import styled from "styled-components";

export const FlowChartContainer = styled.div`
    display:flex;
    height:100%;
`;


export const ArrowButtonLeft = styled.button`
    background-color: rgb(106, 57, 200);
    border: 0;
    padding: 5px 10px;
    color: white;
    border-radius: 25px;
    margin-bottom: 10px;
    background: rgb(18,41,157);
    background: radial-gradient(circle, rgba(18,41,157,1) 0%, rgba(82,31,135,1) 100%);
`;

export const MenuContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const LoadingContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;