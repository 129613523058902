// @flow strict
// Copyright (C) 2022 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited

import React, { useState, useEffect } from "react";
import styles from "./App.module.css";
import Button from "react-bootstrap/Button";
import { MDBContainer } from "mdb-react-ui-kit";

import { TabConfig } from "./config/config";

import logo from "./assets/img/custom.png";

import { apiEndpoint, ColoredBar, ColoredLine } from "./utils/common";

import type { User } from "./rpc/model";

import { AuthService } from "./rpc/auth";

import LoginForm from "./component/loginform/loginForm";
import FlowchartUserMode from "./component/flowchartusermode/flowchartusermode";
import { HeaderMain } from "./styled_components/login";
import { AppLogo, Main, SideBar } from "./styled_components/main";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { HeaderContainer, HeaderData, HeaderInfo, LogoutButton, UserManualButton } from "./styled_components/header";
import { ProSidebarProvider } from "react-pro-sidebar";
import ShowAndHidePassword from "./component/passwordField/passwordField";
import { UserService } from "./rpc/user";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CenterContainer } from "./styled_components/main";
import { TextField } from "@material-ui/core";
import ChatBot from "./component/flowchartusermode/chatBot";
import ChatBotEntry from "./component/flowchartusermode/chatBotEntry";
export default function App() {
  const [userFlowchartTabSelect, setUserFlowchartTabSelect] =
    useState<boolean>(false);

  const [user, setUser] = useState<?User>(null);

  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const [errorLogin, setErrorLogin] = useState<string>("");

  const [isReset, setIsReset] = useState<boolean>(false);

  const [filterTemplate, setFilterTemplate] = useState<string>("");
  const [filterSubItem, setFilterSubItem] = useState<string>("");
  const [watermark, setWaterMark] = useState<string>("");

  const [infoURL, setInfoURL] = useState<?any>({
    user: "",
    db: "",
    component: "",
    flow: "",
  });
  const [showModalUserDetails,setShowModalUserDetails]=useState<string>("");
  const [passwordUser,setPasswordUser]=useState<string>(localStorage.getItem("pass",""))
  var[tempVariableNewPassword,setTempVariableNewPassword]=useState("")
  var [tempVariableRetypePassword,setTempVariableRetypePassword]=useState("")
  var [showChangePasswordSection,setShowChangePasswordSection]=useState(false)
  var [showTableUserDetails,setShowTableUserDetails]=useState(true)
  var [showAccountDeletionSection,setShowAccountDeletionSection]= useState(false)
  var [showAccountDeleteConfirm,setShowAccountDeleteConfirm]=useState(false)
  var [tempVariableUserId,setTempVariableUserId]=useState()
  var [userIdOnLogin,setUserIdOnLogin]=useState(localStorage.getItem("userId",""))
  var [tempVariable1,setTempVariable1]=useState("")
  var [flagFlowMode,setFlowMode]=useState(true)
  useEffect(() => {
    async function fetchMe() {
      if (window.sessionStorage.getItem("ndeWhiz-Template") !== null) {
        window.sessionStorage.removeItem("ndeWhiz-Template");
      }
      const obj = {};
      window.location.href.replace(
        /[?&]+([^=&]+)=([^&]*)/gi,
        function (m, key, value) {
          key = decodeURIComponent(key);
          value = decodeURIComponent(value);
          obj[key] = value;
        }
      );
      if (
        obj.name !== undefined &&
        obj.db !== undefined &&
        obj.component !== undefined &&
        obj.flow !== undefined
      ) {
        setInfoURL(obj);
      }
      setWaterMark("NDEWHIZ Proprietary");
      try {
        if (window.sessionStorage.getItem("ndeWhiz-Template") !== null) {
          window.sessionStorage.removeItem("ndeWhiz-Template");
        }
    const queryParams=query()

    var dsfFlag=queryParams.get("flag1")

        if(dsfFlag==="a"){
          setByPassLogin(true)
          setUser({"id":"testDSF"})
          setIsReset(true)
          setUserFlowchartTabSelect(true)
          setIsAdmin(false)
          return(0)
        }
        
        const resp = await new AuthService(apiEndpoint()).me();
        console.log("resp from window storage",resp)
        setUser(resp.user);
        setIsAdmin(resp.isAdmin);
        if (!resp.isAdmin && resp.user != null) {
          if (resp.user.id !== "" && resp.user.data !== "") {
            const obj = JSON.parse(resp.user.data);
            if (obj.opt !== undefined) {
              if (obj.opt[0].P2 === "Disable") {
                setUserFlowchartTabSelect(true);
              }
              if (obj.opt[0].P2 === "Enable") {
                setUserFlowchartTabSelect(false);
              }
              setFilterTemplate(obj.opt[0].P4);
              setFilterSubItem(obj.opt[0].P5);
            }
          }
        }
        setIsReset(true);
      } catch (err) {
        setErrorLogin("Contact System Administrator: Database Server Down");
      }
    }
    fetchMe();
  }, []);
  function query() {
    return new URLSearchParams(window.location.search);
  }
  var [templateSelect,setTemplateSelect]=useState("")
  var [componentSelect,setComponentSelect]=useState("")
  var [subCompnontSelect,setSubcomponentSelect]=useState("")
  var [byPassLogin,setByPassLogin]=useState("false")
  useEffect(() => {
    const queryParams=query()
    const template = queryParams.get('templateSelect');
    const componentName = queryParams.get('componentName');
    const subComponentName=  queryParams.get('subComponentName')
    
    console.log("template",template,"componentName",componentName,"subCompName")
    setTemplateSelect(template)
    
      setComponentSelect(componentName)
    
      setSubcomponentSelect(subComponentName)
    
      if (
       template !== "" &&
       subComponentName !== "" &&
     componentName !== "" &&
      template !== null &&
        subComponentName !== null &&
   componentName !== null &&
    template !== undefined &&
      subComponentName !== undefined &&
      componentName !== undefined
      ) {
        console.log("flow mode",flagFlowMode)
        setFlowMode(false)
      }


  }, []);
  async function LoginUpdate() {
    const obj = {};
    window.location.href.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      function (m, key, value) {
        key = decodeURIComponent(key);
        value = decodeURIComponent(value);
        obj[key] = value;
      }
    );
    if (
      obj.name !== undefined &&
      obj.db !== undefined &&
      obj.component !== undefined &&
      obj.flow !== undefined
    ) {
      setInfoURL(obj);
    }
    setWaterMark("NDEWHIZ Proprietary");

    const resp = await new AuthService(apiEndpoint()).me();
    console.log("response from user on login",resp)
    setUser(resp.user);
    setIsAdmin(resp.isAdmin);
    if (!resp.isAdmin && resp.user != null) {
      if (resp.user.id !== "" && resp.user.data !== "") {
        const obj = JSON.parse(resp.user.data);
        if (obj.opt !== undefined) {
          if (obj.opt[0].P2 === "Disable") {
            setUserFlowchartTabSelect(true);
          }
          if (obj.opt[0].P2 === "Enable") {
            setUserFlowchartTabSelect(true);
          }
          setFilterTemplate(obj.opt[0].P4);
          setFilterSubItem(obj.opt[0].P5);
        }
      }
    }
    setIsReset(true);
  }
  function onClickSetPasswordParent(passwordInput){
    //setting password in the APP component
    console.log("here in the setPassword")
    console.log(passwordInput)
    setPasswordUser(passwordInput)
    localStorage.setItem("pass",passwordInput)//settting the password for reloading which can be accessed
  }
  function onClickSetUserIdParent(userIdInput){
    console.log(userIdInput)
    console.log("settingtheUserId in Parent")
    setUserIdOnLogin(userIdInput)
    localStorage.setItem("userId",userIdInput)  }
 async function onClickChangePassword(){    if(tempVariableNewPassword===tempVariableRetypePassword){
      let oldPassword=tempVariable1
      let newPassword=tempVariableRetypePassword
      console.log("currentPassword",oldPassword)
      console.log("newPassword",newPassword)
      try{
        let resp= await new UserService(apiEndpoint()).changePassword({ currentPassword:  oldPassword, newPassword: newPassword });
        toast("Password changed successfully!",{
          position:"top-center",
          autoClose:2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          type:"success"
          });
        // setShowModalUserDetails(false);
        setShowChangePasswordSection(false)
        setShowTableUserDetails(true)
        setPasswordUser(newPassword)
      }
      catch(error){
        console.log(error,"error")
        toast("Unauthorization Error! ",{
          position:"top-center",
          autoClose:2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          type:"error"
          });
        }    }
    else{
      toast("Ooops! Passwords don't match", {
        position: "top-center",
        autoClose:2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        type:"error"
        });
    }
  }
  function onClickOpenChangePasswordSection(){
    setShowChangePasswordSection(true)
    setShowTableUserDetails(false)  }
  function onClickAccountDeletionRequest(){    console.log("requested For Account delete")
    let userId=tempVariableUserId
    let password=tempVariableNewPassword
    console.log(userId,password)
    console.log(user,user.id)
    async function  apiCall(){      try{      // const resp = await new UserService(apiEndpoint()).users();
      const response = await new UserService(apiEndpoint()).deleteUser({ userId: user.id });
      console.log(response)
      toast("Account deleted successfully!",{
        position:"top-center",
        autoClose:2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        type:"success"
        });
        setShowModalUserDetails(false)
        setShowAccountDeleteConfirm(false)
    }
  catch(error){
    console.log(error)
    toast("Authorization Error!",{
      position:"top-center",
      autoClose:2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      type:"error"
      });
  }  }
    if(password===passwordUser && userId===userIdOnLogin){
    apiCall()
    }
    else{
      toast("Authorization Error! Bad credentials ",{
        position:"top-center",
        autoClose:2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        type:"error"
        });
    }
  }
  async function onClickLogout() {
    await new AuthService(apiEndpoint()).logout();
    setUser({ id: "", name: "", data: "", createdAt: "" });
    const resp = await new AuthService(apiEndpoint()).me();
    setUser(resp.user);
    setIsAdmin(resp.isAdmin);
    setIsReset(false);
  }

  function onClickUserManual() {
    const extendedURL = apiEndpoint() + "/static/UserManual.pdf";
    window.open(extendedURL, "_blank");
  }

  return (
    <div className={styles.root}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
      <Main>
        <HeaderContainer>
            <AppLogo>
              <img className={styles.logo_img} style={{width:40, height:50}} src= {apiEndpoint() + logo} alt="logo_image" />
              <h6 className={styles.textlogospace} style={{color:'white'}}>{TabConfig.name} ({TabConfig.version})</h6>
            </AppLogo>
            {user != null && user.id !== "" && (
              <HeaderInfo>
                {/* <div>
                  <UserManualButton
                        onClick={() => onClickUserManual()}
                      >
                        User Manual
                  </UserManualButton>
                </div> */}
{/* temp of due to unavailable */}
<div className="switch">

  <LogoutButton onClick={()=>{setFlowMode(!flagFlowMode)}}>
    {
      flagFlowMode===false &&
      <span>
        Chat Mode
      </span>
    }
    {
      flagFlowMode===true &&
      <span>
        Flow Mode
      </span>
    }

  </LogoutButton>

</div>
                <div>
                  <LogoutButton
                        onClick={() => onClickLogout()}
                      >
                        Logout
                  </LogoutButton>
                </div>
                  
                  <MDBContainer className="d-flex justify-content-center" style={{alignItems:'center'}}>
                    <img
                      src="https://mdbcdn.b-cdn.net/img/new/avatars/2.webp"
                      className="rounded-circle"
                      alt="Avatar"
                      style={{height:"50px",width:"50px",transition:"transform .2s"}}
                      onMouseEnter={(e)=>{e.target.style.transform="scale(1.25)";e.target.style.cursor="pointer"}}
                      onMouseLeave={(e)=>{e.target.style.transform="scale(1)"}}
                      onClick={()=>{setShowModalUserDetails(true)}}
                    />
                    <HeaderData>
                      <i>email : {TabConfig.email}</i>
                      <b>{user.name}</b>
                    </HeaderData>
                    
                  </MDBContainer>
              </HeaderInfo>
            )}
          <Modal show={showModalUserDetails} onHide={() => { setShowModalUserDetails(false) }} centered>

            
            {showTableUserDetails === true &&
              <Modal.Header>
                <b> User Account Details</b>
              </Modal.Header>

            }
            {
              showAccountDeleteConfirm === true &&
              <Modal.Header>
                <b>Confirmation</b>
              </Modal.Header>
            }
            {
              showAccountDeletionSection === true &&
              <Modal.Header>
                <b>Account Deletion</b>
              </Modal.Header>
            }{
              showChangePasswordSection === true &&
              <Modal.Header> <b> Change Password</b></Modal.Header>
            }  
            {showTableUserDetails === true && <>
              <TableContainer component={"Paper"}>
                <Table aria-label="simple-table">
                  <TableBody>
                    <TableRow>
                      <TableCell align="left">
                        E-mail
                      </TableCell>
                      <TableCell align="center">
                        {TabConfig.email}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">
                        Username
                      </TableCell >
                      <TableCell align="center">
                        {userIdOnLogin}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">
                        Password
                      </TableCell>
                      <TableCell align="center" style={{ textAlign: "center" }}>
                        <div>
                          <ShowAndHidePassword defaultValue={passwordUser} onchange={function a() { console.log("tempPasswo") }} inputHeading={null} ></ShowAndHidePassword>
                        </div>
                        <div> <Button variant="outline-info" size="sm" onClick={() => { onClickOpenChangePasswordSection() }}> Change Password</Button></div>

                      </TableCell>
                    </TableRow>

                  </TableBody>
                </Table></TableContainer>
              <div className="accountDeletionRequest" style={{ padding: "10px", textAlign: "center" }}>
                <Button variant="warning" onClick={() => { setShowAccountDeletionSection(true); setShowTableUserDetails(false) }}>
                  Delete Account
                </Button>
              </div>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => { setShowModalUserDetails(false) }}>
                  Close
                </Button>
              </Modal.Footer>              </>
            }
                    <Modal.Body>
              <div className="ModalBodyContainer" style={{ textAlign: "center" }}>
                <div className="changeDetailsContainer" style={{ border: "1px solid black" }}>              {/* <Button size="sm" variant="primary" onClick={()=>{setShowChangePasswordSection(true)}}>Change Password</Button> */}
                </div>            
                {
                  showChangePasswordSection === true &&
                  <>
                    <div className="changePasswordSection" style={{ padding: "10px" }}>
                      <ShowAndHidePassword onchange={setTempVariable1} inputHeading={"Enter Current Password"}></ShowAndHidePassword>
                      <div className="newPasswordContainer" style={{ marginTop: "10px", marginBottom: "10px" }}  >
                        <ShowAndHidePassword onchange={setTempVariableNewPassword} inputHeading={"Enter New Password"}></ShowAndHidePassword></div>
                      <div >
                        <ShowAndHidePassword onchange={setTempVariableRetypePassword} inputHeading={"ReType New Password"}></ShowAndHidePassword>
                      </div>                  </div>                </>
                }            
                {
                  showAccountDeletionSection === true &&
                  <>
                    <div className="accountDeleteSectionContainer" style={{ display: "flex", flexDirection: "column", textAlign: "center",paddingLeft:"30px",paddingRight:"30px" }}>

                      <TextField variant="standard" label="UserId" onChange={(e) => { setTempVariableUserId(e.target.value) }} style={{ margin: "5px" }}></TextField>

                      <TextField variant="standard" label="Password" onChange={(e) => { setTempVariableNewPassword(e.target.value) }} style={{ margin: "5px" }}></TextField>

                    </div>
                      <Modal.Footer>
                        <div className="accountDeletionButtonContainer" style={{ padding: "10px" }}>
                          <Button style={{ marginRight: "10px" }} variant="danger" size="sm" onClick={() => { setShowAccountDeleteConfirm(true); setShowAccountDeletionSection(false) }}>
                            Delete Account
                          </Button>
                          <Button variant="secondary" size="sm" onClick={() => { setShowAccountDeletionSection(false); setShowTableUserDetails(true) }} >
                            Cancel
                          </Button>
                        </div>
                      </Modal.Footer>              
                
                  </>
                }
                {
                  showAccountDeleteConfirm === true &&
                  <>
                    <div className="accountDeleteConfirmSectionContainer" style={{ padding: "10px", alignItems: "center" }}>
                      <span>
                        You Are going to Delete Your NDEWhiz account. Are you sure you want to continue?
                      </span>
                    </div>
                    <Modal.Footer>
                      <Button variant="danger" onClick={() => { onClickAccountDeletionRequest() }} style={{ marginRight: "5px" }}>
                        Yes,Delete
                      </Button>
                      <Button variant="secondary" onClick={() => { setShowAccountDeleteConfirm(false); setShowAccountDeletionSection(true) }}>
                        Cancel
                      </Button>
                    </Modal.Footer>
                  </>
                }
              </div>
            </Modal.Body>
            {showChangePasswordSection === true &&
              <Modal.Footer>
                <Button style={{ marginRight: "10px" }} onClick={() => { onClickChangePassword() }} size="sm" variant="success">Change Password</Button>
                <Button variant="danger" size="sm" onClick={() => { setShowTableUserDetails(true); setShowChangePasswordSection(false) }}> Cancel</Button>
              </Modal.Footer>}
          </Modal>
            {user != null && user.id === "" && (
              <div className={styles.textspace}>
                <i>email : {TabConfig.email}</i>
                <h6>
                  {""}{" "}
                  <span>
                    <LogoutButton disabled={true}>
                      Login
                    </LogoutButton>
                  </span>
                </h6>
              </div>
            )}
        </HeaderContainer>

        {/* <div className={styles.background}>
          <p className={styles.bgtext}>{watermark}</p>
        </div> */}

          {/* If the user is not the logged in user */}
        <CenterContainer>
          {user != null && user.id === "" && (
            <LoginForm admin={isAdmin} onSubmit={LoginUpdate} onSubmit1={onClickSetUserIdParent} onSubmit2={onClickSetPasswordParent} />
          )}

          {/* If the user is logged in  */}
          {user != null && user.id !== "" && 
          <>
              {isReset === true &&
                userFlowchartTabSelect === true &&
                isAdmin === false && <>
                {
                  flagFlowMode===true && 
                  <ChatBotEntry></ChatBotEntry>
                  // <ChatBot flagMode={"normal"}></ChatBot>
                }
                {
                  flagFlowMode===false&&
                  <ProSidebarProvider>
                  <FlowchartUserMode
                  userId={user.name}
                    isAdmin={isAdmin}
                    filterTemplate={filterTemplate}
                    filterSubItem={filterSubItem}
                    infoURL={infoURL}
                    template={templateSelect}
                    componentSelect={componentSelect}
                    subCompnontSelect={subCompnontSelect}
                  />
                  </ProSidebarProvider>
                }
           
                    {/* <div
                      class=""
                      style={{ color: "red", marginTop: "50px" }}
                    ></div> */}
                  </>
                }
            </>
          }
        </CenterContainer>
        {errorLogin !== "" && (
          <h6 class="" style={{ color: "red", marginTop: "50px" }}>
            {errorLogin}
          </h6>
        )}
      </Main>
    </div>
  );
}
