import React, { useState } from 'react';
import './chatBotEntry.css';
import ChatBot from './chatBot';

function ChatBotEntry() {
  const [mode, setMode] = useState(null);
  const [flagClicked,setFlagClicked]=useState(false)

  return (
    <div className="App">
    {
        flagClicked===false &&
        <>
        <h1>Select Mode</h1>
      <div className="button-container">
        <button
          className="mode-button"
          onClick={() => {setMode('NDEAssistant');
        setFlagClicked(true)}}
        >
          NDEAssistant
        </button>
        <button
          className="mode-button"
          onClick={() => {setMode('GPT');
        setFlagClicked(true)}}
        >
          GPT
        </button>
      </div>
      {mode && <h2>Selected Mode: {mode}</h2>}
        
        </>
    }
      
      {
        flagClicked===true&&
        <>
        {
            mode==="NDEAssistant" &&
            
            <>
            <ChatBot flagMode={"normal1"}></ChatBot>
            </>

        }
        {
            mode==="GPT" &&
            <>
            <ChatBot flagMode={"gpt"}></ChatBot>
            </>
        }
        </>
      }
    </div>
  );
}

export default ChatBotEntry;
