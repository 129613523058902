import React from 'react';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';

import { BrowserRouter, Route,Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

function Demo() {
  return (
    
    <BrowserRouter>
    <Routes>
      <Route path="/linkedin" element={<LinkedInCallback />} />
    </Routes>
  </BrowserRouter>
  );
}
export default Demo