
import styled from 'styled-components'


export const Main = styled.div`
    height:100%;
    // padding:5px;
`;

export const SideBar = styled.div`
    width: 20%;
    height: 95%;
    border-radius: 20px;
`;

export const CenterContainer = styled.div`
    width: 100%;
    align-items: center;
    height:calc(100vh - 80px);
`;


export const AppLogo = styled.div`
    margin-left: 20px;
    display: flex;
    align-items: center;
`;