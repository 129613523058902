import { Button } from "react-bootstrap";
import styled from "styled-components";

export const WorkflowTrainingReferenceContainer = styled.div`
    box-sizing: border-box;
    width: 100%;
    background: rgb(255 255 255);
    border-radius: 2px;
    padding: 1%;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    border: 1px solid #ebebeb;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    flex-direction: column;
`;

export const WorkflowLabels = styled.label`
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.03em;
    color: #000000;
    display: flex;
    gap:15px;
    margin: 0;
`;

export const Input = styled.input`
//   opacity: 0;
//   z-index: 2;
//   position: absolute;
//   top: 0;
//   width: 100%;
//   height: 100%;
//   margin: 0;
//   cursor: pointer;

  &:focus {
    outline: none;
  }

    &:checked:before{
        background:orange;
        border-radius: 100%;
    }
  }
`;

export const Options = styled.div`
    padding: 15px;
`;    

export const ResetButton = styled(Button)`
    border: 0px;
    padding: 5px 15px;
    background: white;
    border: 1px dotted rgb(106, 57, 200);
    color: black;
    &:hover{
        background: rgb(18,41,157);
        color:white
    }

`;


export const StartContainer = styled.div`
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    padding: 1%;
    align-items: center;
    justify-content: center;
`;


export const Questions = styled.div`
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: white;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style:none
`;

export const QuestionOptions = styled(Button)`
    border: 2px solid rgb(106, 57, 200);
    background: white;
    color: black;
    margin-left:10px;
    &:hover{
        background-color:rgb(106, 57, 200);
        color:white
    }

`;

export const QuestionOptionsList = styled.li`

`;

export const UnorderedList = styled.ul`
  list-style:none;
  display:flex;
  padding: 0;
`;

export const Container = styled.div`
  width:100%;
  justify-content:center
`;

export const InnerContainer = styled.div`
  padding:1%;
  display:flex;
  flex-direction:column;
    align-items: center;
`;

export const AnsweredContainer =styled.div`
background-color: white;
display: flex;
flex-direction: column;
align-items: center;
list-style:none
    height:100%;
    display:flex;
    // overflow-y: scroll;
    // justify-content:end;  
    padding:10px;
    width:80%

`;
export const ChatBotContainer=styled.div`
margin:auto;
flex: 1; /* Allow child2 to grow and fill the available space */
height: 100%; /* Match the height of child1 */
box-sizing: border-box; /* Ensure padding and border are included in the element's total size */
overflow: hidden;`
export const DisplayFlow = styled.ul`
    display: flex;
    list-style: none;
    padding: 10px;
    gap:20px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    margin: 0;
    background:lightblue
`;


export const WorkflowContainer = styled.div`
  width:100%;
  margin: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  overflow: scroll;
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
    }
`;

export const StartButton = styled(Button)`
    background-color: rgb(106, 57, 200);
    border: 0;
    padding: 10px 20px;
    border-radius: 20px;
    &:hover{
        background-color:rgb(106, 57, 200);
        background: rgb(18,41,157);
        background: radial-gradient(circle, rgba(18,41,157,1) 0%, rgba(82,31,135,1) 100%);
        color:white
    }
`;

export const TableHead = styled.thead`
    background: #268d81;
    color: white;
    font-size: 12px;
    background: rgb(26,153,208);
    // background: radial-gradient(circle, rgba(26,153,208,1) 44%, rgba(31,69,135,1) 100%);
    background: rgb(82, 31, 135);
`;

export const TableTH = styled.th`
    padding:5px;
    text-align:center
`;

export const TableTR = styled.tr`
    height: 50px;
	&:nth-of-type(odd) {
		background:white !important;
	}
    &:nth-of-type(even) {
		background:#e2f1ff;
	}
`;


export const TableContainer = styled.table`
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
`;

export const DisabledButton = styled(Button)`
    background-color: white !important;
    color: black !important;
    border: 2px dotted #d1d1d1 !important;
`;

export const ViewSheetButton = styled(Button)`
    background-color:white;
    color: black;
    margin-left:10px;
    border: 0;
    padding: 10px 20px;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    &:hover{
        background: rgb(18,41,157);
        color:white;
    }
`;

export const TableButtons = styled(Button)`
    background-color: white;
    padding:5px;
    border:0;
    color: black;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    &:hover{
        background-color: rgba(18, 41, 157, 0.11);
        color:black;
    }
`;

export const BackButton = styled(Button)`
    width: fit-content;
    background: white;
    border: 0;
    display: flex;
    align-self: self-end;
    color: black;
    border: 2px solid lightseagreen;
    &:hover{
        background-color:rgb(106, 57, 200);
        background: rgb(18,41,157);
        
        color:white
    }
`;

export const DownloadButton = styled(Button)`
    margin-left: 10px;
    border: 0;
    background-color: coral;
    &:hover{
        background-color: #cb3700;
    }
`;

export const NoWorkflowPresent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
`;

export const NoDatabasePresent = styled(NoWorkflowPresent)`
    width: 100%;
    justify-content: center;
`;


export const OpenButton = styled(Button)`
width: fit-content;
background: white;
border: 0;
color: black;
border: 2px solid rgba(26,153,208,1);
&:hover{
    background-color: rgba(26,153,208,1);
    color:white;
}
`;

export const WorkflowButton = styled(Button)`
    background: white;
`;

export const SelectMessage = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;