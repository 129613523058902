// @flow strict
// Copyright (C) 2022 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import React from "react";

let _apiEndpoint = "";

export function apiEndpoint(): string {
  return _apiEndpoint;
}

export function setApiEndpoint(apiEndpoint: string) {
  _apiEndpoint = apiEndpoint;
}

type BarSetting ={
color:string,
height:string
}

export const ColoredBar = (props:BarSetting) => (
    <hr
        style={{
           
            margin: 0,
            color: props.color,
            backgroundColor: props.color,
            height: props.height
        }}
    />
);

type SetLine = {
newColor:string
}

export const ColoredLine = (props:SetLine) => (
    <hr
        style={{
           
            margin: 0,
            color: props.newColor,
            backgroundColor: props.newColor,
            height: 1
        }}
    />
);



export function download(filename: string, rawData: string, url: string) {
  const element = document.createElement("a");
  if (rawData !== "") {
    element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(rawData));
  }
  if (url !== "") {
    element.href = url;
  }
  element.setAttribute("download", filename);

  element.style.display = "none";
  if (document.body !== null) {
    document.body.appendChild(element);
  }
  element.click();
  if (document.body !== null) {
    document.body.removeChild(element);
  }
}

