import styled from 'styled-components'

export const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    // position: absolute;
    background-color: rgb(106, 57, 200);
    background: rgb(18,41,157);
    background: radial-gradient(circle, rgba(18,41,157,1) 0%, rgba(82,31,135,1) 100%);
`;

export const UserManualButton = styled.button`
    background-color: #fff;
    color: black;
    border: 0;
    padding: 5px 15px;
    border-radius: 15px;
    width: 130px;
    font-size: 14px;
`;

export const LogoutButton = styled.button`
    background-color: rgb(225, 78, 147);
    color: white;
    border: 0;
    padding: 5px 15px;
    border-radius: 15px;
    max-height:70px;
    font-size: 14px;
    width:max-content;
`;



export const HeaderInfo = styled.div`
    display: flex;
    align-items: center;
    height: 80px;
    gap: 20px;
`;

export const HeaderData = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    font-size: 12px;
    color:white
`;