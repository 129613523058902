// // src/Chatbot.js
// import React, { useState } from 'react';
// import './chatBot.css';
import * as Icon from 'react-bootstrap-icons';
// import { useEffect } from 'react';
// import axios from "axios"
// import { apiEndpoint, download } from "../../utils/common";

// const ChatBot = (props) => {

//   useEffect(() => {
//     async function admin() {
//  console.log("props",props)

//  let templateSelect=props.template
//  let flowchartSheetSelect=props.compName
//  let flowchartSelect=props.compName+"-"+props.subCompName

//  console.log("templateSelect",templateSelect,flowchartSheetSelect,flowchartSelect)
//  const respy = await axios.get(
//     apiEndpoint() +
//       `/api/blob/workspace/read/${"admin"}/${templateSelect}/${flowchartSheetSelect}/${
//         flowchartSelect + ".json"
//       }`
//   );



//   //set Flowchart data to respy data , fetch flowchart
//   let JSONFlowchart=respy.data



//   let flowchartRef={}
//   let flowChartRef={}
//   function traverseNodes(tempNode){
//     console.log(tempNode,"#tempNode here")
//     if(flowchartRef[tempNode.name]!==undefined){
//       let subItemArray=tempNode.subItem
//     for(let j=0;j<subItemArray.length;j++){
//       let ItemObject=subItemArray[j].item[0]
//       traverseNodes(ItemObject)
//     }
//     return(0)

//     }
//     else{
//         let subItemArray=tempNode.subItem
//         let arrayItemInfo=[]
//         for(let i=0;i<subItemArray.length;i++){
//           arrayItemInfo.push("c")
//           arrayItemInfo.push("c")

//         }
//         arrayItemInfo.push("c") 
//         arrayItemInfo[0]=("#"+tempNode.name)
//         let k=1
//         let k1=subItemArray.length
//         for(let j=0;j<subItemArray.length;j++){
//           let l=k1+j+1
//           arrayItemInfo[k]="_"+subItemArray[j].name
//           let itemArray=subItemArray[j].item
//           console.log("item Array",subItemArray[j].item)
//           if(subItemArray[j].item.length===0){
//             arrayItemInfo[l]="{No Inspection}"
//           }
//           else if(itemArray[0].type==="NDE" || itemArray[0].type==="LOC"){
//             //end node string compression from NDE Array information 
//             let NDEMethodsArray=[]
//             for(let k=0;k<itemArray.length;k++){
//               if(itemArray[k].type==="NDE"){
//                  NDEMethodsArray.push(itemArray[k].subItem[0].name)
//               }
//             }
//             let NDEMethodsString="{"
//             NDEMethodsArray.map((x)=>{

//               if(NDEMethodsArray.indexOf(x)===NDEMethodsArray.length-1){
//              NDEMethodsString=NDEMethodsString+x+"}"  
//               }
//               else{
//                 NDEMethodsString=NDEMethodsString+x+","
//               }
//             })
//             console.log("for item",NDEMethodsArray,NDEMethodsString)
//             arrayItemInfo[l]=NDEMethodsString
            
//           }
//           else{
//           console.log("before Adding item",subItemArray[j].item[0])
//            arrayItemInfo[l]="#"+subItemArray[j].item[0].name
//            traverseNodes(subItemArray[j].item[0])
//           }
//           k=k+1
//         }
//       let nodeEntryString=""
//       console.log("before adding node ",arrayItemInfo)
//       arrayItemInfo.forEach(element => {
//         if(arrayItemInfo.indexOf(element)===arrayItemInfo.length-1){
//           nodeEntryString+=element
//           console.log("here at end of node entry",nodeEntryString)
//         }
//         else{
//           nodeEntryString+=element+","
//         }
//       });
//       flowChartRef[tempNode.name]=nodeEntryString

//     return(0)
//     }
 
//   }
//   console.log("JSON Flowchart",JSONFlowchart)
//   if(Array.isArray(JSONFlowchart)===true)
//   {
//     traverseNodes(JSONFlowchart[0])
//   }
//   else{
//     traverseNodes(JSONFlowchart)
//   }
//   console.log(flowChartRef,",,,,")


//   let concatenatedText = "";
//   for (const key in flowChartRef) {
//       concatenatedText += flowChartRef[key] + "\n";
//   }

//   console.log("flowchart",flowchartRef,concatenatedText)
//   const json = convertToJSON(concatenatedText);
//   console.log(json)
//   setJsonOutput(json);



//     }
//     admin();
//   }, []);



//   const [messages, setMessages] = useState([
//     {
//       text:"How Can I help You?",
//       sender:"bot"
//     }
//   ]);
//   const [input, setInput] = useState('');
//   const [jsonOutput, setJsonOutput] = useState(null);
// const [tempQues,setTempQues]=useState("")
// const [flagChatBot,setFlagChatBot]=useState(false)
//   const handleFileUpload = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       const reader = new FileReader();
//       reader.onload = (e) => {
//         const text = e.target.result;
//         const json = convertToJSON(text);
//         console.log(json)
//         setJsonOutput(json);
//       };
//       reader.readAsText(file);
//     }
//   };




//   const convertToJSON = (text) => {
//     const lines = text.split('\n');
//     const flowchart = {};
//     lines.map((entry,i)=>{

//       let columns=[]
//       console.log(columns)
//       let temp=""
//       let flagInCurly=false
//       for(let i=0;i<entry.length;i++){
//         if(entry[i]==="{"){
//           flagInCurly=true
//           temp+="{"
//         }
//         else if(entry[i]==="}"){
//           flagInCurly=!true
//           temp+="}"
//         }
//         else if(entry[i]===","){
//           if(flagInCurly===true){
//             temp+=entry[i]
//           }
//           else{
//             columns.push(temp)
//             temp=""
//           }
//         }
//         else{
//           temp+=entry[i]
//         }
//       }
//       columns.push(temp)
//       console.log(columns,"columns for row",entry)
//       for(let j=0;j<columns.length;j++){
//         console.log(columns[j])
//         if(j===0){
//           let nodeValue=columns[j]
//           flowchart[nodeValue]={
//             node:nodeValue,
//             results:[],
//             choices:[]
//           }
//         }
//         else{
//           let flagChoice=columns[j][0]==="_"
//           if(flagChoice===true){
//             flowchart[columns[0]].choices.push(columns[j])
//           }
//           else{
//             flowchart[columns[0]].results.push(columns[j])
//           }
      

//         }
//       }

//     console.log("flowchart", flowchart)
//     })
//     // lines.forEach(line => {
//     //   const [node, ...rest] = line.split('_');
//     //   const choices = [];
//     //   const results = [];

//     //   rest.forEach((part, index) => {
//     //     if (index % 2 === 0) {
//     //       choices.push(part);
//     //     } else {
//     //       const resultNodes = part.split(',').map(result => result.trim());
//     //       if (resultNodes.length > 1) {
//     //         results.push({ type: 'end', nodes: resultNodes });
//     //       } else {
//     //         const result = resultNodes[0].replace(/[{}]/g, '');
//     //         if (result.startsWith('#')) {
//     //           results.push({ type: 'node', node: result.slice(1) });
//     //         } else {
//     //           results.push({ type: 'end', nodes: resultNodes });
//     //         }
//     //       }
//     //     }
//     //   });

//     //   flowchart[node.replace('#', '')] = { choices, results };
//     // });
// return(flowchart)
//   }



  
//   const handleSend = () => {
//     let flowchart=jsonOutput
//     if (input.trim()) {
//       // console.log("set messages",messages)
//       // setMessages([...messages, { text: input, sender: 'user' }]);
//       // const choiceIndex = currentNode.choices.indexOf(input.trim());
//       // if (choiceIndex !== -1) {
//       //   const nextNodeKey = currentNode.results[choiceIndex];
//       //   const nextNode = flowchart[nextNodeKey];
//       //   console.log("next node",nextNode)
//       //   setMessages((prevMessages) => [
//       //     ...prevMessages,
//       //     { text: nextNode.message || `You selected: ${input.trim()}`, sender: 'bot' }
//       //   ]);

//       //   setCurrentNode(nextNode);
//       // } else {
//       //   setMessages((prevMessages) => [
//       //     ...prevMessages,
//       //     { text: 'Invalid choice. Please try again.', sender: 'bot' }
//       //   ]);
//       // }
//       if(input[0]==="#"){
//         console.log("input question",input)
//         if(input!==tempQues &&  tempQues!==""){
//           setMessages((x)=>{
//             return(
//               [
//               ...x,
//               {
//                 text:"Invalid Question Entry! Did you mean-"+tempQues,
//                 sender:"bot"
//               }
//               ]
//             )
//           })
//           return(0)
//         }
//         else if(tempQues===""){
//           console.log("first question",input,flowchart[input])
//           if(flowchart[input]===undefined){
//             setMessages((x)=>{
//               return(
//                 [
//                   ...x,
//                   {
//                   text:"Unknown",
//                   sender:"bot"
//                   }
//                 ]
//               )
//             })
//           return(0)
//           }

//         }
//         setTempQues(input)
//         setMessages((x)=>{
//           return(
//             [
//               ...x,
//               {
//               text:input,sender:"user"
//               },
//               {
//                 text:"Select Choice",sender:"bot"
//               }
//             ]
//           )
//         })
//       }
//       else{
//         console.log(input)
//         if(input[0]==="_"){
//         setMessages((x)=>{
//           let indexChoice=-1

//           indexChoice=flowchart[tempQues].choices.indexOf(input)
          
//           if(indexChoice===-1){
//             return(
//               [
//                 ...x,
//                 {
//                   text:"Invalid!Please Enter Again",
//                   sender:"bot"
//                 }
//               ]
//             )
//           }
//           else{
//             let resultNode=flowchart[tempQues].results[indexChoice]
//             setTempQues(resultNode)
//           return([
//             ...x,
//             {

//               text:input,
//               sender:"user"
//             },
//             {
//               text:resultNode[0]==="{"?"Final Node Result="+resultNode+" **FLOW ENDS HERE**":"Select Question",
//               sender:"bot"
//             }
//           ])
//         }
//         })
//       }
//       else{
//         setMessages((x)=>{
//           return(
//           [
//             ...x,
//             {
//             text:"Invalid!Please Again",
//             sender:"bot"
//             }
//           ])
//         })
//       }
//     }
    
//       setInput('');
//   };


//   }
//   function showChatBot(){
//     setFlagChatBot(!flagChatBot)
//   }
//   return (
    
//     <div  className='chatBContainer' >
//     <div className="chatbot">
//     {flagChatBot===true &&  
//     <div className='chatContainer'  >  

//       <div className="chat-window">
//         {messages.map((msg, index) => (
//           <div key={index} className={`message ${msg.sender}`}>
//             {msg.text}
//           </div>
//         ))}
//       </div>
//       <div className="input-container">
//         <input
//           type="text"
//           value={input}
//           onChange={(e) => setInput(e.target.value)}
//           onKeyPress={(e) => e.key === 'Enter' && handleSend()}
//         />
//         <button onClick={handleSend}>Send</button>
//       </div>
//     </div>
// }





// <div style={{ textAlign: "center", height: "20%",textAlign:"right" }}>
//   <button className="btn1" onClick={() => { showChatBot() }}>
//     <span className="button-content">
//       ChatBot
//       {
//         flagChatBot === false &&
//         <Icon.ArrowUpCircle size={20} className="icon" />
//       }
//       {
//         flagChatBot === true &&
//         <Icon.ArrowDownCircle size={20} className="icon" />
//       }
//     </span>
//   </button>
// </div>
//     </div>
//     </div>
//   );
// };

// export default ChatBot;
// src/Chatbot.js
import React, { useState } from 'react';
import './chatBot.css';
import { useEffect } from 'react';
import axios from "axios"

const ChatBot = (props) => {

//   useEffect(() => {
//     async function admin() {
//  console.log("props",props)

//  let templateSelect=props.template
//  let flowchartSheetSelect=props.compName
//  let flowchartSelect=props.compName+"-"+props.subCompName

//  console.log("templateSelect",templateSelect,flowchartSheetSelect,flowchartSelect)
//  const respy = await axios.get(
//     apiEndpoint() +
//       `/api/blob/workspace/read/${"admin"}/${templateSelect}/${flowchartSheetSelect}/${
//         flowchartSelect + ".json"
//       }`
//   );



//   //set Flowchart data to respy data , fetch flowchart
//   let JSONFlowchart=respy.data



//   let flowchartRef={}
//   let flowChartRef={}
//   function traverseNodes(tempNode){
//     console.log(tempNode,"#tempNode here")
//     if(flowchartRef[tempNode.name]!==undefined){
//       let subItemArray=tempNode.subItem
//     for(let j=0;j<subItemArray.length;j++){
//       let ItemObject=subItemArray[j].item[0]
//       traverseNodes(ItemObject)
//     }
//     return(0)

//     }
//     else{
//         let subItemArray=tempNode.subItem
//         let arrayItemInfo=[]
//         for(let i=0;i<subItemArray.length;i++){
//           arrayItemInfo.push("c")
//           arrayItemInfo.push("c")

//         }
//         arrayItemInfo.push("c") 
//         arrayItemInfo[0]=("#"+tempNode.name)
//         let k=1
//         let k1=subItemArray.length
//         for(let j=0;j<subItemArray.length;j++){
//           let l=k1+j+1
//           arrayItemInfo[k]="_"+subItemArray[j].name
//           let itemArray=subItemArray[j].item
//           console.log("item Array",subItemArray[j].item)
//           if(subItemArray[j].item.length===0){
//             arrayItemInfo[l]="{No Inspection}"
//           }
//           else if(itemArray[0].type==="NDE" || itemArray[0].type==="LOC"){
//             //end node string compression from NDE Array information 
//             let NDEMethodsArray=[]
//             for(let k=0;k<itemArray.length;k++){
//               if(itemArray[k].type==="NDE"){
//                  NDEMethodsArray.push(itemArray[k].subItem[0].name)
//               }
//             }
//             let NDEMethodsString="{"
//             NDEMethodsArray.map((x)=>{

//               if(NDEMethodsArray.indexOf(x)===NDEMethodsArray.length-1){
//              NDEMethodsString=NDEMethodsString+x+"}"  
//               }
//               else{
//                 NDEMethodsString=NDEMethodsString+x+","
//               }
//             })
//             console.log("for item",NDEMethodsArray,NDEMethodsString)
//             arrayItemInfo[l]=NDEMethodsString
            
//           }
//           else{
//           console.log("before Adding item",subItemArray[j].item[0])
//            arrayItemInfo[l]="#"+subItemArray[j].item[0].name
//            traverseNodes(subItemArray[j].item[0])
//           }
//           k=k+1
//         }
//       let nodeEntryString=""
//       console.log("before adding node ",arrayItemInfo)
//       arrayItemInfo.forEach(element => {
//         if(arrayItemInfo.indexOf(element)===arrayItemInfo.length-1){
//           nodeEntryString+=element
//           console.log("here at end of node entry",nodeEntryString)
//         }
//         else{
//           nodeEntryString+=element+","
//         }
//       });
//       flowChartRef[tempNode.name]=nodeEntryString

//     return(0)
//     }
 
//   }
//   console.log("JSON Flowchart",JSONFlowchart)
//   if(Array.isArray(JSONFlowchart)===true)
//   {
//     traverseNodes(JSONFlowchart[0])
//   }
//   else{
//     traverseNodes(JSONFlowchart)
//   }
//   console.log(flowChartRef,",,,,")


//   let concatenatedText = "";
//   for (const key in flowChartRef) {
//       concatenatedText += flowChartRef[key] + "\n";
//   }

//   console.log("flowchart",flowchartRef,concatenatedText)
//   const json = convertToJSON(concatenatedText);
//   console.log(json)
//   setJsonOutput(json);



//     }
//     admin();
//   }, []);


  const [modelName,setModelName]=useState("gpt-3.5-turbo")
  const [messages, setMessages] = useState([
    {
      content:"How Can I help You?"+`I am model- ${modelName}`,
      role:"assistant"
    }
  ]);
  const [input, setInput] = useState('');
  const [jsonOutput, setJsonOutput] = useState(null);
const [tempQues,setTempQues]=useState("")
console.log(props.flagMode)
const [flagChatBot,setFlagChatBot]=useState(props.flagMode!==undefined?(props.flagMode[props.flagMode.length-1]==="2"?false:true):false)
const [isLoading, setIsLoading] = useState(false);
const [isVisible, setIsVisible] = useState(false);
const [inputText, setInputText] = useState("");
const [streamedChunks, setStreamedChunks] = useState([]);
const [completeResponse, setCompleteResponse] = useState("");
const [requestCount, setRequestCount] = useState(0);
const  [threadId,setThreadId]=useState("")
var [tempMessageArray,setTempMessageArray]=useState([])
const openaiApiKey = 'sk-proj-JVoiGuhIQW94RxcroSkZT3BlbkFJsQt7LXl4ckvGjOyruQ6I'; // Replace with your actual OpenAI API key
const assistantId = 'asst_y7vEMQYnYMBuQ2w9lVgdHhA4'; // Replace with your actual assistant ID
const baseURL = 'https://api.openai.com/v1';
const headers = {
  'Content-Type': 'application/json',
  'Authorization': `Bearer ${openaiApiKey}`,
  'OpenAI-Beta': 'assistants=v2',
};



const incrementRequestCount = () => {
  setRequestCount((prevCount) => prevCount + 1);
};

const processInput = async (request) => {
  if(props.flagMode[0]==="n"){
console.log("normal mode")
  }
  else{
    console.log("normal gpt")
    const newMessages = [...messages, { role: 'user', content: input }];
    setMessages(newMessages);
     try {
          const response = await axios.post('https://api.openai.com/v1/chat/completions', {
            model:modelName,
            messages:newMessages,
            max_tokens: 1000,
            temperature: 0.7,
          }, {
            headers: {
              'Authorization': `Bearer sk-proj-JVoiGuhIQW94RxcroSkZT3BlbkFJsQt7LXl4ckvGjOyruQ6I`,
              'Content-Type': 'application/json',
            },
          });
         console.log("response",response)
          const botResponse = response.data.choices[0].message.content.trim();
          setMessages([...newMessages, { role: 'assistant', content: botResponse }]);
          setInput('');
        } catch (error) {
          console.error('Error fetching data from OpenAI API:', error);
        }
    return(0)
  }
  console.log("called")
  setIsLoading(true);
  try {
   let threadId1
    if(requestCount<1){
      const threadResponse = await fetch(`${baseURL}/threads`, {
          method: 'POST',
          headers: headers,
          body: ""
        });
        
        const thread = await threadResponse.json();
        console.log('Thread ID:', thread.id);
        threadId1=thread.id
        setThreadId(thread.id)
    } 
   else{
      threadId1=threadId
   }
   // add to messages array
   const newMessages = [...messages, { role: 'user', content: input }];
   tempMessageArray=newMessages
   setMessages(newMessages);
   console.log("here")
    const messageResponse = await fetch(`${baseURL}/threads/${threadId1}/messages`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({ role: "user", content: input }),
    });
    
    const message = await messageResponse.json();
    console.log('Message:', message);

newMessages.push({role:"assistant",content:""})
tempMessageArray=newMessages
setMessages(newMessages)

    const runResponse = await fetch(`${baseURL}/threads/${threadId1}/runs`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({ assistant_id: 'asst_y7vEMQYnYMBuQ2w9lVgdHhA4', stream: true }),
    });
  
    console.log("response of run",runResponse)

    if (!runResponse.ok) {
      throw new Error(`HTTP error! status: ${runResponse.status}`);
    }
    const reader = runResponse.body.getReader();
    const decoder = new TextDecoder();

    const handleStreamedResponse = (value) => {
      const lines = decoder.decode(value).split('\n');
      for (const line of lines) {
        if (line.trim().startsWith('data:')) {
          const data = line.trim().slice(5);
          if (data === '[DONE]') {
            setIsLoading(false);
          } else {
            try {
              const event = JSON.parse(data);
              handleStreamedEvent(event);
            } catch (error) {
              console.error('Error parsing streamed response:', error);
            }
          }
        }
      }
    };

    const readLoop = async () => {
      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          break;
        }
        handleStreamedResponse(value);
      }
    };

    readLoop();

let tempStream=[]
const handleStreamedEvent = (event) => {
  switch (event.object) {
    case 'thread.message.delta':
      if (event.delta.content) {
        const content = event.delta.content[0].text.value;
        const formattedContent = content
          .replace(/\n/g, '<br/>')
          .replace(/\_\_(.+?)\_\_/g, '<strong>$1</strong>')
          .replace(/\_(.+?)\_/g, '<em>$1</em>')
          .replace(/\`(.+?)\`/g, '<code>$1</code>');
      // let latestStream=[...streamedChunks,formattedContent]
      let temp=formattedContent
      let temp2=""
      let flagType=""
      let flag=false
      console.log(temp)
      for(let j=0;j<temp.length;j++){
          if(temp[j]==="<"){
              flag=true
              flagType=""
          }
          else if(temp[j]===">"){
              flag =false
              
              if(flagType[0]+flagType[0+1]==="br"){
                  temp2+="\n"
              }

          }
          else{
              if(flag===false){
                   temp2+=temp[j]
              }
              else{
                  
                  flagType+=temp[j]
              }
          }
      }
      console.log(temp2,"string to be added ")
      tempStream.push(temp2)
      tempMessageArray[tempMessageArray.length-1].content=tempStream.join(" ")
      setMessages(tempMessageArray)
        setStreamedChunks((prevChunks) => [...prevChunks, formattedContent]);
      //   [messages.length-1].content=latestStream
      //   setMessages()
        setIsVisible(true);
      }
      break;
    case 'thread.message.completed':
      console.log("here")
      const finalMessage = event.content[0].text.value;
      const formattedFinalMessage = finalMessage
        .replace(/\n/g, '<br/>')
        .replace(/\_\_(.+?)\_\_/g, '<strong>$1</strong>')
        .replace(/\_(.+?)\_/g, '<em>$1</em>')
        .replace(/\`(.+?)\`/g, '<code>$1</code>');

      setCompleteResponse(formattedFinalMessage);
      setIsLoading(false);
      break;
    default:
      break;
  }
};



}
catch(error){
  console.log(error,"error")



}
incrementRequestCount()
setInput("")
}
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target.result;
        const json = convertToJSON(text);
        console.log(json)
        setJsonOutput(json);
      };
      reader.readAsText(file);
    }
  };




  const convertToJSON = (text) => {
    const lines = text.split('\n');
    const flowchart = {};
    lines.map((entry,i)=>{

      let columns=[]
      console.log(columns)
      let temp=""
      let flagInCurly=false
      for(let i=0;i<entry.length;i++){
        if(entry[i]==="{"){
          flagInCurly=true
          temp+="{"
        }
        else if(entry[i]==="}"){
          flagInCurly=!true
          temp+="}"
        }
        else if(entry[i]===","){
          if(flagInCurly===true){
            temp+=entry[i]
          }
          else{
            columns.push(temp)
            temp=""
          }
        }
        else{
          temp+=entry[i]
        }
      }
      columns.push(temp)
      console.log(columns,"columns for row",entry)
      for(let j=0;j<columns.length;j++){
        console.log(columns[j])
        if(j===0){
          let nodeValue=columns[j]
          flowchart[nodeValue]={
            node:nodeValue,
            results:[],
            choices:[]
          }
        }
        else{
          let flagChoice=columns[j][0]==="_"
          if(flagChoice===true){
            flowchart[columns[0]].choices.push(columns[j])
          }
          else{
            flowchart[columns[0]].results.push(columns[j])
          }
      

        }
      }

    console.log("flowchart", flowchart)
    })
    // lines.forEach(line => {
    //   const [node, ...rest] = line.split('_');
    //   const choices = [];
    //   const results = [];

    //   rest.forEach((part, index) => {
    //     if (index % 2 === 0) {
    //       choices.push(part);
    //     } else {
    //       const resultNodes = part.split(',').map(result => result.trim());
    //       if (resultNodes.length > 1) {
    //         results.push({ type: 'end', nodes: resultNodes });
    //       } else {
    //         const result = resultNodes[0].replace(/[{}]/g, '');
    //         if (result.startsWith('#')) {
    //           results.push({ type: 'node', node: result.slice(1) });
    //         } else {
    //           results.push({ type: 'end', nodes: resultNodes });
    //         }
    //       }
    //     }
    //   });

    //   flowchart[node.replace('#', '')] = { choices, results };
    // });
return(flowchart)
  }



  
  const handleSend = () => {
    let flowchart=jsonOutput
    if (input.trim()) {
      // console.log("set messages",messages)
      // setMessages([...messages, { text: input, sender: 'user' }]);
      // const choiceIndex = currentNode.choices.indexOf(input.trim());
      // if (choiceIndex !== -1) {
      //   const nextNodeKey = currentNode.results[choiceIndex];
      //   const nextNode = flowchart[nextNodeKey];
      //   console.log("next node",nextNode)
      //   setMessages((prevMessages) => [
      //     ...prevMessages,
      //     { text: nextNode.message || `You selected: ${input.trim()}`, sender: 'bot' }
      //   ]);

      //   setCurrentNode(nextNode);
      // } else {
      //   setMessages((prevMessages) => [
      //     ...prevMessages,
      //     { text: 'Invalid choice. Please try again.', sender: 'bot' }
      //   ]);
      // }
    //   if(input[0]==="#"){
    //     console.log("input question",input)
    //     if(input!==tempQues &&  tempQues!==""){
    //       setMessages((x)=>{
    //         return(
    //           [
    //           ...x,
    //           {
    //             text:"Invalid Question Entry! Did you mean-"+tempQues,
    //             sender:"bot"
    //           }
    //           ]
    //         )
    //       })
    //       return(0)
    //     }
    //     else if(tempQues===""){
    //       console.log("first question",input,flowchart[input])
    //       if(flowchart[input]===undefined){
    //         setMessages((x)=>{
    //           return(
    //             [
    //               ...x,
    //               {
    //               text:"Unknown",
    //               sender:"bot"
    //               }
    //             ]
    //           )
    //         })
    //       return(0)
    //       }

    //     }
    //     setTempQues(input)
    //     setMessages((x)=>{
    //       return(
    //         [
    //           ...x,
    //           {
    //           text:input,sender:"user"
    //           },
    //           {
    //             text:"Select Choice",sender:"bot"
    //           }
    //         ]
    //       )
    //     })
    //   }
    //   else{
    //     console.log(input)
    //     if(input[0]==="_"){
    //     setMessages((x)=>{
    //       let indexChoice=-1

    //       indexChoice=flowchart[tempQues].choices.indexOf(input)
          
    //       if(indexChoice===-1){
    //         return(
    //           [
    //             ...x,
    //             {
    //               text:"Invalid!Please Enter Again",
    //               sender:"bot"
    //             }
    //           ]
    //         )
    //       }
    //       else{
    //         let resultNode=flowchart[tempQues].results[indexChoice]
    //         setTempQues(resultNode)
    //       return([
    //         ...x,
    //         {

    //           text:input,
    //           sender:"user"
    //         },
    //         {
    //           text:resultNode[0]==="{"?"Final Node Result="+resultNode+" **FLOW ENDS HERE**":"Select Question",
    //           sender:"bot"
    //         }
    //       ])
    //     }
    //     })
    //   }
    //   else{
    //     setMessages((x)=>{
    //       return(
    //       [
    //         ...x,
    //         {
    //         text:"Invalid!Please Again",
    //         sender:"bot"
    //         }
    //       ])
    //     })
    //   }
    // }
    

    //handle chat input through openAI apo
    const apiCall =async()=>
    
    {
        const newMessages = [...messages, { role: 'user', content: input }];
    setMessages(newMessages);
        try {
            const response = await axios.post('https://api.openai.com/v1/chat/completions', {
              model:modelName,
              messages:newMessages,
              max_tokens: 1000,
              temperature: 0.7,
            }, {
              headers: {
                'Authorization': `Bearer sk-proj-JVoiGuhIQW94RxcroSkZT3BlbkFJsQt7LXl4ckvGjOyruQ6I`,
                'Content-Type': 'application/json',
              },
            });
      
            const botResponse = response.data.choices[0].message.content.trim();
            setMessages([...newMessages, { role: 'assistant', content: botResponse }]);
            setInput('');
          } catch (error) {
            console.error('Error fetching data from OpenAI API:', error);
          }
    }
apiCall()
      setInput('');
  };


  }
  function showChatBot(){
    setFlagChatBot(!flagChatBot)
  }
  return (
    
    <div  className='chatBContainer' >
    <div className="chatbot">
    {flagChatBot===true &&  
    <div className='chatContainer'  >  

      <div className="chat-window">
        {messages.map((msg, index) => (
          <div key={index} className={`message ${msg.role}`}>
            {msg.content}
          </div>
        ))}
      </div>
      <div className="input-container">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleSend()}
        />
        <button onClick={processInput}>Send</button>
      </div>
    </div>
}





<div style={{ textAlign: "center", height: "20%",textAlign:"right" }}>
  {
    props.flagMode[0]==="n" && props.flagMode[props.flagMode.length-1]==="2" &&
    <>
     <button className="btn1" onClick={() => { showChatBot() }}>
    <span className="button-content">
      ChatBot
      {
        flagChatBot === false && <>
                   <Icon.ArrowUpCircle size={20} className="icon" />

        </>
      }
      {
        flagChatBot === true  &&<>
                <Icon.ArrowDownCircle size={20} className="icon" />
        
        </> 
      }

   
    </span>
  </button>
    </>
  }
 {
  <span>

  </span>
 }
</div>
    </div>
    </div>
  );
};

export default ChatBot;

