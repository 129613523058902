// @flow strict
// Copyright (C) 2022 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import React, { useState } from "react";
import styles from "./loginForm.module.css";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { TabConfig } from "../../config/config";

import { apiEndpoint } from "../../utils/common";

import { AuthService } from "../../rpc/auth";
import  Toast from "react-bootstrap/Toast";
import * as Icon from 'react-bootstrap-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import LinkedinPage from  "../Linkedin/LinkedinRedirect"
import Demo from "../Linkedin/LinkedinRedirect"
type LoginProps = {
  admin: boolean,
  onSubmit: () => Promise<void>,
  onSubmit1:()=>Function,
  onSubmit2 :()=>Function 
};

export default function LoginForm(props: LoginProps) {
  const [login, setLogin] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errorLogin, setErrorLogin] = useState<string>("");
  const [password1, setPassword1] = useState<string>("");
  var [showToast,setShowToast]=useState(false)
  async function onClickAdminLogin() {
    await new AuthService(apiEndpoint()).addAdmin({ password: password });
    props.onSubmit();
  }

  async function onClickLogin() {
    console.log(password,password1,"password")
    let proceedOnSubmit=password===password1
    // if(proceedOnSubmit===false){
    //   // setShowToast(true)
    //   toast("Ooops! Passwords don't match", {
    //     position: "top-center",
    //     autoClose:2000,
    //     hideProgressBar: true,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "light",
    //     type:"error"
    //     });
    //   return(0)
    // }
    try {
      await new AuthService(apiEndpoint()).login({
        name: login,
        password: password,
      });
      props.onSubmit();
      props.onSubmit1(login)
      props.onSubmit2(password)
    } catch (err) {
      setPassword("");
      setPassword1("");
      setErrorLogin(
      );
        
        toast("Login failed,try Re-login with correct User Name and Password", {
        position: "top-center",
        autoClose:3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        type:"error"
        });
    }
  }


  async function handleLoginChange(
    e: SyntheticKeyboardEvent<HTMLInputElement>
  ) {
    if (errorLogin !== "") {
      setErrorLogin("");
    }
    setLogin(e.currentTarget.value);
  }
  
  async function handlePasswordChange(
    e: SyntheticKeyboardEvent<HTMLInputElement>
  ) {
    if (errorLogin !== "") {
      setErrorLogin("");
    }
    setPassword(e.currentTarget.value);
  }
  async function handlePasswordChange1(
    e: SyntheticKeyboardEvent<HTMLInputElement>
  ) {
    if (errorLogin !== "") {
      setErrorLogin("");
    }
    setPassword1(e.currentTarget.value);
  }
  return (
   <>
   <div className="toastContainer" style={{marginTop:"100px"}}>
    <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
    </div>
    <div className={styles.root}>
      
      <div className="loginHeadingContainer" style={{ marginBottom: "20px" }}>
        <h3>
          Login
        </h3>
      </div>
      <Form>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>User Name:</Form.Label>
          <Form.Control
            autoComplete="none"
            value={props.admin ? "" : login}
            type="text"
            placeholder={props.admin ? "admin" : "Enter Login Name"}
            disabled={props.admin === true}
            onChange={props.admin === true ? null : handleLoginChange}
          />
        </Form.Group>

        <Form.Group controlId="formBasicPassword">
          <Form.Label>Password:</Form.Label>
          <Form.Control
            autoComplete="none"
            value={props.admin ? "" : password}
            type="password"
            placeholder="Password"
            onChange={handlePasswordChange}
            
          />
        </Form.Group>
        {/* <Form.Group controlId="formBasicPassword">
          <Form.Label>Retype Password:</Form.Label>
          <Form.Control
            autoComplete="none"
            value={props.admin ? "" : password1}
            type="password"
            placeholder="Password"
            onChange={handlePasswordChange1}
          />
        </Form.Group> */}
        <div className="submitButtonContainer" style={{textAlign:"center"}}>
        <Button
          variant="primary"
          disabled={
            props.admin === true
              ? password === ""
              : login === "" || password === ""
          }
          onClick={
            props.admin === true
              ? () => onClickAdminLogin()
              : () => onClickLogin()
          }
        >
          Submit
        </Button>
        </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ flex: 1, backgroundColor: "grey", height: "1px" }} />

            <p style={{ margin: "0 10px" }}>Or</p>

            <div style={{ flex: 1, backgroundColor: "grey", height: "1px" }} />
          </div>
        <div className="lindeginLoginContainer">
            {/* <LinkedinPage ></LinkedinPage> */}
            <Demo></Demo>
        </div>
      </Form>
      {errorLogin !== "" && (
        <h6 class="text-center" style={{ color: "red" }}>
          {errorLogin}
        </h6>
      )}

    </div>

    </>
  );
}
