// @flow strict
// Copyright (C) 2022 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import React, { useState, useEffect } from "react";
import styles from "./workflowmode0.module.css";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Col } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

import axios from "axios";

import ToggleButton from "react-toggle-button";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
import { Check, X } from "../../utils/buttons";
import { apiEndpoint, download } from "../../utils/common";
import { GrStatusWarning } from "react-icons/gr";
import { FaLongArrowAltUp } from "react-icons/fa";
import { TabConfig } from "../../config/config";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {AiFillEye} from "react-icons/ai"
import {BsCloudDownloadFill} from "react-icons/bs"
// import Tooltip from 'react-bootstrap/Tooltip';
import {
  AnsweredContainer,
  BackButton,
  Container,
  DisabledButton,
  DisplayFlow,
  DownloadButton,
  InnerContainer,
  Input,
  NoWorkflowPresent,
  Options,
  QuestionOptions,
  QuestionOptionsList,
  Questions,
  ResetButton,
  StartButton,
  StartContainer,
  TableButtons,
  TableContainer,
  TableHead,
  TableTH,
  TableTR,
  UnorderedList,
  ViewSheetButton,
  WorkflowContainer,
  WorkflowLabels,
  WorkflowTrainingReferenceContainer,
  OpenButton,
  WorkflowButton,
} from "../../styled_components/workFlow";

import { BootstrapTooltip } from "../../utils/tooltip";
import ChatBot from "./chatBot";

type WorkflowMode0Props = {
  isAdmin: boolean,
  filterTemplate: string,
  data: Array<>,
  filterSubItem: string,
  subcomponent: string,
  component: string,
  workflow: string,
  documentProcess: (m: string, s: string, t: string) => Promise<void>,
  training: Array<>,
  reference: Array<>,
  docstate: Array<>,
};

let indexA = [];
let indexB = [];
let indexC = [];

export default function WorkflowMode0(props: WorkflowMode0Props) {
  console.log(props);

  const [checkboxSelect, setCheckboxSelect] = useState<Array<boolean>>([]);

  const [idNode, setIdNode] = useState<string>("");

  const [updateTemplate, setUpdateTemplate] = useState<boolean>(false);
  const [autoSelect, setAutoSelect] = useState<boolean>(false);
  const [initEnable, setInitEnable] = useState<boolean>(false);

  const [userStoreFlowchartInfo, setUserStoreFlowchartInfo] = useState<
    Array<[]>
  >([]);

  const [finalItem, setFinalItem] = useState<Array<[]>>([]);
  const [finalEnable, setFinalEnable] = useState<boolean>(false);
  const [endEnable, setEndEnable] = useState<boolean>(false);

  const [viewWait, setViewWait] = useState<boolean>(false);
  const [subComponentSplit, setSubComponentSplit] = useState<Array<[]>>([]);

  const [sheetItems, setSheetItems] = useState<Array<Project>>([]);
  const [selectedSheetItems, setSelectedSheetItems] = useState<Array<Project>>(
    []
  );
  const [tableItems, setTableItems] = useState<Array<Project>>([]);
  const [selectedTableItems, setSelectedTableItems] = useState<Array<Project>>(
    []
  );
  const [tableTitle, setTableTitle] = useState<string>("");
  const [edtxSxSelect, setEdtxSxSelect] = useState<boolean>(false);
  const [currentSelect, setCurrentSelect] = useState<string>("flowchart");

  // const BootstrapTooltip = styled(({ className, ...props }) => (
  //   <Tooltip {...props} arrow classes={{ popper: className }} />
  // ))(({ theme }) => ({
  //   [`& .${tooltipClasses.arrow}`]: {
  //     color: theme.palette.common.black,
  //   },
  //   [`& .${tooltipClasses.tooltip}`]: {
  //     backgroundColor: theme.palette.common.black,
  //   },
  // }));

  // const storeItem = JSON.parse(JSON.stringify(props.data));
  //   console.log("rerender");
  //   if (storeItem.length > 0) {
  //     for (let i = 0; i < storeItem.length; i++) {
  //       storeItem[i].visible = true;
  //     }
  //   }
  // setUserStoreFlowchartInfo(storeItem);
  useEffect(() => {
    async function admin() {}
    admin();
  }, []);

  useEffect(() => {
    async function fetchMe() {
      setCheckboxSelect([true, false, false]);
      setUpdateTemplate(true);
    }
    fetchMe();
  }, []);

  async function executeUpdateTemplate() {
    console.log("execute Template");
    setSubComponentSplit(props.subcomponent.split("-"));
    setUpdateTemplate(false);
    setAutoSelect(false);
    setFinalItem([]);
    setFinalEnable(false);
    setEndEnable(false);
    indexA = [];
    indexB = [];
    indexC = [];
    setIdNode("");
    setInitEnable(true);
  }

  function onClickStartNavigation() {
    setAutoSelect(true);
  }

  async function onClickResetTree() {
    setUserStoreFlowchartInfo([]);
    setUpdateTemplate(true);
  }

  function processItemMessage(str: string) {
    let x = str.split("<=");
    if (x.length === 2) {
      return "Less Then or Equal" + x[1];
    }
    x = str.split(">=");
    if (x.length === 2) {
      return "Greater Then or Equal" + x[1];
    }
    x = str.split("<");
    if (x.length === 2) {
      return "Less then" + x[1];
    }
    x = str.split(">");
    if (x.length === 2) {
      return "Greater Then" + x[1];
    }

    return str;
  }

  function initTree() {
    console.log("initTree");
    console.log("tree=", props.data);

    setInitEnable(false);
    const storeItem = JSON.parse(JSON.stringify(props.data));
    console.log("here At init");
    if (storeItem.length > 0) {
      for (let i = 0; i < storeItem.length; i++) {
        storeItem[i].visible = true;
      }
    }
    setUserStoreFlowchartInfo(storeItem);
  }

  function onClickNextItemTree(id: string, level, n: number) {
    resMapCheckSelect(id, level, n);
    const storeItem = userStoreFlowchartInfo;
    let found = false;
    for (let i = 0; i < storeItem.length; i++) {
      for (let j = 0; j < storeItem[i].subItem.length; j++) {
        if (idNode === storeItem[i].subItem[j].id) {
          if (
            storeItem[i].subItem[j].item.length === 0 &&
            storeItem[i].subItem[j].link !== ""
          ) {
            const linkedItem = NextLinkedItemTree(storeItem[i].subItem[j].link);
            if (linkedItem.found === true) {
              storeItem[i].subItem[j].item = linkedItem.xitem;
            }
          }
          if (storeItem[i].subItem[j].item.length === 0) {
            found = true;
            setEndEnable(true);
            break;
          }
          for (let k = 0; k < storeItem[i].subItem[j].item.length; k++) {
            if (storeItem[i].subItem[j].item[k].type === "") {
              storeItem[i].subItem[j].item[k].visible = true;
            } else {
              setFinalEnable(true);
              setFinalItem(storeItem[i].subItem[j].item);
              break;
            }
          }
          for (let k = 0; k < storeItem[i].subItem.length; k++) {
            if (idNode !== storeItem[i].subItem[k].id) {
              storeItem[i].subItem[k].visible = false;
              setVisibleStatus(storeItem[i].subItem[k].id, level, 0, 0);
            }
          }
          found = true;
          break;
        } else {
          found = NextTreeLevel(storeItem[i].subItem[j].item, level, idNode);
          if (found === true) {
            break;
          }
        }
      }
      if (found === true) {
        break;
      }
    }
    setUserStoreFlowchartInfo(storeItem);
  }

  function NextLinkedItemTree(id: string) {
    const storeItem = userStoreFlowchartInfo;
    let found = false;
    let xitem = {};
    for (let i = 0; i < storeItem.length; i++) {
      if (id === storeItem[i].id) {
        found = true;
        xitem = storeItem[i];
        break;
      }
      for (let j = 0; j < storeItem[i].subItem.length; j++) {
        const x = LinkedItemTree(storeItem[i].subItem[j].item, id);
        found = x.found;
        xitem = x.xitem;
        if (found === true) {
          break;
        }
      }
      if (found === true) {
        break;
      }
    }
    return { found: found, xitem: xitem };
  }

  function LinkedItemTree(item, id) {
    let found = false;
    let xitem = {};
    for (let i = 0; i < item.length; i++) {
      if (id === item[i].id) {
        xitem = item;
        found = true;
        break;
      }
      if (found === true) {
        break;
      }
      for (let j = 0; j < item[i].subItem.length; j++) {
        const x = LinkedItemTree(item[i].subItem[j].item, id);
        found = x.found;
        xitem = x.xitem;

        if (found === true) {
          break;
        }
      }
      if (found === true) {
        break;
      }
    }
    return { found: found, xitem: xitem };
  }

  function NextTreeLevel(item, level, idNode) {
    let found = false;
    for (let i = 0; i < item.length; i++) {
      for (let j = 0; j < item[i].subItem.length; j++) {
        if (idNode === item[i].subItem[j].id) {
          if (
            item[i].subItem[j].item.length === 0 &&
            item[i].subItem[j].link !== ""
          ) {
            const linkedItem = NextLinkedItemTree(item[i].subItem[j].link);
            if (linkedItem.found === true) {
              item[i].subItem[j].item = linkedItem.xitem;
            }
          }
          if (item[i].subItem[j].item.length === 0) {
            found = true;
            setEndEnable(true);
            break;
          }

          for (let k = 0; k < item[i].subItem[j].item.length; k++) {
            if (item[i].subItem[j].item[k].type === "") {
              item[i].subItem[j].item[k].visible = true;
            } else {
              setFinalEnable(true);
              setFinalItem(item[i].subItem[j].item);
              break;
            }
          }
          for (let k = 0; k < item[i].subItem.length; k++) {
            if (idNode !== item[i].subItem[k].id) {
              item[i].subItem[k].visible = false;
              setVisibleStatus(item[i].subItem[k].id, level, 0, 0);
            }
          }
          found = true;
        } else {
          found = NextTreeLevel(item[i].subItem[j].item, level, idNode);
          if (found === true) {
            break;
          }
        }
      }
      if (found === true) {
        break;
      }
    }
    return found;
  }

  function onClickNextSubItemTree(id: string, level, n: number) {
    resMapCheckSelect(id, level, n);

    const storeItem = userStoreFlowchartInfo;
    let found = false;
    for (let i = 0; i < storeItem.length; i++) {
      if (idNode === storeItem[i].id) {
        if (storeItem[i].subItem.length === 0) {
          found = true;
          setEndEnable(true);
          break;
        }
        for (let j = 0; j < storeItem[i].subItem.length; j++) {
          storeItem[i].subItem[j].visible = true;
        }
        for (let k = 0; k < storeItem.length; k++) {
          if (idNode !== storeItem[k].id) {
            storeItem[k].visible = false;
            setVisibleStatus(storeItem[k].id, level, 0, 0);
          }
        }
        found = true;
        break;
      }
      if (found === true) {
        break;
      }
      for (let j = 0; j < storeItem[i].subItem.length; j++) {
        found = NextSubTreeLevel(storeItem[i].subItem[j].item, level, idNode);
        if (found === true) {
          break;
        }
      }
      if (found === true) {
        break;
      }
    }
    setUserStoreFlowchartInfo(storeItem);
  }

  function NextSubTreeLevel(item, level, idNode) {
    let found = false;
    for (let i = 0; i < item.length; i++) {
      if (idNode === item[i].id) {
        if (item[i].subItem.length === 0) {
          found = true;
          setEndEnable(true);
          break;
        }
        for (let j = 0; j < item[i].subItem.length; j++) {
          item[i].subItem[j].visible = true;
        }
        for (let k = 0; k < item.length; k++) {
          if (idNode !== item[k].id) {
            item[k].visible = false;
            setVisibleStatus(item[k].id, level, 0, 0);
          }
        }
        found = true;
        break;
      }
      if (found === true) {
        break;
      }
      for (let j = 0; j < item[i].subItem.length; j++) {
        found = NextSubTreeLevel(item[i].subItem[j].item, level, idNode);
        if (found === true) {
          break;
        }
      }
      if (found === true) {
        break;
      }
    }
    return found;
  }

  function executeMapIndex(
    id: string,
    level,
    n: number,
    name: string,
    subItem: number
  ) {
    let found = false;
    for (let i = 0; i < indexA.length; i++) {
      if (indexA[i][0] === id && indexA[i][1] === level && indexA[i][2] === n) {
        found = true;
        break;
      }
    }
    if (found === false) {
      if (level === 2) {
        indexA.push([id, level, n, 1, 100, 0, 0, name, 0]);
        indexB = [];
        for (let i = 0; i < indexA.length; i++) {
          if (indexA[indexA.length - 1 - i][6] === 2) {
            indexB.push(indexA[indexA.length - 1 - i]);
          }
        }
        indexC = [];
        for (let i = 0; i < indexA.length; i++) {
          if (indexA[i][6] === 2) {
            indexC.push(indexA[i]);
          }
        }
      } else {
        indexA.push([id, level, n, 1, 100, 0, 0, name, subItem]);
      }
    }
  }

  function getMapCheckSelect(id: string, level, n: number) {
    if (indexA.length > 0) {
      for (let i = 0; i < indexA.length; i++) {
        if (
          indexA[i][0] === id &&
          indexA[i][1] === level &&
          indexA[i][2] === n
        ) {
          if (indexA[i][5] === 0) return false;
          if (indexA[i][5] === 1) {
            if (indexA[i][6] === 0) {
              indexA[i][6] = 1;
            }
            return true;
          }
        }
      }
    }
    return false;
  }

  function autoMapCheckSelect(id: string, level, n: number) {
    if (indexA.length > 0) {
      for (let i = 0; i < indexA.length; i++) {
        if (
          indexA[i][0] === id &&
          indexA[i][1] === level &&
          indexA[i][2] === n
        ) {
          if (indexA[i][6] === 0) return false;
          if (indexA[i][6] === 1) return true;
        }
      }
    }
    return false;
  }

  function resMapCheckSelect(id: string, level, n: number) {
    if (indexA.length > 0) {
      for (let i = 0; i < indexA.length; i++) {
        if (
          indexA[i][0] === id &&
          indexA[i][1] === level &&
          indexA[i][2] === n
        ) {
          indexA[i][6] = 2;
        }
      }
    }
    indexB = [];
    for (let i = 0; i < indexA.length; i++) {
      if (indexA[indexA.length - 1 - i][6] === 2) {
        indexB.push(indexA[indexA.length - 1 - i]);
      }
    }
    indexC = [];
    for (let i = 0; i < indexA.length; i++) {
      if (indexA[i][6] === 2) {
        indexC.push(indexA[i]);
      }
    }

    return false;
  }

  function onClickMapTreeCheckBox(id: string, level, n: number, name: string) {
    console.log("onClickMapTree", id, level, n, name);
    if (indexA.length > 0) {
      for (let i = 0; i < indexA.length; i++) {
        if (
          indexA[i][0] === id &&
          indexA[i][1] === level &&
          indexA[i][2] === n
        ) {
          if (indexA[i][4] === n) {
            indexA[i][4] = 100;
            setIdNode("");
            break;
          } else {
            indexA[i][4] = n;
            indexA[i][5] = 1;
            setIdNode(id);
            break;
          }
        } else {
          indexA[i][4] = 100;
        }
      }
    }
  }

  function getVisibleStatus(id: string, level, n: number, name: string) {
    if (indexA.length > 0) {
      for (let i = 0; i < indexA.length; i++) {
        if (
          indexA[i][0] === id &&
          indexA[i][1] === level &&
          indexA[i][2] === n
        ) {
          if (indexA[i][3] === 0) return false;
          if (indexA[i][3] === 1) return true;
        }
      }
    }
    return false;
  }

  function setVisibleStatus(id: string, level, n: number, name: string) {
    if (indexA.length > 0) {
      for (let i = 0; i < indexA.length; i++) {
        if (indexA[i][0] === id && indexA[i][1] === level) {
          indexA[i][3] = 0;
        }
      }
    }
  }

  function executeTree(
    x1: Array[],
    n0,
    n1: number,
    cnt: number,
    check: boolean
  ) {
    cnt = cnt + 2;
    let subItemflag = true;
    let itemflag = true;
    let itemNameList = "";
    for (let i = 0; i < x1.length; i++) {
      if (indexA.length > 0) {
        for (let j = 0; j < indexA.length; j++) {
          if (indexA[j][0] === x1[i].id) {
            if (indexA[j][6] === 2) {
              itemflag = false;
              for (let k = 0; k < x1[i].subItem.length; k++) {
                for (let k1 = 0; k1 < indexA.length; k1++) {
                  if (indexA[k1][0] === x1[i].subItem[k].id) {
                    if (indexA[k1][6] === 2 || indexA[k1][6] === 6) {
                      subItemflag = false;
                      break;
                    }
                  }
                }
                if (subItemflag === false) {
                  break;
                }
              }
              break;
            }
          }
        }
        if (itemflag === false) {
          break;
        }
      }
    }
    if (itemflag === true) {
      subItemflag = false;
      if (check === true) {
        //    subItemflag = true;
      }
      itemflag = false;
      for (let i = 0; i < x1.length; i++) {
        if (x1[i].visible === true) {
          itemflag = true;
          break;
        }
      }
    }
    if (itemflag === true) {
      for (let k = 0; k < x1.length; k++) {
        itemNameList = itemNameList + x1[k].name;
        if (k !== x1.length - 1) {
          itemNameList = itemNameList + " , ";
        }
      }
    }
    console.log(itemflag, subItemflag, "whileExecutingTree");
    return (
      <Container>
        {itemflag === true && x1.length > 0 && (
          <InnerContainer>
            <h5 style={{ fontSize: "14px" }}>
              Select {processItemMessage(itemNameList)}{" "}
            </h5>
            <UnorderedList>
              {x1.map((x2, n2) => (
                <>
                  {executeMapIndex(x2.id, cnt, n2, x2.name, x1.length)}
                  {getVisibleStatus(x2.id, cnt, n2, x2.name) === true && (
                    <>
                      {getMapCheckSelect(x2.id, cnt, n2, x2.name) === false && (
                        <>
                          <li>
                            <QuestionOptions
                              variant={TabConfig.color}
                              size="sm"
                              disabled={false}
                              onClick={() =>
                                onClickMapTreeCheckBox(x2.id, cnt, n2, x2.name)
                              }
                            >
                              {x2.name}
                            </QuestionOptions>
                          </li>
                        </>
                      )}
                      {autoMapCheckSelect(x2.id, cnt, n2, x2.name) === true &&
                        onClickNextSubItemTree(x2.id, cnt, n2, x2.name)}
                    </>
                  )}
                </>
              ))}
            </UnorderedList>
          </InnerContainer>
        )}

        {subItemflag === true &&
          x1 !== undefined &&
          x1.length > 0 &&
          x1.map((x2, n2) => (
            <>
              {getVisibleStatus(x2.id, cnt, n2, x2.name) === true && (
                <InnerContainer>
                  <h5 style={{ fontSize: "14px" }}>
                    Click on your choice to proceed ?{" "}
                  </h5>
                  <UnorderedList>
                    {x2.subItem.length > 0 &&
                      x2.subItem.map((x3, n3) => (
                        <>
                          {executeMapIndex(
                            x3.id,
                            cnt + 1,
                            n3,
                            x3.name,
                            x2.subItem.length
                          )}

                          {getVisibleStatus(x3.id, cnt + 1, n3, x3.name) ===
                            true && (
                            <div>
                              {getMapCheckSelect(
                                x3.id,
                                cnt + 1,
                                n3,
                                x3.name
                              ) === false && (
                                <>
                                  <QuestionOptionsList>
                                    <QuestionOptions
                                      
                                      variant={TabConfig.color}
                                      size="sm"
                                      disabled={false}
                                      onClick={() =>
                                        onClickMapTreeCheckBox(
                                          x3.id,
                                          cnt + 1,
                                          n3,
                                          x3.name
                                        )
                                      }
                                    >
                                      {x3.name}
                                    </QuestionOptions>
                                  </QuestionOptionsList>
                                </>
                              )}
                              {autoMapCheckSelect(
                                x3.id,
                                cnt + 1,
                                n3,
                                x3.name
                              ) === true &&
                                onClickNextItemTree(
                                  x3.id,
                                  cnt + 1,
                                  n3,
                                  x3.name
                                )}
                            </div>
                          )}
                        </>
                      ))}
                  </UnorderedList>
                </InnerContainer>
              )}
            </>
          ))}

        {subItemflag === false &&
          x1 !== undefined &&
          x1.length > 0 &&
          x1.map((x2, n2) => (
            <>
              {getVisibleStatus(x2.id, cnt, n2, x2.name) === true && (
                <div>
                  {x2.subItem.length > 0 &&
                    x2.subItem.map((x3, n3) => (
                      <>
                        {getVisibleStatus(x3.id, cnt + 1, n3, x3.name) ===
                          true && (
                          <div>
                            {x3.item !== undefined &&
                              x3.item.length > 0 &&
                              executeTree(x3.item, n0, n1, cnt, false)}
                          </div>
                        )}
                      </>
                    ))}
                </div>
              )}
            </>
          ))}
      </Container>
    );
  }

  async function onClickFinalStep(x, y, z) {
    console.log("final Step before document")
    if(props.userId==="nwuser003_vessel"){
      window.alert("Document not available for download\n Code: Restricted - Please contact admin for access")
      return(0)
    }
    switch (x) {
      case "FLOW":
        props.documentProcess(x, props.component, props.subcomponent);
        break;

      case "NDE":
        props.documentProcess(x, y, z);
        break;

      case "NDE-GE":
        props.documentProcess(x, props.component, props.subcomponent);
        break;

      case "NDE-PM":
        props.documentProcess(x, props.component, props.subcomponent);
        break;

      case "NDE-3DMM":
        props.documentProcess(x, props.component, props.subcomponent);
        break;

      case "NDE-E":
        props.documentProcess("NDE-E", y, z);
        break;

      case "NDE-V":
        props.documentProcess("NDE-V", y, z);
        break;

      case "NDE-S":
        props.documentProcess("NDE-S", y, z);
        break;

      case "NDE-C":
        props.documentProcess("NDE-C", y, z);
        break;

      case "NDE-P":
        props.documentProcess("NDE-P", y, z);
        break;

      case "NDE-T":
        props.documentProcess("NDE-T", y, z);
        break;

      case "LOC":
        props.documentProcess(x, props.component, props.subcomponent, z);
        break;

      case "PATH":
        setViewWait(true);
        break;

      default:
        break;
    }
  }

  async function onClickBackNavigate(x) {
    let val = 0;
    let found = false;
    for (let i = 0; i < indexA.length; i++) {
      if (indexA[i][0] === x[0] && indexA[i][5] === 1) {
        val = i;
        indexA[i][5] = 0;
        indexA[i][6] = 0;
        indexA[i][4] = 100;
        found = true;
        break;
      }
    }
    if (found === true) {
      const n = indexA.length - val;
      if (val === 0) {
        indexA.splice(val, n);
      } else {
        indexA.splice(val, n);
      }

      indexB = [];
      for (let i = 0; i < indexA.length; i++) {
        if (indexA[indexA.length - 1 - i][6] === 2) {
          indexB.push(indexA[indexA.length - 1 - i]);
        }
      }
      indexC = [];
      for (let i = 0; i < indexA.length; i++) {
        if (indexA[i][6] === 2) {
          indexC.push(indexA[i]);
        }
      }

      setEndEnable(false);
      setFinalEnable(false);
      setFinalItem([]);
    }
    console.log(indexA);
  }

  async function handleEditor(
    str: string,
    val: number,
    e: SyntheticKeyboardEvent<HTMLInputElement>
  ) {
    switch (str) {
      case "CHECKBOX":
        if (val === 10) {
          setUpdateTemplate(true);
        }
        if (val === 11) {
          setCheckboxSelect([true, false, false]);
        }
        if (val === 12) {
          setSheetItems(props.training);
          setSelectedSheetItems([]);
          setSelectedTableItems([]);
          setTableItems([]);
          setSheetItems(props.training);
          setCurrentSelect("manual");
          setEdtxSxSelect(false);
          setCheckboxSelect([false, true, false]);
        }
        if (val === 13) {
          setSheetItems(props.reference);
          setSelectedSheetItems([]);
          setSelectedTableItems([]);
          setTableItems([]);
          setSheetItems(props.reference);
          setCurrentSelect("reference");
          setEdtxSxSelect(false);
          setCheckboxSelect([false, false, true]);
        }

        break;

      default:
        break;
    }
  }

  async function onClickEditManual() {
    const sheet = "sheet_" + selectedSheetItems[0].id;
    const respy = await axios.get(
      apiEndpoint() +
        `/api/blob/folder/sheetfile/read/${"admin"}/${
          props.workflow
        }/${currentSelect}/${sheet}/${"table.json"}`
    );
    if (respy.status === 200) {
      setTableItems(respy.data.tableItem);
      setTableTitle(respy.data.title);
    }
    setEdtxSxSelect(true);
  }

  function onClickEdtBackManual() {
    setEdtxSxSelect(false);
  }

  function onClickOpenURL(x) {
    window.open(x.name, "_blank");
  }

  function checkTableItemCol(str: string, link: string) {
    if (selectedTableItems.length !== 0) return true;
    let x = false;
    switch (str) {
      case "Download":
        if (link === "") x = true;
        break;

      default:
        x = true;
        break;
    }
    return x;
  }

  async function onClickManualTableItemAction(
    str: string,
    link: string,
    rowno: string,
    colno: string,
    filename: string
  ) {
    const sheet = "sheet_" + selectedSheetItems[0].id;
    const rowx = "row_" + rowno;
    const colx = "col_" + colno;
    switch (str) {
      case "Download":
        await axios
          .get(
            apiEndpoint() +
              `/api/blob/folder/sheet/read/${"admin"}/${
                props.workflow
              }/${currentSelect}/${sheet}/${rowx}/${colx}/${link}`,
            { responseType: "blob" }
          )
          .then((response) => {
            const downloadUrl = window.URL.createObjectURL(
              new Blob([response.data])
            );
            download(filename, "", downloadUrl);
          });
        window.alert("Download Success");
        break;

      default:
        break;
    }
  }

  function onManualSelectedChanged(info: Run) {
    const index = selectedSheetItems.indexOf(info);
    if (index >= 0) {
      setSelectedSheetItems((selectedSheetItems) => [
        ...selectedSheetItems.slice(0, index),
        ...selectedSheetItems.slice(index + 1),
      ]);
    } else {
      setSelectedSheetItems([]);
      setSelectedTableItems([]);
      setSelectedSheetItems((selectedSheetItems) => [
        ...selectedSheetItems,
        info,
      ]);
    }
  }

  // const renderDownloadTooltip =()=>{
  //   return( <Tooltip id="button-tooltip" {...props}>
  //   Simple tooltip
  // </Tooltip>)
  // }
  return (
    <WorkflowContainer>
      {updateTemplate === true && executeUpdateTemplate()}
      {initEnable === true && initTree()}
      <WorkflowTrainingReferenceContainer>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
          }}
        >
          <WorkflowLabels>
            <Input
              type="radio"
              name="dropdown-group-1"
              checked={checkboxSelect[0]}
              onChange={(e) => handleEditor("CHECKBOX", 11, e)}
            />
            <span >Workflow</span>
          </WorkflowLabels>
          <WorkflowLabels>
            <Input
              type="radio"
              name="dropdown-group-2"
              checked={checkboxSelect[1]}
              onChange={(e) => handleEditor("CHECKBOX", 12, e)}
            />
            <span >Training</span>
          </WorkflowLabels>
          <WorkflowLabels>
            <Input
              type="radio"
              name="dropdown-group-3"
              checked={checkboxSelect[2]}
              onChange={(e) => handleEditor("CHECKBOX", 13, e)}
            />
            <span >Reference</span>
          </WorkflowLabels>
        </div>
        <Options>
          {autoSelect === true && (
            <Form>
              <Form.Row>
                <Col xs="auto" md={{ span: 0, offset: 0 }}>
                  <ResetButton
                    variant={TabConfig.color}
                    size="sm"
                    disabled={false}
                    onClick={() => onClickResetTree()}
                  >
                    Reset
                  </ResetButton>
                </Col>

                <Col xs="auto" md={{ span: 0, offset: 0 }}>
                  {props.docstate[0] !== "NDE-GE" && (
                    <ResetButton
                      variant={TabConfig.color}
                      size="sm"
                      disabled={false}
                      onClick={() => onClickFinalStep("NDE-GE", "", "")}
                    >
                      Global Effectiveness Table ( DM )
                    </ResetButton>
                  )}
                  {props.docstate[0] === "NDE-GE" && (
                    <ResetButton variant={TabConfig.color} size="sm">
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      {props.docstate[3]}
                    </ResetButton>
                  )}
                </Col>
              </Form.Row>
            </Form>
          )}
        </Options>
      </WorkflowTrainingReferenceContainer>

      {checkboxSelect[0] === true && (
        <>
          {autoSelect === false && (
            <StartContainer>
              <StartButton
                variant={TabConfig.color}
                size="sm"
                disabled={false}
                onClick={() => onClickStartNavigation()}
              >
                {" "}
                Start
              </StartButton>
            </StartContainer>
          )}

          {autoSelect === true && (
            <>
              {autoSelect === true && endEnable === true && (
                <NoWorkflowPresent>
                  <GrStatusWarning
                    style={{ width: 40, height: 40, color: "yellow" }}
                    width={40}
                    height={40}
                    color={"yellow"}
                  />
                  <h6
                    style={{
                      textAlign: "center",
                      color: "#c79710",
                    }}
                  >
                    No Workflow Sequence,Reset to continue...
                  </h6>
                </NoWorkflowPresent>
              )}

              {autoSelect === true && finalEnable === true && (
                <>
                  <h6 class="text-center" style={{ marginTop: "10px" }}>
                    {"NDE Information"}
                  </h6>
                  {finalItem.length > 0 && (
                    <>
                      <TableContainer>
                        <TableHead>
                          <tr>
                            <TableTH>Reference</TableTH>
                            <TableTH>Navigated Path</TableTH>
                            {finalItem.length > 0 &&
                              finalItem.map((x) =>
                                x.type === "LOC" ? (
                                  <TableTH>{x.name}</TableTH>
                                ) : (
                                  <></>
                                )
                              )}
                            <TableTH>NDE Intro Session</TableTH>
                            <TableTH>3DMM URL</TableTH>
                          </tr>
                        </TableHead>
                        <tbody>
                          <TableTR>
                            <td style={{textAlign:'center'}} style={{textAlign:'center'}}>
                              {props.docstate[0] !== "FLOW" && (
                                <BootstrapTooltip title="Flowchart">
                                  <TableButtons
                                    variant={TabConfig.color}
                                    size="sm"
                                    disabled={false}
                                    onClick={() =>
                                      onClickFinalStep("FLOW", "", "")
                                    }
                                  >
                                    {"Flowchart"}
                                  </TableButtons>
                                </BootstrapTooltip>
                              )}
                              {props.docstate[0] === "FLOW" && (
                                <BootstrapTooltip title={props.docstate[3]}>
                                  <TableButtons
                                    variant={TabConfig.color}
                                    size="sm"
                                  >
                                    <Spinner
                                      as="span"
                                      animation="grow"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                    {props.docstate[3]}
                                    {props.percentValueForButton}
                                  </TableButtons>
                                </BootstrapTooltip>
                              )}
                            </td>
                            <td style={{textAlign:'center'}} style={{textAlign:'center'}}>
                              <BootstrapTooltip title={"View"}>
                                <TableButtons
                                  
                                  variant={TabConfig.color}
                                  size="sm"
                                  disabled={false}
                                  onClick={() =>
                                    onClickFinalStep("PATH", "", "")
                                  }
                                >
                                  <AiFillEye color="purple" />
                                </TableButtons>
                              </BootstrapTooltip>
                            </td>
                            <td style={{textAlign:'center'}} style={{textAlign:'center'}}>
                              {finalItem.length > 0 &&
                                finalItem.map((x) =>
                                  x.type === "LOC" ? (
                                    <>
                                      {x.subItem.length > 0 && (
                                        <>
                                          {props.docstate[0] !== x.type && (
                                            <BootstrapTooltip
                                              title={x.subItem[0].name}
                                            >
                                              <TableButtons
                                                
                                                variant={TabConfig.color}
                                                size="sm"
                                                disabled={false}
                                                onClick={() =>
                                                  onClickFinalStep(
                                                    x.type,
                                                    x.name,
                                                    x.subItem[0].name
                                                  )
                                                }
                                              >
                                                {x.subItem[0].name}
                                              </TableButtons>
                                            </BootstrapTooltip>
                                          )}
                                          {props.docstate[0] === x.type && (
                                            <BootstrapTooltip
                                              title={props.docstate[3]}
                                            >
                                              <TableButtons
                                                variant={TabConfig.color}
                                                size="sm"
                                              >
                                                <Spinner
                                                  as="span"
                                                  animation="grow"
                                                  size="sm"
                                                  role="status"
                                                  aria-hidden="true"
                                                />
                                                {props.docstate[3]}
                                                {props.percentValueForButton}
                                              </TableButtons>
                                            </BootstrapTooltip>
                                          )}
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )
                                )}
                            </td>

                            <td style={{textAlign:'center'}}>
                              {props.docstate[0] !== "NDE-PM" && (
                                <BootstrapTooltip title={"Download"}>
                                  <TableButtons
                                    
                                    variant={TabConfig.color}
                                    size="sm"
                                    disabled={false}
                                    onClick={() =>
                                      onClickFinalStep("NDE-PM", "", "")
                                    }
                                  >
                                    <BsCloudDownloadFill color="purple" />
                                  </TableButtons>
                                </BootstrapTooltip>
                              )}
                              {props.docstate[0] === "NDE-PM" && (
                                <BootstrapTooltip title={props.docstate[3]}>
                                  <TableButtons
                                    variant={TabConfig.color}
                                    size="sm"
                                  >
                                    <Spinner
                                      as="span"
                                      animation="grow"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                    {props.docstate[3]}
                                    {props.percentValueForButton}
                                  </TableButtons>
                                </BootstrapTooltip>
                              )}
                            </td>

                            <td style={{textAlign:'center'}}>
                              {props.docstate[0] !== "NDE-3DMM" && (
                                <BootstrapTooltip title={"Open"}>
                                  <TableButtons
                                    
                                    variant={TabConfig.color}
                                    size="sm"
                                    disabled={false}
                                    onClick={() =>
                                      onClickFinalStep("NDE-3DMM", "", "")
                                    }
                                  >
                                    {"Open"}
                                  </TableButtons>
                                </BootstrapTooltip>
                              )}
                              {props.docstate[0] === "NDE-3DMM" && (
                                <BootstrapTooltip title={props.docstate[3]}>
                                  <TableButtons
                                    variant={TabConfig.color}
                                    size="sm"
                                  >
                                    <Spinner
                                      as="span"
                                      animation="grow"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />

                                    {props.percentValueForButton}
                                  </TableButtons>
                                </BootstrapTooltip>
                              )}
                            </td>
                          </TableTR>
                        </tbody>
                      </TableContainer>
                      <h6
                        style={{
                          textAlign: "right",
                          fontSize: "small",
                          color: "red",
                          marginTop: "5px",
                        }}
                      >
                        {"* Location Spreadsheet download and view"}
                      </h6>
                    </>
                  )}
                  {finalItem.length > 0 && (
                    <TableContainer>
                      <TableHead>
                        <tr>
                          {props.tableHeaders[1].map((element, i) => {
                            if (i != 1) {
                              if (i < 8) {
                                return <TableTH>{element.name}</TableTH>;
                              } else {
                                if (i === 9) {
                                  return (
                                    <TableTH>
                                      {element.name.slice(
                                        0,
                                        element.name.length - 2
                                      )}
                                    </TableTH>
                                  );
                                }
                              }
                            }
                          })}
                        </tr>
                      </TableHead>
                      <tbody>
                        {finalItem.length > 0 &&
                          finalItem.map((x) =>
                            x.type === "NDE" ? (
                              <TableTR>
                                <td style={{textAlign:'center'}}>{x.name}</td>
                                <td style={{textAlign:'center'}}>
                                  {x.subItem.length > 0 &&
                                    (props.docstate[0] !== x.type ||
                                      (props.docstate[0] === x.type &&
                                        (props.docstate[1] !== x.name ||
                                          props.docstate[2] !==
                                            x.subItem[0].name))) && (
                                      <BootstrapTooltip
                                        title={x.subItem[0].name}
                                      >
                                        <TableButtons
                                          
                                          variant={TabConfig.color}
                                          size="sm"
                                          disabled={false}
                                          onClick={() =>
                                            onClickFinalStep(
                                              x.type,
                                              x.name,
                                              x.subItem[0].name
                                            )
                                          }
                                        >
                                          {x.subItem[0].name}
                                        </TableButtons>
                                      </BootstrapTooltip>
                                    )}
                                  {props.docstate[0] === x.type &&
                                    props.docstate[1] === x.name &&
                                    props.docstate[2] === x.subItem[0].name && (
                                      <BootstrapTooltip
                                        title={props.docstate[3]}
                                      >
                                        <TableButtons
                                          variant={TabConfig.color}
                                          size="sm"
                                        >
                                          <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                          />
                                          {props.docstate[3]}
                                          {props.percentValueForButton + "%"}
                                        </TableButtons>
                                      </BootstrapTooltip>
                                    )}
                                </td>
                                <td style={{textAlign:'center'}}>
                                  {(props.docstate[0] !== "NDE-E" ||
                                    (props.docstate[0] === "NDE-E" &&
                                      (props.docstate[1] !== x.name ||
                                        props.docstate[2] !==
                                          x.subItem[0].name))) && (
                                    <BootstrapTooltip title={"View"}>
                                      <TableButtons
                                        
                                        variant={TabConfig.color}
                                        size="sm"
                                        disabled={false}
                                        onClick={() =>
                                          onClickFinalStep(
                                            "NDE-E",
                                            x.name,
                                            x.subItem[0].name
                                          )
                                        }
                                      >
                                        <AiFillEye color="purple" />
                                      </TableButtons>
                                    </BootstrapTooltip>
                                  )}
                                  {props.docstate[0] === "NDE-E" &&
                                    props.docstate[1] === x.name &&
                                    props.docstate[2] === x.subItem[0].name && (
                                      <BootstrapTooltip title={"View"}>
                                        <TableButtons
                                          variant={TabConfig.color}
                                          size="sm"
                                        >
                                          <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                          />
                                          {props.docstate[3]}
                                          {props.percentValueForButton + "%"}
                                        </TableButtons>
                                      </BootstrapTooltip>
                                    )}
                                </td>
                                <td style={{textAlign:'center'}}>
                                  {(props.docstate[0] !== "NDE-V" ||
                                    (props.docstate[0] === "NDE-V" &&
                                      (props.docstate[1] !== x.name ||
                                        props.docstate[2] !==
                                          x.subItem[0].name))) && (
                                    <BootstrapTooltip title={"View"}>
                                      <TableButtons
                                        
                                        variant={TabConfig.color}
                                        size="sm"
                                        disabled={false}
                                        onClick={() =>
                                          onClickFinalStep(
                                            "NDE-V",
                                            x.name,
                                            x.subItem[0].name
                                          )
                                        }
                                      >
                                        <AiFillEye color="purple" />
                                      </TableButtons>
                                    </BootstrapTooltip>
                                  )}
                                  {props.docstate[0] === "NDE-V" &&
                                    props.docstate[1] === x.name &&
                                    props.docstate[2] === x.subItem[0].name && (
                                      <BootstrapTooltip
                                        title={props.docstate[3]}
                                      >
                                        <TableButtons
                                          variant={TabConfig.color}
                                          size="sm"
                                        >
                                          <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                          />

                                          {props.percentValueForButton + "%"}
                                        </TableButtons>
                                      </BootstrapTooltip>
                                    )}
                                </td>
                                <td style={{textAlign:'center'}}>
                                  {(props.docstate[0] !== "NDE-S" ||
                                    (props.docstate[0] === "NDE-S" &&
                                      (props.docstate[1] !== x.name ||
                                        props.docstate[2] !==
                                          x.subItem[0].name))) && (
                                    <BootstrapTooltip title={props.docstate[3]}>
                                      <TableButtons
                                        
                                        variant={TabConfig.color}
                                        size="sm"
                                        disabled={false}
                                        onClick={() =>
                                          onClickFinalStep(
                                            "NDE-S",
                                            x.name,
                                            x.subItem[0].name
                                          )
                                        }
                                      >
                                        <AiFillEye color="purple" />
                                      </TableButtons>
                                    </BootstrapTooltip>
                                  )}
                                  {props.docstate[0] === "NDE-S" &&
                                    props.docstate[1] === x.name &&
                                    props.docstate[2] === x.subItem[0].name && (
                                      <BootstrapTooltip
                                        title={props.docstate[3]}
                                      >
                                        <TableButtons
                                          variant={TabConfig.color}
                                          size="sm"
                                        >
                                          <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                          />
                                          {props.docstate[3]}
                                          {props.percentValueForButton + "%"}
                                        </TableButtons>
                                      </BootstrapTooltip>
                                    )}
                                </td>
                                <td style={{textAlign:'center'}}>
                                  {(props.docstate[0] !== "NDE-C" ||
                                    (props.docstate[0] === "NDE-C" &&
                                      (props.docstate[1] !== x.name ||
                                        props.docstate[2] !==
                                          x.subItem[0].name))) && (
                                    <BootstrapTooltip title={"View"}>
                                      <TableButtons
                                        
                                        variant={TabConfig.color}
                                        size="sm"
                                        disabled={false}
                                        onClick={() =>
                                          onClickFinalStep(
                                            "NDE-C",
                                            x.name,
                                            x.subItem[0].name
                                          )
                                        }
                                      >
                                        <AiFillEye color="purple" />
                                      </TableButtons>
                                    </BootstrapTooltip>
                                  )}
                                  {props.docstate[0] === "NDE-C" &&
                                    props.docstate[1] === x.name &&
                                    props.docstate[2] === x.subItem[0].name && (
                                      <BootstrapTooltip
                                        title={props.docstate[3]}
                                      >
                                        <TableButtons
                                          variant={TabConfig.color}
                                          size="sm"
                                        >
                                          <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                          />
                                          {props.docstate[3]}
                                          {props.percentValueForButton + "%"}
                                        </TableButtons>
                                      </BootstrapTooltip>
                                    )}
                                </td>
                                <td style={{textAlign:'center'}}>
                                  {(props.docstate[0] !== "NDE-P" ||
                                    (props.docstate[0] === "NDE-P" &&
                                      (props.docstate[1] !== x.name ||
                                        props.docstate[2] !==
                                          x.subItem[0].name))) && (
                                    <BootstrapTooltip title={"Download"}>
                                      <TableButtons
                                        
                                        variant={TabConfig.color}
                                        size="sm"
                                        disabled={false}
                                        onClick={() =>
                                          onClickFinalStep(
                                            "NDE-P",
                                            x.name,
                                            x.subItem[0].name
                                          )
                                        }
                                      >
                                        <BsCloudDownloadFill color="purple" />
                                      </TableButtons>
                                    </BootstrapTooltip>
                                  )}
                                  {props.docstate[0] === "NDE-P" &&
                                    props.docstate[1] === x.name &&
                                    props.docstate[2] === x.subItem[0].name && (
                                      <BootstrapTooltip
                                        title={props.docstate[3]}
                                      >
                                        <TableButtons
                                          variant={TabConfig.color}
                                          size="sm"
                                        >
                                          <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                          />
                                          {props.docstate[3]}
                                          {props.percentValueForButton + "%"}
                                        </TableButtons>
                                      </BootstrapTooltip>
                                    )}
                                </td>

                                <td style={{textAlign:'center'}}>
                                  {(props.docstate[0] !== "NDE-T" ||
                                    (props.docstate[0] === "NDE-T" &&
                                      (props.docstate[1] !== x.name ||
                                        props.docstate[2] !==
                                          x.subItem[0].name))) && (
                                    <BootstrapTooltip title={"View"}>
                                      <TableButtons
                                        
                                        variant={TabConfig.color}
                                        size="sm"
                                        disabled={false}
                                        onClick={() =>
                                          onClickFinalStep(
                                            "NDE-T",
                                            x.name,
                                            x.subItem[0].name
                                          )
                                        }
                                      >
                                        <AiFillEye color="purple" />
                                      </TableButtons>
                                    </BootstrapTooltip>
                                  )}
                                  {props.docstate[0] === "NDE-T" &&
                                    props.docstate[1] === x.name &&
                                    props.docstate[2] === x.subItem[0].name && (
                                      <BootstrapTooltip
                                        title={props.docstate[3]}
                                      >
                                        <TableButtons
                                          variant={TabConfig.color}
                                          size="sm"
                                        >
                                          <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                          />
                                          {props.docstate[3]}
                                          {props.percentValueForButton + "%"}
                                        </TableButtons>
                                      </BootstrapTooltip>
                                    )}
                                </td>
                              </TableTR>
                            ) : (
                              <></>
                            )
                          )}
                      </tbody>
                    </TableContainer>
                  )}
                </>
              )}
              <Questions>
                {autoSelect === true &&
                  finalEnable === false &&
                  endEnable === false &&
                  userStoreFlowchartInfo.length > 0 &&
                  executeTree(userStoreFlowchartInfo, 0, 1, 0, true)}
              </Questions>
              <>
              
              
                
                <div style={{ display: "flex", flexDirection: "row", border: "1px dotted grey",boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",borderRadius:"2px" }}>
                  <AnsweredContainer>

                    {autoSelect === true &&
                      userStoreFlowchartInfo.length > 0 &&
                      indexB.length > 0 &&
                      indexB.map((x, index) => (
                       
                          <>
                          {x[6] === 2 && index === 0 && x[1] % 2 === 0 && (
                            <div style={{marginLeft:"27%"}}>
                              <DisplayFlow>
                                <li>
                                  <Button
                                    variant={"dark"}
                                    size="sm"
                                    disabled={true}
                                  >
                                    {x[7]}
                                  </Button>
                                </li>
                              </DisplayFlow>
                            </div>
                          )}

                          {x[6] === 2 && x[1] % 2 === 1 && (
                            <>
                              <div style={{marginLeft:"27%"}}>
                                <FaLongArrowAltUp />
                              </div>
                              <div style={{marginLeft:"27%"}}>
                                <DisplayFlow>
                                  <li>
                                    <DisabledButton size="sm" disabled={true}>
                                      {x[7]}
                                    </DisabledButton>
                                  </li>
                                  {index + 1 < indexB.length && (
                                    <li>
                                      <Button
                                        variant={"danger"}
                                        size="sm"
                                        disabled={false}
                                        onClick={() =>
                                          onClickBackNavigate(indexB[index + 1])
                                        }
                                      >
                                        {indexB[index + 1][7]}
                                      </Button>
                                    </li>
                                  )}
                                </DisplayFlow>
                              </div>
                            </>
                          )}
                        </>
                      ))}
                  </AnsweredContainer>
                  <div className="chatBot" style={{
                    marginLeft: "auto",
                    width:"20%"
                  }}>
                    <div className="chatBotContainer" style={{ margin: "1px" }}>
                      <ChatBot flagMode={"normal1"} template={props.workflow} compName={props.component} subCompName={props.subcomponent}></ChatBot>
                    </div>
                  </div>

                </div>
              </>
              {/* {autoSelect === true && userStoreFlowchartInfo.length > 0 && (
                <div className={styles.dtNode}>
                  <ul>
                    {subComponentSplit.length > 0 &&
                      subComponentSplit
                        .slice(0)
                        .reverse()
                        .map((x) => (
                          <li>
                            <Button
                              
                              variant={"dark"}
                              size="sm"
                              disabled={true}
                            >
                              {x}
                            </Button>
                          </li>
                        ))}
                    <li>
                      <Button
                        
                        variant={"dark"}
                        size="sm"
                        disabled={true}
                      >
                        {props.component}
                      </Button>
                    </li>
                    <li>
                      <Button
                        
                        variant={"dark"}
                        size="sm"
                        disabled={true}
                      >
                        {props.workflow}
                      </Button>
                    </li>
                  </ul>
                </div>
              )} */}
            </>
          )}

          {viewWait === true && (
            <Modal
              show={true}
              backdrop="static"
              centered
              size="xl"
              onHide={() => setViewWait(false)}
            >
              <Modal.Header closeButton> </Modal.Header>
              <div style={{ height: "600px", overflow: "auto" }}>
                <div
                  class="text-center"
                  style={{
                    backgroundColor: "#AF69EF",
                    width: "30%",
                    border: "2px solid black",
                    padding: "15px",
                    marginLeft: "35%",
                    marginTop: "5px",
                  }}
                >
                  {props.workflow}
                </div>
                <div
                  style={{
                    fontSize: "24px",
                    marginLeft: "50%",
                    marginTop: "5px",
                  }}
                >
                  {" "}
                  <i style={{ marginLeft: "0px" }} class="fa fa-arrow-down"></i>
                </div>
                <div
                  class="text-center"
                  style={{
                    backgroundColor: "#AF69EF",
                    width: "30%",
                    border: "2px solid black",
                    padding: "15px",
                    marginLeft: "35%",
                    marginTop: "5px",
                  }}
                >
                  {props.component}
                </div>
                <div
                  style={{
                    fontSize: "24px",
                    marginLeft: "50%",
                    marginTop: "5px",
                  }}
                >
                  {" "}
                  <i style={{ marginLeft: "0px" }} class="fa fa-arrow-down"></i>
                </div>
                {subComponentSplit.length > 0 &&
                  subComponentSplit.map((x) => (
                    <>
                      <div
                        class="text-center"
                        style={{
                          backgroundColor: "#AF69EF",
                          width: "30%",
                          border: "2px solid black",
                          padding: "15px",
                          marginLeft: "35%",
                          marginTop: "5px",
                        }}
                      >
                        {x}
                      </div>
                      <div
                        style={{
                          fontSize: "24px",
                          marginLeft: "50%",
                          marginTop: "5px",
                        }}
                      >
                        {" "}
                        <i
                          style={{ marginLeft: "0px" }}
                          class="fa fa-arrow-down"
                        ></i>
                      </div>
                    </>
                  ))}
                {autoSelect === true &&
                  userStoreFlowchartInfo.length > 0 &&
                  indexC.length > 0 &&
                  indexC.map((x, index) => (
                    <div>
                      {x[6] === 2 && x[8] <= 1 && (
                        <>
                          {x[1] % 2 === 1 && (
                            <>
                              <div
                                class="text-center"
                                style={{
                                  backgroundColor: "#E39FF6",
                                  width: "30%",
                                  border: "2px solid black",
                                  padding: "15px",
                                  marginLeft: "35%",
                                  marginTop: "5px",
                                }}
                              >
                                {x[7]}
                              </div>
                              {index !== indexC.length - 1 && (
                                <div
                                  style={{
                                    fontSize: "24px",
                                    marginLeft: "50%",
                                    marginTop: "30px",
                                  }}
                                >
                                  {" "}
                                  <i
                                    style={{ marginLeft: "0px" }}
                                    class="fa fa-arrow-down"
                                  ></i>
                                </div>
                              )}
                            </>
                          )}

                          {x[1] % 2 === 0 && index === indexC.length - 1 && (
                            <>
                              <div
                                class="text-center"
                                style={{
                                  backgroundColor: "#E39FF6",
                                  width: "30%",
                                  border: "2px solid black",
                                  padding: "15px",
                                  marginLeft: "35%",
                                  marginTop: "5px",
                                }}
                              >
                                {x[7]}
                              </div>
                              {index !== indexC.length - 1 && (
                                <div
                                  style={{
                                    fontSize: "24px",
                                    marginLeft: "50%",
                                    marginTop: "30px",
                                  }}
                                >
                                  {" "}
                                  <i
                                    style={{ marginLeft: "0px" }}
                                    class="fa fa-arrow-down"
                                  ></i>
                                </div>
                              )}
                            </>
                          )}

                          {x[1] % 2 === 0 &&
                            index !== indexC.length - 1 &&
                            indexC[index + 1][8] <= 1 && (
                              <>
                                <div
                                  class="text-center"
                                  style={{
                                    backgroundColor: "#E39FF6",
                                    width: "30%",
                                    border: "2px solid black",
                                    padding: "15px",
                                    marginLeft: "35%",
                                    marginTop: "5px",
                                  }}
                                >
                                  {x[7]}
                                </div>
                                {index !== indexC.length - 1 && (
                                  <div
                                    style={{
                                      fontSize: "24px",
                                      marginLeft: "50%",
                                      marginTop: "30px",
                                    }}
                                  >
                                    {" "}
                                    <i
                                      style={{ marginLeft: "0px" }}
                                      class="fa fa-arrow-down"
                                    ></i>
                                  </div>
                                )}
                              </>
                            )}

                          {x[1] % 2 === 0 &&
                            index !== indexC.length - 1 &&
                            indexC[index + 1][8] >= 2 && (
                              <>
                                <div
                                  style={{
                                    marginLeft: "43%",
                                    marginTop: "35px",
                                  }}
                                >
                                  <div
                                    style={{
                                      marginLeft: "15px",
                                      backgroundColor: "#E39FF6",
                                      width: "150px",
                                      height: "150px",
                                      border: "1px solid black",
                                      transform: "rotate(45deg)",
                                    }}
                                  >
                                    <div
                                      style={{
                                        textAlign: "center",
                                        padding: "70px 0",
                                        transform: "rotate(-45deg)",
                                      }}
                                    >
                                      {x[7]}
                                    </div>
                                  </div>
                                </div>

                                {index !== indexC.length - 1 && (
                                  <div
                                    style={{
                                      fontSize: "24px",
                                      marginLeft: "50%",
                                      marginTop: "30px",
                                    }}
                                  >
                                    {" "}
                                    <i
                                      style={{ marginLeft: "0px" }}
                                      class="fa fa-arrow-down"
                                    ></i>
                                  </div>
                                )}
                                {index !== indexC.length - 1 &&
                                  indexC[index + 1][8] >= 2 && (
                                    <div
                                      style={{
                                        fontSize: "24px",
                                        marginLeft: "55%",
                                        marginTop: "-40px",
                                      }}
                                    >
                                      {indexC[index + 1][7]}
                                    </div>
                                  )}
                              </>
                            )}
                        </>
                      )}

                      {x[6] === 2 && x[8] >= 2 && x[1] % 2 === 0 && (
                        <>
                          <div style={{ marginLeft: "43%", marginTop: "35px" }}>
                            <div
                              style={{
                                marginLeft: "15px",
                                backgroundColor: "#E39FF6",
                                width: "150px",
                                height: "150px",
                                border: "1px solid black",
                                transform: "rotate(45deg)",
                              }}
                            >
                              <div
                                style={{
                                  textAlign: "center",
                                  padding: "70px 0",
                                  transform: "rotate(-45deg)",
                                }}
                              >
                                {x[7]}
                              </div>
                            </div>
                          </div>
                          {index !== indexC.length - 1 && (
                            <div
                              style={{
                                fontSize: "24px",
                                marginLeft: "50%",
                                marginTop: "30px",
                              }}
                            >
                              {" "}
                              <i
                                style={{ marginLeft: "0px" }}
                                class="fa fa-arrow-down"
                              ></i>
                            </div>
                          )}
                          {index !== indexC.length - 1 &&
                            indexC[index + 1][8] >= 2 && (
                              <div
                                style={{
                                  fontSize: "24px",
                                  marginLeft: "55%",
                                  marginTop: "-40px",
                                }}
                              >
                                {indexC[index + 1][7]}
                              </div>
                            )}
                        </>
                      )}
                    </div>
                  ))}
                <div
                  style={{ marginLeft: "20%", marginRight: "20%" }}
                  className={styles.root}
                >
                  {finalItem.length > 0 && (
                    <table>
                      <TableHead>
                        <tr>
                          <TableTH>NDE Method</TableTH>
                          <TableTH>NDE Sub Method</TableTH>
                        </tr>
                      </TableHead>
                      <tbody>
                        {finalItem.length > 0 &&
                          finalItem.map((x) =>
                            x.type === "NDE" ? (
                              <TableTR>
                                <td style={{textAlign:'center'}}>{x.name}</td>
                                <td style={{textAlign:'center'}}>
                                  {x.subItem.length > 0 && (
                                    <WorkflowButton
                                      
                                      variant={TabConfig.color}
                                      size="sm"
                                      disabled={true}
                                      onClick={() =>
                                        onClickFinalStep(
                                          x.type,
                                          x.name,
                                          x.subItem[0].name
                                        )
                                      }
                                    >
                                      {x.subItem[0].name}
                                    </WorkflowButton>
                                  )}
                                </td>
                              </TableTR>
                            ) : (
                              <></>
                            )
                          )}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>

              <div style={{ marginLeft: "43%", marginTop: "100px" }}></div>
            </Modal>
          )}
        </>
      )}
      {checkboxSelect[1] === true && (
        <>
          {edtxSxSelect === false && (
            <>
              <div style={{ marginTop: "10px" }}>
                <h4 class="text-center">{"Documents"}</h4>
              </div>

              <Form>
                <Form.Row>
                  <ViewSheetButton
                    size="sm"
                    disabled={selectedSheetItems.length === 0}
                    onClick={() => onClickEditManual()}
                  >
                    View Sheet
                  </ViewSheetButton>
                </Form.Row>
              </Form>
              <div>
                <Table striped bordered hover size="sm">
                  <TableHead>
                    <tr>
                      <TableTH>Select</TableTH>
                      <TableTH>Id</TableTH>
                      <TableTH>Name</TableTH>
                      <TableTH>Sub Name</TableTH>
                    </tr>
                  </TableHead>
                  <tbody>
                    {sheetItems.map((x, index) => (
                      <TableTR key={x.id}>
                        <td style={{textAlign:'center'}} className={styles.cellsize}>
                          <ToggleButton
                            inactiveLabel={<X />}
                            activeLabel={<Check />}
                            value={selectedSheetItems.indexOf(x) >= 0}
                            onToggle={() => onManualSelectedChanged(x)}
                          />
                        </td>
                        <td style={{textAlign:'center'}} className={styles.cellsize}>{index + 1}</td>
                        <td style={{textAlign:'center'}}>{x.name}</td>
                        <td style={{textAlign:'center'}}>{x.subname}</td>
                      </TableTR>
                    ))}
                  </tbody>
                </Table>
              </div>
            </>
          )}

          {edtxSxSelect === true && (
            <>
              <div style={{ marginTop: "10px" }}>
                <h4 class="text-center">{tableTitle}</h4>
              </div>
              <BackButton
                variant={TabConfig.color}
                size="sm"
                disabled={false}
                onClick={() => onClickEdtBackManual()}
              >
                Back
              </BackButton>
              <div>
                <Table striped bordered hover size="sm">
                  <TableHead>
                    <tr>
                      {tableItems.length > 0 && (
                        <>
                          <TableTH>Id</TableTH>
                          {tableItems[0].col.map((x, val) => (
                            <TableTH>{x.name}</TableTH>
                          ))}
                        </>
                      )}
                    </tr>
                  </TableHead>
                  <tbody>
                    {tableItems.map((x, index) =>
                      index === 0 ? (
                        <></>
                      ) : (
                        <TableTR key={x.id}>
                          <td style={{textAlign:'center'}} className={styles.cellsize}>{index}</td>

                          {selectedTableItems.indexOf(x) < 0 &&
                            x.col.map((y, uindex) =>
                              y.val.length === 0 ? (
                                <td style={{textAlign:'center'}}>{y.name}</td>
                              ) : (
                                <td style={{textAlign:'center'}}>
                                  <Form>
                                    {y.val.map((d1) =>
                                      d1 === "Download" ? (
                                        <>
                                          <BootstrapTooltip
                                            title={"Download"}
                                            placement="right"
                                          >
                                            <DownloadButton
                                              
                                              variant={TabConfig.color}
                                              size="sm"
                                              disabled={checkTableItemCol(
                                                d1,
                                                y.uploadLink
                                              )}
                                              onClick={() =>
                                                onClickManualTableItemAction(
                                                  d1,
                                                  y.uploadLink,
                                                  x.id,
                                                  y.id,
                                                  y.uploadName
                                                )
                                              }
                                            >
                                              {d1 === "Download" && (
                                                <i
                                                  class="fa fa-download"
                                                  aria-hidden="true"
                                                ></i>
                                              )}
                                            </DownloadButton>
                                          </BootstrapTooltip>
                                        </>
                                      ) : (
                                        <></>
                                      )
                                    )}
                                  </Form>
                                </td>
                              )
                            )}
                        </TableTR>
                      )
                    )}
                  </tbody>
                </Table>
              </div>
            </>
          )}
        </>
      )}
      {checkboxSelect[2] === true && (
        <>
          {edtxSxSelect === false && (
            <>
              <div style={{ marginTop: "10px" }}>
                <h4 class="text-center">{"Information Links"}</h4>
              </div>

              <Form>
                <Form.Row>
                  <ViewSheetButton
                    
                    size="sm"
                    disabled={selectedSheetItems.length === 0}
                    onClick={() => onClickEditManual()}
                  >
                    View Sheet
                  </ViewSheetButton>
                </Form.Row>
              </Form>
              <div>
                <Table striped bordered hover size="sm">
                  <TableHead>
                    <tr>
                      <TableTH>Select</TableTH>
                      <TableTH>Id</TableTH>
                      <TableTH>Name</TableTH>
                      <TableTH>Sub Name</TableTH>
                    </tr>
                  </TableHead>
                  <tbody>
                    {sheetItems.map((x, index) => (
                      <TableTR key={x.id}>
                        <td style={{textAlign:'center'}} className={styles.cellsize}>
                          <ToggleButton
                            inactiveLabel={<X />}
                            activeLabel={<Check />}
                            value={selectedSheetItems.indexOf(x) >= 0}
                            onToggle={() => onManualSelectedChanged(x)}
                          />
                        </td>
                        <td style={{textAlign:'center'}} className={styles.cellsize}>{index + 1}</td>
                        <td style={{textAlign:'center'}}>{x.name}</td>
                        <td style={{textAlign:'center'}}>{x.subname}</td>
                      </TableTR>
                    ))}
                  </tbody>
                </Table>
              </div>
            </>
          )}

          {edtxSxSelect === true && (
            <>
              <div style={{ marginTop: "10px" }}>
                <h4 class="text-center">{tableTitle}</h4>
              </div>

              <BackButton
                variant={TabConfig.color}
                size="sm"
                disabled={false}
                onClick={() => onClickEdtBackManual()}
              >
                Back
              </BackButton>

              <div>
                <Table striped bordered hover size="sm">
                  <TableHead>
                    <tr>
                      {tableItems.length > 0 && (
                        <>
                          <TableTH>Id</TableTH>
                          {tableItems[0].col.map((x, val) => (
                            <TableTH>{x.name}</TableTH>
                          ))}
                        </>
                      )}
                    </tr>
                  </TableHead>
                  <tbody>
                    {tableItems.map((x, index) =>
                      index === 0 ? (
                        <></>
                      ) : (
                        <TableTR key={x.id}>
                          <td style={{textAlign:'center'}} className={styles.cellsize}>{index}</td>
                          {x.col.map((y, uindex) =>
                            uindex === 0 ? (
                              <td style={{textAlign:'center'}}>{y.name}</td>
                            ) : (
                              <td style={{textAlign:'center'}}>
                                <BootstrapTooltip
                                  title={"Open"}
                                  placement="right"
                                >
                                  <OpenButton
                                    variant={TabConfig.color}
                                    size="sm"
                                    disabled={false}
                                    onClick={() => onClickOpenURL(y)}
                                  >
                                    Open
                                  </OpenButton>
                                </BootstrapTooltip>
                              </td>
                            )
                          )}
                        </TableTR>
                      )
                    )}
                  </tbody>
                </Table>
              </div>
            </>
          )}
        </>
      )}
    </WorkflowContainer>
  );
}
