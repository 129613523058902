// @flow strict
// Copyright (C) 2022 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import React, { useState, useEffect, useRef } from "react";
import "./flowchartusermode.module.css";

import axios from "axios";
import { apiEndpoint, download } from "../../utils/common";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { TabConfig } from "../../config/config";

import WorkflowMode0 from "./workflowmode0";
import WorkflowMode1 from "./workflowmode1";
import WorkflowMode2 from "./workflowmode2";
import * as Icon from "react-bootstrap-icons";
import Select, { StylesConfig } from "react-select";
import { BouncingBalls } from "react-cssfx-loading";
import { BiSelectMultiple } from "react-icons/bi";
import {BsDatabaseFillExclamation} from "react-icons/bs"
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  useProSidebar,
  sidebarClasses,
  menuClasses,
} from "react-pro-sidebar";
import {
  ArrowButtonLeft,
  FlowChartContainer,
  LoadingContainer,
  MenuContainer,
} from "../../styled_components/flowchart";
import { NoDatabasePresent, SelectMessage } from "../../styled_components/workFlow";
import ChatBot from "./chatBot";

type FlowchartUserModeProps = {
  isAdmin: boolean,
  filterTemplate: string,
  filterSubItem: string,
  infoURL: string,
};

const color_1 = "#473a4f";
const color_2 = "#AF69EF";
const color_3 = "white";
export default function FlowchartUserMode(props: FlowchartUserModeProps) {
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [widthFlag, setWidthFlag] = useState(false);
  const [reset, setReset] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [updateTemplate, setUpdateTemplate] = useState<boolean>(false);
  var [templateSelect, setTemplateSelect] = useState<string>("");
  const [storeTemplateInfo, setStoreTemplateInfo] = useState<Array<[]>>([]);

  const [componentSelect, setComponentSelect] = useState<string>("");
  const [subComponentSelect, setSubComponentSelect] = useState<string>("");
  const [isItemEmpty, setIsItemEmpty] = useState<boolean>(false);
  const [isSubItemEmpty, setIsSubItemEmpty] = useState<boolean>(false);

  const [storeComponentNode, setStoreComponentNode] = useState<
    Array<SheetData>
  >([]);
  const [storeSubComponentNode, setStoreSubComponentNode] = useState<
    Array<SheetData>
  >([]);

  const [updateFlowchart, setUpdateFlowchart] = useState<boolean>(false);
  const [flowchartSheetSelect, setFlowchartSheetSelect] = useState<string>("");
  const [flowchartSelect, setFlowchartSelect] = useState<string>("");
  const [storeFlowchartInfo, setStoreFlowchartInfo] = useState<Array<[]>>([]);
  const [updateReady, setUpdateReady] = useState<boolean>(false);

  const [spreadsheets, setSpreadsheets] = useState<Array<Project>>([]);
  var  [manuals, setManuals] = useState<Array<Project>>([]);
  var  [flowcharts, setFlowcharts] = useState<Array<Project>>([]);
  const [references, setReferences] = useState<Array<Project>>([]);

  const [viewWait, setViewWait] = useState<boolean>(false);
  const [testPdf, setTestPdf] = useState<boolean>(false);
  const [filterPlot, setFilterPlot] = useState<Array<boolean>>([]);
  const [technicalNotes, setTechnicalNotes] = useState<Array<[]>>([]);

  const [docState, setDocState] = useState<Array<string>>([]);
  const [imageSelect, setImageSelect] = useState<string>("");
  const [imageReady, setImageReady] = useState<Array<boolean>>(false);

  const [waitIndicatorStart, setWaitIndicatorStart] = useState<boolean>(false);
  const [percentCompleted, setPercentCompleted] = React.useState<number>(0);
  var [table1Headers, setTable1Headers] = useState([]);
  var [table2Headers, setTable2Headers] = useState([]);
  var [percentValueForButton, setPercentValueForButton] = useState(0);
  var [jsonFileName, setJsonFileName] = useState("");
  var [collapsedFlag, setCollapsedFlag] = useState(false);
  var n1 = 0;
  var flagdropDown1 = false;
  var flagdropDown2 = false;
  var [flag3, setFlag3] = useState(false);
  var [flag1,setFlag1]= useState(false)
  var [flag2,setFlag2]=useState(false)
  var [flag11,setFlag11]=useState(false)
  var { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } =
    useProSidebar();

  var [dropdown1Value, setDropdownValue1] = useState("Select..");
  var [dropdown2Value, setDropdownValue2] = useState("Select..");
  var [dropdown3Value, setDropdownValue3] = useState("Select..");
  var [flagVessel,setFlagVessel]=useState(false)
  var[ tempUnavailable,setTempUnavailable]=useState(false)
  var [flagChatBot,setFlagChatBot]=useState(false)
  // var [ countDatabaseClicks,setCo]
  var countDatabaseClicks=0
  
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  var opions = (n) => {
    var array = [];
    if (n === 2) {
      let tempArray = JSON.parse(JSON.stringify(storeTemplateInfo));
      // console.log(tempArray,"storeTemplateInfo")
      for (let i = 0; i < tempArray.length; i++) {
        array.push({
          value: tempArray[i],
          label: capitalizeFirstLetter(tempArray[i]),
        });
      }
    } else if (n === 3) {
      let tempArray = JSON.parse(JSON.stringify(storeComponentNode));
      // console.log(tempArray,"storeComponentInfo")
      for (let i = 0; i < tempArray.length; i++) {
        array.push({
          value: tempArray[i].name,
          label: capitalizeFirstLetter(tempArray[i].name),
        });
      }
    } else {
      let tempArray = JSON.parse(JSON.stringify(storeSubComponentNode));
      // console.log(tempArray,"storeSubComponentInfo")
      for (let i = 0; i < tempArray.length; i++) {
        array.push({
          value: tempArray[i].name,
          label: capitalizeFirstLetter(tempArray[i].name),
        });
      }
    }
    return array;
  };

  var refDropdown1 = useRef(null);
  var refDropdown2 = useRef(null);
  var refDropdown3 = useRef(null);
  var MyComponent = (n) => {
    console.log("My Component Called for " + `${n}`);
    let options = opions(n);
    if (n === 2) {
      return (
        <Select
          styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: state.isSelected || state.isFocused ? 'rgb(82, 31, 135)' : 'grey',
                border: 0,
                backgroundColor: 'rgb(18 41 157 / 11%)',
                ":hover":{
                  borderColor:'rgb(82, 31, 135)'
                }
              }),
              menu: (base) => ({
                ...base,
                marginTop: 2,
                borderRadius:0
              })
            }}
          ref={refDropdown1}
          className="dropdown"
          onClick={() => {
            console.log("dropdownClicked");
          }}
          options={options}
          onChange={(e) => {
            setFlagDropdown(e, n);
            handleDropdown(e, n);
          }}
          defaultValue={{
            value: dropdown1Value,
            label: capitalizeFirstLetter(dropdown1Value),
          }}
        />
      );
    } else if (n === 3) {
      return (
        <Select
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: state.isSelected || state.isFocused ? 'rgb(82, 31, 135)' : 'grey',
                color:'white',
                border: 0,
                backgroundColor: 'rgb(18 41 157 / 11%)',
                ":hover":{
                  borderColor:'rgb(82, 31, 135)'
                }
              }),
              
              menu: (base) => ({
                ...base,
                marginTop: 2,
                borderRadius:0
              })
            }}
          ref={refDropdown2}
          className="dropdown"
          onClick={() => {
            console.log("dropdownClicked");
          }}
          options={options}
          onChange={(e) => {
            setFlagDropdown(e, n);
            handleDropdown(e, n);
          }}
          defaultValue={{
            value: dropdown2Value,
            label: capitalizeFirstLetter(dropdown2Value),
          }}
        />
      );
    } else {
      return (
        <Select
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: state.isSelected || state.isFocused ? 'rgb(82, 31, 135)' : 'grey',
                color:'white',
                border: 0,
                backgroundColor: 'rgb(18 41 157 / 11%)',
                ":hover":{
                  borderColor:'rgb(82, 31, 135)'
                }
              }),
              menu: (base) => ({
                ...base,
                marginTop: 2,
                borderRadius:0
              }),
              option:(baseStyles, state) => ({
                ...baseStyles,
                
              })
            }}
          ref={refDropdown3}
          className="dropdown"
          onClick={() => {
            console.log("dropdownClicked");
          }}
          options={options}
          onChange={(e) => {
            setFlagDropdown(e, n);
            handleDropdown(e, n);
          }}
          defaultValue={{
            value: dropdown3Value,
            label: capitalizeFirstLetter(dropdown3Value),
          }}
        />
      );
    }
  };

  useEffect(() => {
    async function admin() {
      setReset(true);
    }
    admin();
  }, []);


  useEffect(() => {
    async function fetchMe() {
      try {
        let select = "";
        let newTemplate = [];
        setDocState(["", "", "", "", ""]);
        if (props.infoURL.db === "") {
          const resp = await axios.get(
            apiEndpoint() + `/api/blob/workspace/list/${"admin"}`
          );
          if (resp.status === 200) {
            if (props.filterTemplate === "") {
              console.log(props.userId)
              console.log(props.userId==="sc88qa0012@xaaslabs.com")
              // setStoreTemplateInfo(props.userId==="test1Shubham"||props.userId==="test1"||props.userId==="sc88qa0012@xaaslabs.com"?[resp.data[2]]:resp.data);
              setStoreTemplateInfo([resp.data[2]])
              select = resp.data[0];
              newTemplate = resp.data;
              if (window.sessionStorage.getItem("ndeWhiz-Template") === null) {
                window.sessionStorage.setItem("ndeWhiz-Template", select);
              } else {
                select = window.sessionStorage.getItem("ndeWhiz-Template");
              }
            } else {
              const newFilter = props.filterTemplate.split(",");
              for (let i = 0; i < resp.data.length; i++) {
                for (let j = 0; j < newFilter.length; j++) {
                  if (resp.data[i] === newFilter[j]) {
                    newTemplate.push(resp.data[i]);
                    break;
                  }
                }
              }
              setStoreTemplateInfo(resp.data);
              setStoreTemplateInfo([resp.data[2]])

              select = newTemplate[0];
              if (window.sessionStorage.getItem("ndeWhiz-Template") === null) {
                window.sessionStorage.setItem("ndeWhiz-Template", select);
              } else {
                select = window.sessionStorage.getItem("ndeWhiz-Template");
              }
            }
          }
          setTemplateSelect(select);
          // setDropdownValue1(select);
          setFilterPlot([true, false, false]);
            setUpdateTemplate(true);

    
        } else {
          setTemplateSelect(props.infoURL.db);
          // setDropdownValue1(props.infoURL.db);
          setStoreTemplateInfo([props.infoURL.db]);
          setFilterPlot([true, false, false]);
          setUpdateTemplate(true);
        }

      // console.log()?
      // if(props.template!==""){
      //   setTemplateSelect(props.template)
      // }
      // if(props.componentSelect!==""){
      //   setFlowchartSheetSelect(props.componentSelect)
      //   setComponentSelect(props.componentSelect)
      // }
      // let c1=props.subComponentSelect
      // if(c1!==""){
      //   setFlowchartSelect(props.componentSelect+"-"+props.subComponentSelect)
      //   setSubComponentSelect(c1)
      //   setFlag3(true)
      //   updateReady === true &&
            
      //       storeSubComponentNode
      //       isItemEmpty === true &&
      //       isSubItemEmpty === true &&
      //       tempUnavailable===false flagVessel===true

      // }
      
      } catch (err) {
        console.log("Error");
      }
    }
    fetchMe();
  }, [props.filterTemplate, props.infoURL.db]);


  // const [allParamsAvailable, setAllParamsAvailable] = useState(false);
  // const {template,component,subcomponent}={props}
  useEffect(() => {
    console.log("use Effect induced")
    if (
      props.template !== "" &&
      props.subCompnontSelect !== "" &&
      props.componentSelect !== "" &&
      props.template !== null &&
      props.subCompnontSelect !== null &&
      props.componentSelect !== null &&
      props.template !== undefined &&
      props.subCompnontSelect !== undefined &&
      props.componentSelect !== undefined
    ) {
      console.log("here at flow direct")
      setTemplateSelect(props.template)
      setComponentSelect(props.componentSelect)
      setFlowchartSheetSelect(props.componentSelect)
      setSubComponentSelect(props.subCompnontSelect)
  
     setFlag3(true)
     setStoreComponentNode([{name:"xyz"}])//  sample array to make flag positive
     setStoreSubComponentNode([{name:"xyz"}])
     setIsItemEmpty(true)
     setIsSubItemEmpty(true)
     setTempUnavailable(false)
     setFlagVessel(true)
     setUpdateReady(false)
     setDropdownValue1(props.template)
     setDropdownValue2(props.componentSelect)
     setDropdownValue3(props.subCompnontSelect)
     setUpdateFlowchart(true)
    } 
  }, [props.template, props.componentSelect, props.subCompnontSelect]);

  async function updateSelectedDatabase(str: String) {
    console.log("update selected database called")
    if (
      props.template !== "" &&
      props.subCompnontSelect !== "" &&
      props.componentSelect !== "" &&
      props.template !== null &&
      props.subCompnontSelect !== null &&
      props.componentSelect !== null &&
      props.template !== undefined &&
      props.subCompnontSelect !== undefined &&
      props.componentSelect !== undefined
    ){
      setLoading(true)
    }
    var Manuals;
    var Flowcharts;
    setUpdateTemplate(false);
    setDocState(["", "", "", "", ""]);
    try {
      const respy = await axios.get(
        apiEndpoint() +
          `/api/blob/folder/root/read/${"admin"}/${templateSelect}/${"master.json"}`
      );
      if (respy.status === 200) {
        if (respy.data.length > 0) {
          if (respy.data[0].manual !== undefined) {
            Manuals = respy.data[0].manual;
            setManuals(respy.data[0].manual);
          }
          if (respy.data[0].spreadsheet !== undefined) {
            setSpreadsheets(respy.data[0].spreadsheet);
          }
          if (respy.data[0].flowchart !== undefined) {
            setFlowcharts(respy.data[0].flowchart);
            Flowcharts = respy.data[0].flowchart;
          }
          if (respy.data[0].reference !== undefined) {
            setReferences(respy.data[0].reference);
          }
        } else {
          console.log("Error");
        }
      }
    } catch (err) {
      setIsItemEmpty(false);
      setIsSubItemEmpty(false);
    }
    if (props.infoURL.db !== "") {
      setStoreComponentNode([{ name: props.infoURL.component }]);
      setComponentSelect(props.infoURL.component);
      // setDropdownValue2(props.infoURL.component)
      setStoreSubComponentNode([{ name: props.infoURL.flow }]);
      setSubComponentSelect(props.infoURL.flow);
      // setDropdownValue3(props.infoURL.flow)
      setFlowchartSheetSelect(props.infoURL.component);
      setFlowchartSelect(props.infoURL.component + "-" + props.infoURL.flow);
      if (!(
        props.template !== "" &&
        props.subCompnontSelect !== "" &&
        props.componentSelect !== "" &&
        props.template !== null &&
        props.subCompnontSelect !== null &&
        props.componentSelect !== null &&
        props.template !== undefined &&
        props.subCompnontSelect !== undefined &&
        props.componentSelect !== undefined
      ) )
      {
        setUpdateFlowchart(true);

      }
      setLoading(true);
      setIsItemEmpty(true);
      setIsSubItemEmpty(true);
      return;
    }
    const response = await axios.get(
      apiEndpoint() +
        `/api/blob/workspace/read/${"admin"}/${templateSelect}/${"master.json"}`
    );
    let sheet = "sheet_" + Manuals[0].id.toString();
    let currentSelect = "manual";
    // let responseForTableHeaders = await axios.get(
    //   apiEndpoint() +
    //     `/api/blob/folder/sheetfile/read/${"admin"}/${templateSelect}/${currentSelect}/${sheet}/${"table.json"}`
    // );   
    
    let responseForTableHeaders = await axios.get(
      apiEndpoint() +
        `/api/blob/folder/sheetfile/read/${"admin"}/${"Damage Mechanism"}/${currentSelect}/${sheet}/${"table.json"}`
    );
    setTable2Headers(responseForTableHeaders.data.tableItem[0].col);
    currentSelect = "flowchart";
    sheet = "sheet_" + Flowcharts[0].id;
    // responseForTableHeaders = await axios.get(
    //   apiEndpoint() +
    //     `/api/blob/folder/sheetfile/read/${"admin"}/${templateSelect}/${currentSelect}/${sheet}/${"table.json"}`
    // );
    responseForTableHeaders = await axios.get(
      apiEndpoint() +
        `/api/blob/folder/sheetfile/read/${"admin"}/${"Damage Mechanism"}/${currentSelect}/${sheet}/${"table.json"}`
    );
    setTable1Headers(responseForTableHeaders.data.tableItem[0].col);

    if (response.status === 200) {
      const masterdata = response.data[0];
      const x = masterdata.component;
      if (props.filterSubItem !== "") {
        const st = props.filterSubItem.split(",");
        for (let i = 0; i < st.length; i++) {
          for (let j = 0; j < x.length; j++) {
            if (x[j].name === st[i]) {
              x.splice(j, 1);
              break;
            }
          }
        }
      }

      const component = x;
      setStoreComponentNode([]);
      if (component.length > 0) {
        setStoreComponentNode(component);
        setComponentSelect(component[0].name);
        // setDropdownValue2(component[0].name)
        if (component[0].subcomponent.length > 0) {
          setIsItemEmpty(true);
          setStoreSubComponentNode(component[0].subcomponent);
          setSubComponentSelect(component[0].subcomponent[0].name);
          // setDropdownValue3(component[0].subcomponent[0].name)
          if (component[0].subcomponent[0].item.length > 0) {
            if (component[0].subcomponent[0].item[0].subitem.length > 0) {
              setIsSubItemEmpty(true);
              setFlowchartSheetSelect(component[0].name);
              setFlowchartSelect(
                component[0].name + "-" + component[0].subcomponent[0].name
              );
              console.log("here")

              if (!(
                props.template !== "" &&
                props.subCompnontSelect !== "" &&
                props.componentSelect !== "" &&
                props.template !== null &&
                props.subCompnontSelect !== null &&
                props.componentSelect !== null &&
                props.template !== undefined &&
                props.subCompnontSelect !== undefined &&
                props.componentSelect !== undefined
              ) )
              {
              setUpdateFlowchart(true);
              }
            } else {
              setIsSubItemEmpty(false);
            }
          } else {
            setIsItemEmpty(false);
          }
        } else {
          setComponentSelect("");
          setDropdownValue2("Select..");
          setSubComponentSelect("");
          setDropdownValue3("Select..");
        }
      } else {
        setStoreSubComponentNode([]);
        setSubComponentSelect("");
        setDropdownValue3("Select..");
      }
    }
    setLoading(true);
  }
  function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  }


  //   function transformBpmnJson(bpmnJson) {
  
  
  
  //     const bpmnDefinitions = bpmnJson['bpmn2:definitions'];
  
  // if (bpmnDefinitions) {
  //   // Access BPMN process if it exists
  //   const bpmnProcess = bpmnDefinitions['bpmn2:process'];
    
  //   if (bpmnProcess) {
  //     // Initialize an array to store the transformed data
  //     const transformedData = [];
      
  //     // Iterate through various BPMN elements and extract information
  //     // For example, sequenceFlows, tasks, gateways, etc.
  //     // You can access these elements based on your BPMN JSON structure
      
  //     // Example: Extract sequence flows
  //     if (bpmnProcess['bpmn2:sequenceFlow']) {
  //       bpmnProcess['bpmn2:sequenceFlow'].forEach((sequenceFlow) => {
  //         const id = sequenceFlow._attributes.id;
  //         const type = 'link'; // You can determine the type based on your BPMN structure
  //         const from = sequenceFlow._attributes.sourceRef;
  //         const to = sequenceFlow._attributes.targetRef;
  //         const content = sequenceFlow._attributes.name || ''; // Extract name if available
          
  //         transformedData.push({ id, type, from, to, content });
  //       });
  //     }
      
  //     // Example: Extract tasks
  //     if (bpmnProcess['bpmn2:task']) {
  //       bpmnProcess['bpmn2:task'].forEach((task) => {
  //         const id = task._attributes.id;
  //         const type = 'task'; // You can determine the type based on your BPMN structure
  //         const content = task._attributes.name || ''; // Extract name if available
          
  //         transformedData.push({ id, type, content });
  //       });
  //     }
      
  //     // Add more logic to extract other BPMN elements as needed
      
  //     // Now transformedData contains the information in the desired format
  //     console.log(transformedData);
  //   } else {
  //     console.error("BPMN process not found in the BPMN JSON.");
  //   }
  // } else {
  //   console.error("BPMN definitions not found in the BPMN JSON.");
  // }
  //   }
  function xmlToJson(xmlData) {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlData, 'text/xml');
    const json = xmlToJsonRecursive(xmlDoc);
    return json;
  }
  
  function xmlToJsonRecursive(xml) {
    const obj = {};
  
    if (xml.nodeType === 1) {
      if (xml.attributes.length > 0) {
        obj['attributes'] = {};
        for (let j = 0; j < xml.attributes.length; j++) {
          const attribute = xml.attributes.item(j);
          obj['attributes'][attribute.nodeName] = attribute.nodeValue;
        }
      }
    } else if (xml.nodeType === 3) {
      obj['value'] = xml.nodeValue;
    }
  
    if (xml.hasChildNodes()) {
      for (let i = 0; i < xml.childNodes.length; i++) {
        const item = xml.childNodes.item(i);
        const nodeName = item.nodeName;
  
        if (typeof obj[nodeName] === 'undefined') {
          obj[nodeName] = xmlToJsonRecursive(item);
        } else {
          if (typeof obj[nodeName].push === 'undefined') {
            const old = obj[nodeName];
            obj[nodeName] = [];
            obj[nodeName].push(old);
          }
          obj[nodeName].push(xmlToJsonRecursive(item));
        }
      }
    }
  
    return obj;
  }

    
    
  function f(array, rowNumber, flag1, value1, value2, value3) {
    rowNumber = parseInt(rowNumber);
    console.log(array, rowNumber);
    let node = {
      cond: "false",
      edit: false,
      icon: "",
      id: "66b5630-e173-a8d1-2ad0-160583d34cc",
      info: "",
      itemMode: "External",
      link: "",
      name: "Choose Brand",
      style: [],

      text: "",
      type: "",
      visible: false,
    };
    node.id = uuidv4();
    if (flag1 === 1) {
      console.log("forItemRowNummber", rowNumber);
      node.subItem = [];
      node.name = array[rowNumber - 1].N11;
      let noOfOptions = parseInt(array[rowNumber - 1].C1);
      let noOfOptionsLND = 0;

      for (let i = 0; i < noOfOptions; i++) {
        if (array[rowNumber - 1][`S${i + 1 + noOfOptions}1`] === "LND") {
          noOfOptionsLND += 1;
        }
        console.log("rowNumberForSubItem", rowNumber);
        console.log("subItemName", array[rowNumber - 1][`S${i + 1}1`]);
        console.log(
          "subItemOption",
          array[rowNumber - 1][`S${i + 1 + noOfOptions}1`]
        );
        console.log(
          "LND end Value String",
          array[rowNumber - 1][`S${2 * noOfOptions - 1 + noOfOptionsLND}1`]
        );
        node.subItem.push(
          f(
            array,
            "",
            0,
            array[rowNumber - 1][`S${i + 1}1`],
            array[rowNumber - 1][`S${i + 1 + noOfOptions}1`],
            array[rowNumber - 1][`S${2 * noOfOptions + noOfOptionsLND}1`]
          )
        );
      }
    } else if (flag1 == 0) {
      console.log(
        "forSubItem",
        "subItemName=",
        value1,
        "subItemOption",
        value2
      );
      node.item = [];
      node.name = value1;
      if (value2 === "LND") {
        console.log("subItem Where path Ends", value3);
        if (value3[0] === `"`) {
          value3 = value3.slice(1, value3.length - 1);
        }
        console.log(value3);
        let locationValue = "";
        let noOfMethods = "";
        let methodsArrray = "";
        let countTemp = 0;
        for (let j = 0; j < value3.length; j++) {
          if (value3[j] === "[") {
            countTemp += 1;
          }
          if (countTemp === 1) {
            if (value3[j] != "]" && value3[j] != "[") {
              locationValue += value3[j];
            }
          } else if (countTemp === 2) {
            if (value3[j] != "]" && value3[j] != "[") {
              noOfMethods += value3[j];
            }
          } else if (countTemp === 3) {
            if (value3[j] != "]" && value3[j] != "[") {
              methodsArrray += value3[j];
            }
          }
        }
        noOfMethods = parseInt(noOfMethods);
        methodsArrray = methodsArrray.split(",");
        console.log(locationValue, "locationValue");
        node.item.push(f(array, "", 2, locationValue, "", "")); // for location
        console.log("noOfMethods", noOfMethods);
        console.log("methodsArray", methodsArrray);
        for (let j = 0; j < noOfMethods; j++) {
          node.item.push(f(array, "", 3, methodsArrray[j], "", "")); // for methods
        }
        //desing design object for Methods
      } else if (value2 === "NI") {
        //design object for No workflow
      } else {
        node.item = [f(array, value2, 1, "", "")];
      }
    } else if (flag1 === 2) {
      node.type = "LOC";
      node.name = "Location";
      node.subItem = [f(array, "", 4, value1, "", "")];
      //
    } else if (flag1 === 3) {
      value1 = value1.split("@");
      node.name = value1[0];
      node.type = "NDE";
      node.subItem = [f(array, "", 5, value1[1], "", "")];
    } else if (flag1 === 4) {
      node.name = "Location" + "[" + value1 + "]";
      node.type = "LOC";
      node.item = [];
    } else if (flag1 === 5) {
      node.name = value1;
      node.type = "NDE";
      node.item = [];
    }
    //currently it's done for only two options .
    // We would be needing iterating for more than two optio

    // node.subItem[array[rowNumber]]
    return node;
  }

  function getTreeFromJson(array) {
    console.log(array);
    let treeResult = {};
    let detailsRow = array[0];
    let detailsObject = {};
    
    detailsObject.workflowName = detailsRow.D11;
    detailsObject.component1 = detailsRow.N11;
    detailsObject.component2 = detailsRow.S11 + detailsRow.S21;
    setJsonFileName(
      detailsRow.N11 + detailsRow.S11 + detailsRow.S21 + detailsRow.S31
    );
    let array1 = array.slice(2, 25);
    array1[0].S41 = "3";
    let rowNumber = 1;
    detailsObject.subItem = f(array1, rowNumber, 1, "", "");
    treeResult = detailsObject;
    return treeResult;
  }
  function splitStringIntoArray(string) {
    console.log(string);
    let array = [];
    let flag1 = false;
    let temp = "";

    for (let i = 0; i < string.length - 1; i++) {
      if (string[i] === ",") {
        if (flag1 === false) {
          if (temp != "") {
            array.push(temp);
          }
          temp = "";
        } else {
          temp += string[i];
        }
      } else {
        temp += string[i];
      }

      if (string[i] === `"`) {
        if (flag1 === false) {
          flag1 = true;
        } else {
          flag1 = false;
        }
      }
    }

    if (temp != "") {
      array.push(temp);
    }
    return array;
  }

  function convertOnClick() {
    const file1 = document.getElementsByClassName("inputfile")[0].files[0];
    console.log("file1Input", file1);
    const reader = new FileReader();
    reader.onload = () => {
      let resultOfConversion = convertCSVtoJSON(reader.result);
      console.log(resultOfConversion);
      let treeResult = getTreeFromJson(resultOfConversion.result2);
      setStoreFlowchartInfo([treeResult.subItem]);

      return treeResult;
    };
    reader.readAsText(file1);
    function convertCSVtoJSON(file1) {
      console.log(file1);
      let csvArray = file1.split("\n");
      console.log(csvArray, "csvArray");
      let csvHeaders = csvArray[0].split(",");
      csvHeaders.pop();
      csvHeaders.pop();
      csvHeaders.pop();
      csvHeaders.push("S41");
      csvHeaders.push("S51");
      csvHeaders.push("S61");
      console.log(csvHeaders, "csvHeaders");
      let resultArrayOfArrays = [];
      let resultArrayOfObjects = [];
      for (let i = 1; i < csvArray.length; i++) {
        let tempArray = splitStringIntoArray(csvArray[i]);
        console.log(tempArray);
        let tempObject = {};
        resultArrayOfArrays.push(tempArray);
        for (let j = 0; j < tempArray.length; j++) {
          tempObject[csvHeaders[j]] = tempArray[j];
        }
        resultArrayOfObjects.push(tempObject);
      }
      return {
        result1: resultArrayOfArrays,
        result2: resultArrayOfObjects,
      };
    }
  }


  async function updateSelectedFlowchart(str: String) {
    console.log("update fflowchart ",props.userId)
    if(props.userId==="nwuser003_vessel"){
      let flagVessel1=true
      if(subComponentSelect.split("-")[0]==="Vessel"){
        console.log("sadlfjas;dl")
      }
      else{
        flagVessel1=false
        setFlagVessel(flagVessel1)
        setTempUnavailable(true)
      }
    }
    setDocState(["", "", "", "", ""]);
    setUpdateFlowchart(false);
    try {
      console.log("here at flowchart")
      console.log(templateSelect)
      console.log(flowchartSheetSelect)
      console.log(flowchartSheetSelect)
      console.log(subComponentSelect)
      let flowchartSelect1=flowchartSheetSelect+"-"+subComponentSelect
      console.log(flowchartSelect1)
      
      const respy = await axios.get(
        apiEndpoint() +
          `/api/blob/workspace/read/${"admin"}/${templateSelect}/${flowchartSheetSelect}/${
            flowchartSelect1 + ".json"
          }`
      );
    
      console.log("responseFromFile");
     if(window.confirm("Enter Bot Mode?")===!false){
      setFlagChatBot(true)
     }
     else{
      setFlagChatBot(false)
     }
      if (respy.status === 200) {
        if (respy.data.length > 0) {
          setStoreFlowchartInfo(respy.data);
        }
      }
      setUpdateReady(true);
    } catch (err) {
      if (!(
        props.template !== "" &&
        props.subCompnontSelect !== "" &&
        props.componentSelect !== "" &&
        props.template !== null &&
        props.subCompnontSelect !== null &&
        props.componentSelect !== null &&
        props.template !== undefined &&
        props.subCompnontSelect !== undefined &&
        props.componentSelect !== undefined
      ) )
      {
        
        console.log("here at error for flowchart , now database not available would be shown")
      }
      else{
        window.alert("Something went wrong! Please check use-case particulars and revisit")
      }
      setIsItemEmpty(false);
        setIsSubItemEmpty(false);
    
    }
    /*
    let currentSelect = "";
    let id = "";
    let rowno= "";
    let colno= "";
    let link = "";
    let filename = "";
    let ready = false;
    let t = [];
    t = flowchartSelect.split("-");
    for (let i=0; i < flowcharts.length; i++) {
       if (t[0] === flowcharts[i].name) {
          id = flowcharts[i].id;
           break;
        }
      }
      if (id !== "") {
      const sheet = "sheet_" + id.toString(); 
      const respy = await axios.get(apiEndpoint() + `/api/blob/folder/sheetfile/read/${"admin"}/${templateSelect}/${"flowchart"}/${sheet}/${"table.json"}`);
      if (respy.status === 200) {
         for (let j=0; j < respy.data.tableItem.length; j++) {
  //          if (t[1] === respy.data.tableItem[j].col[0].name && t[2] === respy.data.tableItem[j].col[1].name && t[3] === respy.data.tableItem[j].col[2].name) {
               rowno= respy.data.tableItem[1].id;
               colno= respy.data.tableItem[1].col[7].id;
               link= respy.data.tableItem[1].col[7].uploadLink;
               ready = true;
               break;
    //         }
          }
      }
    }
    if (ready === true && id !== "" && rowno !== "" && colno !== "" && link !== "") {
      const sheet = "sheet_" + id;
      const rowx = "row_" + rowno;
      const colx = "col_" + colno;
      await axios.get(apiEndpoint()+ `/api/blob/folder/sheet/read/${"admin"}/${templateSelect}/${"flowchart"}/${sheet}/${rowx}/${colx}/${link}`, { responseType: "blob" })
      .then((xresponse) => {
         const reader = new FileReader();
         reader.readAsDataURL(xresponse.data); 
         reader.onload = function () {
         const imageDataUrl = reader.result;
         setImageSelect(imageDataUrl);
         setImageReady(true);
      };
        });
    }
*/
  }
  function setFlagDropdown(val, n) {
    if (n === 2) {
      flagdropDown1 = true;
      flagdropDown2 = false;

      refDropdown2.current.clearValue(); // clearing value of the second and third dropdown
      refDropdown3.current.clearValue();
      setComponentSelect("");
      setDropdownValue2("Select..");
      setSubComponentSelect("");
      setDropdownValue3("Select..");
      setFlag3(false);
      setFlag2(false)
      setTempUnavailable(false)
      if(val!==undefined && val!==null){
        setFlag1(true)
      }
    } else if (n === 3) {
      flagdropDown1 = false;
      flagdropDown2 = true;
      refDropdown3.current.clearValue(); // clearing value of the third dropdown
      setSubComponentSelect("");
      setDropdownValue3("Select..");
      setFlag3(false);
      setFlag1(false)
      setTempUnavailable(false)

      if(val!==null && val!==undefined && refDropdown1.current.getValue().length>0){
        setFlag2(true)
      }
    } else {
      console.log(refDropdown3.current.getValue(), "onClickThird------", val);
      if (
        val !== null &&
        val !== undefined &&
        refDropdown2.current.getValue().length !== 0 
        && refDropdown1.current.getValue().length!==0
      ) {
        setFlag3(true);
      }
      setFlag1(false)
      setFlag2(false)
      flagdropDown1 = false;
      flagdropDown2 = false;
    }
  }

  function handleDropdown(val, n1) {
    if (val === undefined || val === null) {
      return 1;
    }
    console.log("n1", n1);
    console.log(val.value, "n1", n1);
    const trimValue = val.value.trim();
    
    console.log("trimValue", trimValue);
    const store = storeComponentNode;
    let found = false;
    let index = 0;
    let foundA = false;
    let indexA = 0;
    let foundB = false;
    let indexB = 0;
    let foundC = false;

    switch (n1) {
      case 2:
        countDatabaseClicks=countDatabaseClicks+1
        setImageSelect("");
        setImageReady(false);

        if (window.sessionStorage.getItem("ndeWhiz-Template") === null) {
          window.sessionStorage.setItem("ndeWhiz-Template", val.value);
        } else {
          window.sessionStorage.setItem("ndeWhiz-Template", val.value);
        }

        setTemplateSelect(val.value);
        setDropdownValue1(val.value);
        setStoreFlowchartInfo([]);
        // setStoreComponentNode([]); 
        async function testAPi() {
        var template
        template=val.value
        console.log(template)
        setFlag11(true)
        const response = await axios.get(
          apiEndpoint() +
            `/api/blob/workspace/read/${"admin"}/${template}/${"master.json"}`
        );
        countDatabaseClicks=countDatabaseClicks-1
        
        if(countDatabaseClicks===0){
          setFlag11(false)
        }
       
      // console.log(response.data,"apiCallforTemplate")
      
      setStoreComponentNode(response.data[0].component)
      console.log(response.data[0].component)
      
      return(0)
      }
        // console.log(testAPi().data,"apiCallforATemplate")
      testAPi()
   
        setStoreSubComponentNode([]);
        setSubComponentSelect("");
        setDropdownValue3("Select..");
        setUpdateReady(false);
        setUpdateTemplate(true);
        setLoading(true)

        break;

      case 3:
        setFlag11(true)
        setImageSelect("");
        setImageReady(false);

        setUpdateReady(false);
        setStoreFlowchartInfo([]);
        setComponentSelect(trimValue);
        setDropdownValue2(trimValue);
        for (let i = 0; i < store.length; i++) {
          console.log(store[i].name);
          console.log(trimValue);
          console.log(store[i].name === trimValue, store[i].name === trimValue);
          if (store[i].name === trimValue) {
            found = true;
            index = i;
            foundA = true;
            foundB = true;
            indexA = 0;
            indexB = 0;
            break;
          }
        }
setFlag11(false)
        break;

      case 4:
        setImageSelect("");
        setImageReady(false);
        setUpdateReady(false);
        setStoreFlowchartInfo([]);
        setSubComponentSelect(trimValue);
        setDropdownValue3(trimValue);
        console.log("third Dropdown select",trimValue)
        if(trimValue.split("-")[0]==="Piping"){
          setFlagVessel(true)
        }
        else{
          setFlagVessel(false)
        }
        console.log("props.userId",props.userId)
        if(props.userId==="Shyam"){
          
          setTempUnavailable(true)
        }
        else{
          setTempUnavailable(false)
        }
        for (let i = 0; i < store.length; i++) {
          if (store[i].name === componentSelect) {
            found = true;
            index = i;
            break;
          }
        }
        if (found === true) {
          if (store[index].subcomponent.length > 0) {
            foundA = false;
            for (let i = 0; i < store[index].subcomponent.length; i++) {
              if (store[index].subcomponent[i].name === trimValue) {
                foundA = true;
                indexA = i;
                foundB = true;
                indexB = 0;
                break;
              }
            }
          }
        }

        break;

      default:
        foundC = true;
        break;
    }

    if (foundC === false) {
      if (found === true) {
        if (store[index].subcomponent.length > 0 && foundA === true) {
          setStoreSubComponentNode(store[index].subcomponent);
          setSubComponentSelect(store[index].subcomponent[indexA].name);
          // setDropdownValue3(store[index].subcomponent[indexA].name);
          if (
            store[index].subcomponent[indexA].item.length > 0 &&
            foundB === true
          ) {
            setIsItemEmpty(true);
            if (
              store[index].subcomponent[indexA].item[indexB].subitem.length > 0
            ) {
              setIsSubItemEmpty(true);
              setFlowchartSheetSelect(store[index].name);
              
              setFlowchartSelect(
                store[index].name + "-" + store[index].subcomponent[indexA].name
              );
              setUpdateFlowchart(true); //updateSelectedFlowchart
            } else {
              setIsSubItemEmpty(false);
            }
          } else {
            setIsItemEmpty(false);
          }
        } else {
          setStoreSubComponentNode([]);
          setSubComponentSelect("");
          setDropdownValue3("Select..");
        }
      } else {
        setStoreSubComponentNode([]);
        setSubComponentSelect("");
        setDropdownValue3("Select..");
      }
    }
    console.log(storeSubComponentNode,isItemEmpty,isSubItemEmpty,flag3,loading)
    setRefresh(!refresh);
  }
  function handleDropdown1(
    id: number,
    e: SyntheticKeyboardEvent<HTMLInputElement>
  ) {
    const trimValue = e.currentTarget.value.trim();
    const store = storeComponentNode;
    let found = false;
    let index = 0;
    let foundA = false;
    let indexA = 0;
    let foundB = false;
    let indexB = 0;
    let foundC = false;

    switch (id) {
      case 2:
        setImageSelect("");
        setImageReady(false);

        if (window.sessionStorage.getItem("ndeWhiz-Template") === null) {
          window.sessionStorage.setItem(
            "ndeWhiz-Template",
            e.currentTarget.value
          );
        } else {
          window.sessionStorage.setItem(
            "ndeWhiz-Template",
            e.currentTarget.value
          );
        }
        
        setTemplateSelect(e.currentTarget.value);
        setDropdownValue1(e.currentTarget.value);
        setStoreFlowchartInfo([]);
        setStoreSubComponentNode([]);
        setSubComponentSelect("");
        setUpdateReady(false);
        setUpdateTemplate(true);
        break;

      case 3:
        setImageSelect("");
        setImageReady(false);

        setUpdateReady(false);
        setStoreFlowchartInfo([]);
        setComponentSelect(trimValue);
        setDropdownValue2(trimValue);
        for (let i = 0; i < store.length; i++) {
          if (store[i].name === trimValue) {
            found = true;
            index = i;
            foundA = true;
            foundB = true;
            indexA = 0;
            indexB = 0;
            break;
          }
        }
        break;

      case 4:
        setImageSelect("");
        setImageReady(false);
        setUpdateReady(false);
        setStoreFlowchartInfo([]);
        setSubComponentSelect(trimValue);
        setDropdownValue3(trimValue);
        for (let i = 0; i < store.length; i++) {
          if (store[i].name === componentSelect) {
            found = true;
            index = i;
            break;
          }
        }
        if (found === true) {
          if (store[index].subcomponent.length > 0) {
            foundA = false;
            for (let i = 0; i < store[index].subcomponent.length; i++) {
              if (store[index].subcomponent[i].name === trimValue) {
                foundA = true;
                indexA = i;
                foundB = true;
                indexB = 0;
                break;
              }
            }
          }
        }
        break;

      default:
        foundC = true;
        break;
    }
    if (foundC === false) {
      if (found === true) {
        if (store[index].subcomponent.length > 0 && foundA === true) {
          setStoreSubComponentNode(store[index].subcomponent);
          setSubComponentSelect(store[index].subcomponent[indexA].name);
          if (
            store[index].subcomponent[indexA].item.length > 0 &&
            foundB === true
          ) {
            setIsItemEmpty(true);
            if (
              store[index].subcomponent[indexA].item[indexB].subitem.length > 0
            ) {
              setIsSubItemEmpty(true);
              setFlowchartSheetSelect(store[index].name);
              setFlowchartSelect(
                store[index].name + "-" + store[index].subcomponent[indexA].name
              );
              setUpdateFlowchart(true);
            } else {
              setIsSubItemEmpty(false);
            }
          } else {
            setIsItemEmpty(false);
          }
        } else {
          setStoreSubComponentNode([]);
          setSubComponentSelect("");
        }
      } else {
        setStoreSubComponentNode([]);
        setSubComponentSelect("");
      }
    }
    setRefresh(!refresh);
  }

  async function handleEditor(
    str: string,
    val: number,
    e: SyntheticKeyboardEvent<HTMLInputElement>
  ) {
    switch (str) {
      case "CHECKBOX":
        if (val === 10) {
          setUpdateTemplate(true);
        }
        if (val === 11) {
          setFilterPlot([true, false, false]);
        }
        if (val === 12) {
          setFilterPlot([false, true, false]);
        }
        if (val === 13) {
          setFilterPlot([false, false, true]);
        }

        break;

      default:
        break;
    }
  }
  function onClickGetManualsReferenceExcel(){
    async function apiCallManualsData(){
    for(let i=0;i<manuals.length;i++){
      let currentSelect = "manual";
       let id = manuals[i].id;
        if (id !== "") {
          const sheet = "sheet_" + id.toString();
          console.log("calling for",sheet,templateSelect,currentSelect)
          const respy = await axios.get(
            apiEndpoint() +
              `/api/blob/folder/sheetfile/read/${"admin"}/${templateSelect}/${currentSelect}/${sheet}/${"table.json"}`
          );
          console.log("respy Data",respy.data)
          let array=[]
          let tableItem=respy.data.tableItem
          for(let j=0;j<tableItem.length;j++){
            let array1=[]
            console.log("tableItem[j].col",tableItem[j].col)
            for (let k=0;k<tableItem[j].col.length;k++){
              let name1=j===0 || k<2?tableItem[j].col[k].name:tableItem[j].col[k].uploadName
              if(name1===""){
                name1="#NOT UPLOADED"
              }
              else{
                if(name1.includes("(NA)")){
                name1="#NOT AVAILABLE"}
              }
              array1.push(name1)
            }
            array.push(array1)
          }
          function arrayToCSV(data) {
            const rows = data.map(row => row.join(','));
            return rows.join('\n');
        }
        
        // Function to trigger the download
        function downloadCSV(filename) {
            const csvData = arrayToCSV(array);
            const blob = new Blob([csvData], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
        
            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
        
            // Clean up
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        }
        
        // Automatically trigger the download with the filename "xyz.csv"
        downloadCSV(tableItem[1].col[1].name);
    }
  
  }

  }
  apiCallManualsData()
}
function getSpreadsheetJSON(){





  let apiCall = () =>{


  async function apiCall1(){







    let currentSelect="spreadsheet"
    let JSONObject={}
    for (let i=0;i<spreadsheets.length;i++){
      let objectSpreadsheetDetails={}
      let sheet="sheet_"+spreadsheets[i].id.toString()
      let respy = await axios.get(
        apiEndpoint() +
          `/api/blob/folder/sheetfile/read/${"admin"}/${templateSelect}/${currentSelect}/${sheet}/${"table.json"}`
      );
      let tableItem=respy.data.tableItem

      console.log("respy.data",respy)
 
      for(let j=1;j<tableItem.length;j++){
        let subNam=tableItem[j].col[0]+tableItem[j].col[1]+tableItem[j].col[2]

        if(objectSpreadsheetDetails[subNam]===undefined){
          objectSpreadsheetDetails[subNam]={}
        }

      const rowno=tableItem[j].id
const colno=tableItem[j].col[5].id
let link=tableItem[j].col[5].uploadLink
          let rowx = "row_" + rowno;
          let colx = "col_" + colno;
          
          try {
            const response = await axios.get(
              apiEndpoint() +
                `/api/blob/folder/sheet/read/${"admin"}/${templateSelect}/${currentSelect}/${sheet}/${rowx}/${colx}/${link}`,
              {
                responseType: 'blob',
              }
            );
            // console.log("response for ",spreadsheets[i])
            if (response.status === 200) {
              // Extract the filename from the response headers (if available)
              const contentDisposition = response.headers['content-disposition'];
              const fileName = contentDisposition
                ? contentDisposition.split(';')[1].trim().split('=')[1].replace(/"/g, '')
                : 'download.xlsx';
        
              // Create a URL for the blob data
              const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

              // Create a temporary URL for the blob data
              const blobUrl = window.URL.createObjectURL(blob);
        
              // Create a temporary anchor element and trigger a click event to download the file
              const a = document.createElement('a');
              a.href = blobUrl;
              a.download = fileName;
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
        
              // Revoke the blob URL to free up resources
              window.URL.revokeObjectURL(blobUrl);
            } else {
              console.error('Error downloading Excel file');
            }
          } catch (error) {
            console.error('An error occurred while downloading the Excel file', error);
          }
          }

    }
  }
  apiCall1()
  }
  apiCall()
}
function showWorkFlow(){
  return(
  flagChatBot===true?<ChatBot flagMode={"normal2"} template={templateSelect} compName={componentSelect} subCompName={subComponentSelect}></ChatBot>:
  <WorkflowMode0
userId={props.userId}
                workflow={templateSelect}
                component={componentSelect}
                subcomponent={subComponentSelect}
                documentProcess={documentProcess}
                data={storeFlowchartInfo}
                training={manuals}
                reference={references}
                docstate={docState}
                tableHeaders={[table1Headers, table2Headers]}
                percentValueForButton={percentValueForButton}
              />)
}
async function getMethodsRef(){
  
  let MethodsObject={}
  for (let i = 0; i < manuals.length; i++) {
    let methodsName = manuals[i].name
    MethodsObject[methodsName] = {}


    

      let sheet = "sheet_" + manuals[i].id.toString()
      let c1 = "manual"
      const resp = await axios.get(
        apiEndpoint() +
        `/api/blob/folder/sheetfile/read/${"admin"}/${templateSelect}/${c1}/${sheet}/${"table.json"}`
      );
      let tableItem = resp.data.tableItem
      tableItem.map((y) => {
        MethodsObject[methodsName][y.col[0].name] = true
      })


  }
  const jsonString=JSON.stringify(MethodsObject)
  const blob = new Blob([jsonString], { type: "application/json" });

// Create a URL for the Blob
const url = URL.createObjectURL(blob);

// Create an <a> element for downloading
const a = document.createElement("a");
a.href = url;
a.download = "data.json"; // Specify the file name

// Simulate a click event on the <a> element to trigger the download
a.click();

// Revoke the Blob's URL
URL.revokeObjectURL(url);
}
  async function documentProcess(x, y, z, s,flag) {
    templateSelect="Damage Mechanism"
    console.log("template select changed",templateSelect)
    console.log("document Procces s",x,y,z)
    let  respy = await axios.get(
      apiEndpoint() +
        `/api/blob/folder/root/read/${"admin"}/${templateSelect}/${"master.json"}`
    );

      if (respy.data.length > 0) {
        if (respy.data[0].manual !== undefined) {
         manuals=respy.data[0].manual
          setManuals(respy.data[0].manual);
        }

        if (respy.data[0].flowchart !== undefined) {
          setFlowcharts(respy.data[0].flowchart);
          
      
          flowcharts=respy.data[0].flowchart
        }

      } else {
        console.log("Error");
      }
    let currentSelect = "";
    let id = "";
    let rowno = "";
    let colno = "";
    let link = "";
    let filename = "";
    let ready = false;
    let t = [];
    setDocState([x, y, z, "Loading Wait ...", "100%"]);
    switch (x) {
      case "FLOW":
        t = z.split("-");
        currentSelect = "flowchart";
        for (let i = 0; i < flowcharts.length; i++) {
          if (y === flowcharts[i].name) {
            id = flowcharts[i].id;
            break;
          }
        }
        if (id !== "") {
          const sheet = "sheet_" + id.toString();
          const respy = await axios.get(
            apiEndpoint() +
              `/api/blob/folder/sheetfile/read/${"admin"}/${templateSelect}/${currentSelect}/${sheet}/${"table.json"}`
          );
          if (respy.status === 200) {
            for (let j = 0; j < respy.data.tableItem.length; j++) {
              if (
                t[0] === respy.data.tableItem[j].col[0].name &&
                t[1] === respy.data.tableItem[j].col[1].name &&
                t[2] === respy.data.tableItem[j].col[2].name
              ) {
                rowno = respy.data.tableItem[j].id;
                colno = respy.data.tableItem[j].col[4].id;
                link = respy.data.tableItem[j].col[4].uploadLink;
                ready = true;
                break;
              }
              console.log("NDEx");
            }
          }
        }
        if (
          ready === true &&
          id !== "" &&
          rowno !== "" &&
          colno !== "" &&
          link !== ""
        ) {
          const sheet = "sheet_" + id;
          const rowx = "row_" + rowno;
          const colx = "col_" + colno;
          await axios
            .get(
              apiEndpoint() +
                `/api/blob/folder/sheet/read/${"admin"}/${templateSelect}/${currentSelect}/${sheet}/${rowx}/${colx}/${link}`,
              {
                responseType: "blob",
                onDownloadProgress: (progressEvent) => {
                  console.log(progressEvent);
                  let percentageCompleted;
                  percentageCompleted = Math.floor(
                    (progressEvent.loaded / progressEvent.total) * 100
                  );
                  setPercentValueForButton(percentageCompleted);
                },
              }
            )
            .then((response) => {
              const reader = new FileReader();
              reader.readAsDataURL(response.data);
              reader.onload = function () {
                const imageDataUrl = reader.result;
                setTestPdf(imageDataUrl);
              };
              setViewWait(true);
            });
        }
        break;

      case "NDE-GE":
        t = z.split("-");
        currentSelect = "flowchart";
        for (let i = 0; i < flowcharts.length; i++) {
          if (y === flowcharts[i].name) {
            id = flowcharts[i].id;
            break;
          }
        }
        if (id !== "") {
          const sheet = "sheet_" + id.toString();
          const respy = await axios.get(
            apiEndpoint() +
              `/api/blob/folder/sheetfile/read/${"admin"}/${templateSelect}/${currentSelect}/${sheet}/${"table.json"}`
          );
          if (respy.status === 200) {
            for (let j = 0; j < respy.data.tableItem.length; j++) {
              rowno = respy.data.tableItem[1].id;
              colno = respy.data.tableItem[1].col[5].id;
              link = respy.data.tableItem[1].col[5].uploadLink;
              ready = true;
              break;
            }
          }
        }
        if (
          ready === true &&
          id !== "" &&
          rowno !== "" &&
          colno !== "" &&
          link !== ""
        ) {
          const sheet = "sheet_" + id;
          const rowx = "row_" + rowno;
          const colx = "col_" + colno;
          await axios
            .get(
              apiEndpoint() +
                `/api/blob/folder/sheet/read/${"admin"}/${templateSelect}/${currentSelect}/${sheet}/${rowx}/${colx}/${link}`,
              {
                responseType: "blob",
                onDownloadProgress: (progressEvent) => {
                  console.log(progressEvent);
                  let percentageCompleted;
                  percentageCompleted = Math.floor(
                    (progressEvent.loaded / progressEvent.total) * 100
                  );
                  setPercentValueForButton(percentageCompleted);
                },
              }
            )
            .then((response) => {
              const reader = new FileReader();
              reader.readAsDataURL(response.data);
              reader.onload = function () {
                const imageDataUrl = reader.result;
                setTestPdf(imageDataUrl);
              };
              setViewWait(true);
            });
        }
        break;

      case "NDE":
        currentSelect = "manual";
        for (let i = 0; i < manuals.length; i++) {
          if (y === manuals[i].name) {
            id = manuals[i].id;
            break;
          }
        }
        if (id !== "") {
          const sheet = "sheet_" + id.toString();
          const respy = await axios.get(
            apiEndpoint() +
              `/api/blob/folder/sheetfile/read/${"admin"}/${templateSelect}/${currentSelect}/${sheet}/${"table.json"}`
          );
          if (respy.status === 200) {
            console.log(respy.data)
            for (let j = 0; j < respy.data.tableItem.length; j++) {
              if (z === respy.data.tableItem[j].col[0].name) {
                rowno = respy.data.tableItem[j].id;
                colno = respy.data.tableItem[j].col[2].id;
                link = respy.data.tableItem[j].col[2].uploadLink;
                ready = true;
                console.log("here at row",respy.data.tableItem[j])

                let fileName=respy.data.tableItem[j].col[2].uploadName
                if(flag===1){
                  if(fileName.includes("NA") || link===""){
                    return(true)
                  }
                  else{
                    return(false)
                  }
                }
                break;
              }
              console.log("NDEx");
            }
          }
        }
        if (
          ready === true &&
          id !== "" &&
          rowno !== "" &&
          colno !== "" &&
          link !== ""
        ) {
          const sheet = "sheet_" + id;
          const rowx = "row_" + rowno;
          const colx = "col_" + colno;
          await axios
            .get(
              apiEndpoint() +
                `/api/blob/folder/sheet/read/${"admin"}/${templateSelect}/${currentSelect}/${sheet}/${rowx}/${colx}/${link}`,
              {
                responseType: "blob",
                onDownloadProgress: (progressEvent) => {
                  console.log(progressEvent);
                  let percentageCompleted;
                  percentageCompleted = Math.floor(
                    (progressEvent.loaded / progressEvent.total) * 100
                  );
                  setPercentValueForButton(percentageCompleted);
                },
              }
            )
            .then((response) => {
 

              const reader = new FileReader();

              reader.readAsDataURL(response.data);
              reader.onload = function () {
                const imageDataUrl = reader.result;
                setTestPdf(imageDataUrl);
              };
              setViewWait(true);
            });
         
        }

        break;

      case "NDE-E":
        console.log("here at NDE ")
        currentSelect = "manual";
        for (let i = 0; i < manuals.length; i++) {
          if (y === manuals[i].name) {
            id = manuals[i].id;
            break;
          }
        }
        if (id !== "") {
          const sheet = "sheet_" + id.toString();
          const respy = await axios.get(
            apiEndpoint() +
              `/api/blob/folder/sheetfile/read/${"admin"}/${templateSelect}/${currentSelect}/${sheet}/${"table.json"}`
          );
          if (respy.status === 200) {
            console.log("table json",respy.data)
            for (let j = 0; j < respy.data.tableItem.length; j++) {
              rowno = respy.data.tableItem[1].id;
              colno = respy.data.tableItem[1].col[3].id;
              link = respy.data.tableItem[1].col[3].uploadLink;
              
              ready = true;
              let fileName=respy.data.tableItem[j].col[3].upoadName
              
                if(flag===1){
                  if(fileName.includes("NA") || link===""){
                    return(true)
                  }
                  else{
                    return(false)
                  }
                }
              break;
            }
          }
        }
        if (
          ready === true &&
          id !== "" &&
          rowno !== "" &&
          colno !== "" &&
          link !== ""
        ) {
          const sheet = "sheet_" + id;
          const rowx = "row_" + rowno;
          const colx = "col_" + colno;
          await axios
            .get(
              apiEndpoint() +
                `/api/blob/folder/sheet/read/${"admin"}/${templateSelect}/${currentSelect}/${sheet}/${rowx}/${colx}/${link}`,
              {
                responseType: "blob",
                onDownloadProgress: (progressEvent) => {
                  console.log(progressEvent);
                  let percentageCompleted;
                  percentageCompleted = Math.floor(
                    (progressEvent.loaded / progressEvent.total) * 100
                  );
                  setPercentValueForButton(percentageCompleted);
                },
              }
            )
            .then((response) => {

              const reader = new FileReader();
              reader.readAsDataURL(response.data);
              reader.onload = function () {
                const imageDataUrl = reader.result;
                setTestPdf(imageDataUrl);
              };
              setViewWait(true);
            });
          
          // // Add a response interceptor to handle the conversion
          // const interceptorId=axios.interceptors.response.use(
          //   (response) => convertResponseToBlob(response),
          //   (error) => {
          //     return Promise.reject(error);
          //   }
          // );
          // // Now you can use Axios as usual for your API requests
          // axios
          //   .get(
          //     apiEndpoint() +
          //       `/api/blob/folder/sheet/read/${"admin"}/${templateSelect}/${currentSelect}/${sheet}/${rowx}/${colx}/${link}`,
          //     {
          //       responseType: "blob",
          //       onDownloadProgress: (progressEvent) => {
          //         console.log(progressEvent);
          //         let percentageCompleted = Math.floor(
          //           (progressEvent.loaded / progressEvent.total) * 100
          //         );
          //         setPercentValueForButton(percentageCompleted);
          //       },
          //     }
          //   )
          //   .then((response) => {
          //     // Here, response.data is guaranteed to be a Blob
          //     const reader = new FileReader();
          //     reader.onload = function () {
          //       const imageDataUrl = reader.result;
          //       setTestPdf(imageDataUrl);
          //     };
          //     reader.readAsDataURL(response.data);
          //     setViewWait(true);
          //   })
          //   .catch((error) => {
          //     console.error("Error:", error);
          //     // Handle errors here
          //   });
          //   axios.interceptors.response.eject(interceptorId);
        
      //   axios.interceptors.response.use(
      //     (response) => {
      //       // Check if the response data is a Blob

      //       if (response.data instanceof Blob) {
      //         return response; // If it's already a Blob, no need to convert
      //       }
        
      //       // Convert the response data to a Blob
      //       const blob = new Blob([response.data], { type: 'application/octet-stream' });
            
      //       // Update the response data to be the converted Blob
      //       response.data = blob;
        
      //       return response;
      //     },
      //     (error) => {
      //       return Promise.reject(error);
      //     }
      //   );
        
      //   // Now you can use Axios as usual for your API requests
      //   axios.get(apiEndpoint() + `/api/blob/folder/sheet/read/${"admin"}/${templateSelect}/${currentSelect}/${sheet}/${rowx}/${colx}/${link}`, { responseType: "blob",onDownloadProgress: (progressEvent) => {
      //             console.log(progressEvent,"progress event NDE-E");
      //             let percentageCompleted;
      //             percentageCompleted =
      //               (progressEvent.loaded / progressEvent.total) * 100;
      //             setPercentValueForButton(percentageCompleted);
      //           },
      
      
      
      // })
      //     .then((response) => {
      //       // Here, response.data is guaranteed to be a Blob
      //       const reader = new FileReader();
        
      //       reader.onload = function () {
      //         const imageDataUrl = reader.result;
      //         setTestPdf(imageDataUrl);
      //       };
            
      //       reader.readAsDataURL(response.data);
      //       window.alert("done")
      //       setViewWait(true);
      //     })
      //     .catch((error) => {
      //       // Handle errors here
      //       console.error("Error:", error);
      //     });
             
        
        }
        break;

      case "NDE-V":
        currentSelect = "manual";
        for (let i = 0; i < manuals.length; i++) {
          if (y === manuals[i].name) {
            id = manuals[i].id;
            break;
          }
        }
        if (id !== "") {
          const sheet = "sheet_" + id.toString();
          const respy = await axios.get(
            apiEndpoint() +
              `/api/blob/folder/sheetfile/read/${"admin"}/${templateSelect}/${currentSelect}/${sheet}/${"table.json"}`
          );
          if (respy.status === 200) {
            for (let j = 0; j < respy.data.tableItem.length; j++) {
              if (z === respy.data.tableItem[j].col[0].name) {
                rowno = respy.data.tableItem[j].id;
                colno = respy.data.tableItem[j].col[4].id;
                link = respy.data.tableItem[j].col[4].uploadLink;
                ready = true;
                let fileName=respy.data.tableItem[j].col[4].uploadName
                if(flag===1){
                  if(fileName.includes("NA")|| link==""){
                    return(true)
                  }
                  else{
                    return(false)
                  }
                }
                break;
              }
            }
          }
        }
        if (
          ready === true &&
          id !== "" &&
          rowno !== "" &&
          colno !== "" &&
          link !== ""
        ) {
          const sheet = "sheet_" + id;
          const rowx = "row_" + rowno;
          const colx = "col_" + colno;
          await axios
            .get(
              apiEndpoint() +
                `/api/blob/folder/sheet/read/${"admin"}/${templateSelect}/${currentSelect}/${sheet}/${rowx}/${colx}/${link}`,
              {
                responseType: "blob",
                onDownloadProgress: (progressEvent) => {
                  console.log(progressEvent);
                  let percentageCompleted;
                  percentageCompleted = Math.floor(
                    (progressEvent.loaded / progressEvent.total) * 100
                  );
                  setPercentValueForButton(percentageCompleted);
                },
              }
            )
            .then((response) => {
           
              const reader = new FileReader();
              reader.readAsDataURL(response.data);
              reader.onload = function () {
                const imageDataUrl = reader.result;
                setTestPdf(imageDataUrl);
              };
              setViewWait(true);
            });
        // axios
        //   .get(
        //     apiEndpoint() +
        //       `/api/blob/folder/sheet/read/${"admin"}/${templateSelect}/${currentSelect}/${sheet}/${rowx}/${colx}/${link}`,
        //     {
        //       responseType: "blob", // Use "arraybuffer" to receive binary data
        //       onDownloadProgress: (progressEvent) => {
        //         console.log(progressEvent);
        //         let percentageCompleted =
        //           (progressEvent.loaded / progressEvent.total) * 100;
        //         setPercentValueForButton(percentageCompleted);
        //       },
        //     }
        //   )
        //   .then((response) => {
        //     console.log("response data from NDE E ",response.data)
        //     // Convert the response data to a Blob
        //     const blob = new Blob([response.data], { type: "application/octet-stream" });
        
        //     const reader = new FileReader();
        
        //     reader.onload = function (event) {
        //       const dataURL = event.target.result;
        //       setTestPdf(dataURL);
        //       setViewWait(true);
        //     };
        
        //     reader.readAsDataURL(blob);
        //   })
        //   .catch((error) => {
        //     console.error("Error:", error);
        //     // Handle errors here
        //   });    
        }

        break;

      case "NDE-S":
        currentSelect = "manual";
        for (let i = 0; i < manuals.length; i++) {
          if (y === manuals[i].name) {
            id = manuals[i].id;
            break;
          }
        }
        if (id !== "") {
          const sheet = "sheet_" + id.toString();
          const respy = await axios.get(
            apiEndpoint() +
              `/api/blob/folder/sheetfile/read/${"admin"}/${templateSelect}/${currentSelect}/${sheet}/${"table.json"}`
          );
          if (respy.status === 200) {
            for (let j = 0; j < respy.data.tableItem.length; j++) {
              if (z === respy.data.tableItem[j].col[0].name) {
                rowno = respy.data.tableItem[j].id;
                colno = respy.data.tableItem[j].col[5].id;
                link = respy.data.tableItem[j].col[5].uploadLink;
                ready = true;
                let fileName=respy.data.tableItem[j].col[5].uploadName
                if(flag===1){
                  if(fileName.includes("NA") || link===""){
                    return(true)
                  }
                  else{
                    return(false)
                  }
                }
                break;
              }
            }
          }
        }
        if (
          ready === true &&
          id !== "" &&
          rowno !== "" &&
          colno !== "" &&
          link !== ""
        ) {
          const sheet = "sheet_" + id;
          const rowx = "row_" + rowno;
          const colx = "col_" + colno;
          await axios
            .get(
              apiEndpoint() +
                `/api/blob/folder/sheet/read/${"admin"}/${templateSelect}/${currentSelect}/${sheet}/${rowx}/${colx}/${link}`,
              {
                responseType: "blob",
                onDownloadProgress: (progressEvent) => {
                  console.log(progressEvent);
                  let percentageCompleted;
                  percentageCompleted = Math.floor(
                    (progressEvent.loaded / progressEvent.total) * 100
                  );

                  setPercentValueForButton(percentageCompleted);
                },
              }
            )
            .then((response) => {
              const reader = new FileReader();
              reader.readAsDataURL(response.data);
              reader.onload = function () {
                const imageDataUrl = reader.result;
                setTestPdf(imageDataUrl);
              };
              setViewWait(true);
            });
        }

        break;

      case "NDE-C":
        currentSelect = "manual";
        for (let i = 0; i < manuals.length; i++) {
          if (y === manuals[i].name) {
            id = manuals[i].id;
            break;
          }
        }

        if (id !== "") {
          const sheet = "sheet_" + id.toString();
          const respy = await axios.get(
            apiEndpoint() +
              `/api/blob/folder/sheetfile/read/${"admin"}/${templateSelect}/${currentSelect}/${sheet}/${"table.json"}`
          );
          if (respy.status === 200) {
            for (let j = 0; j < respy.data.tableItem.length; j++) {
              rowno = respy.data.tableItem[1].id;
              colno = respy.data.tableItem[1].col[6].id;
              link = respy.data.tableItem[1].col[6].uploadLink;
              let fileName=respy.data.tableItem[j].col[6].uploadName
                if(flag===1){
                  if(fileName.includes("NA")|| link==""){
                    return(true)
                  }
                  else{
                    return(false)
                  }
                }
              ready = true;
              break;
            }
          }
        }
        if (
          ready === true &&
          id !== "" &&
          rowno !== "" &&
          colno !== "" &&
          link !== ""
        ) {
          const sheet = "sheet_" + id;
          const rowx = "row_" + rowno;
          const colx = "col_" + colno;
          await axios
            .get(
              apiEndpoint() +
                `/api/blob/folder/sheet/read/${"admin"}/${templateSelect}/${currentSelect}/${sheet}/${rowx}/${colx}/${link}`,
              {
                responseType: "blob",
                onDownloadProgress: (progressEvent) => {
                  console.log(progressEvent);
                  let percentageCompleted;
                  percentageCompleted = Math.floor(
                    (progressEvent.loaded / progressEvent.total) * 100
                  );

                  setPercentValueForButton(percentageCompleted);
                },
              }
            )
            .then((response) => {
              const reader = new FileReader();
              reader.readAsDataURL(response.data);
              reader.onload = function () {
                const imageDataUrl = reader.result;
                setTestPdf(imageDataUrl);
              };
              setViewWait(true);
            });
        }

        break;

      case "NDE-PM":
        t = z.split("-");
        currentSelect = "flowchart";
        for (let i = 0; i < flowcharts.length; i++) {
          if (y === flowcharts[i].name) {
            id = flowcharts[i].id;
            break;
          }
        }
        if (id !== "") {
          const sheet = "sheet_" + id.toString();
          const respy = await axios.get(
            apiEndpoint() +
              `/api/blob/folder/sheetfile/read/${"admin"}/${templateSelect}/${currentSelect}/${sheet}/${"table.json"}`
          );
          if (respy.status === 200) {
            for (let j = 0; j < respy.data.tableItem.length; j++) {
              rowno = respy.data.tableItem[1].id;
              colno = respy.data.tableItem[1].col[6].id;
              link = respy.data.tableItem[1].col[6].uploadLink;
              filename = respy.data.tableItem[1].col[6].uploadName;

              ready = true;
              break;
            }
          }
        }
        if (
          ready === true &&
          id !== "" &&
          rowno !== "" &&
          colno !== "" &&
          link !== ""
        ) {
          const sheet = "sheet_" + id;
          const rowx = "row_" + rowno;
          const colx = "col_" + colno;
          setWaitIndicatorStart(true);
          setPercentCompleted(0);
          await axios({
            method: "get",
            url:
              apiEndpoint() +
              `/api/blob/folder/sheet/read/${"admin"}/${templateSelect}/${currentSelect}/${sheet}/${rowx}/${colx}/${link}`,
            responseType: "blob",
            onDownloadProgress: (progressEvent) => {
              console.log(progressEvent);
              let percentageCompleted;
              percentageCompleted = Math.floor(
                (progressEvent.loaded / progressEvent.total) * 100
              );

              setPercentValueForButton(percentageCompleted);
            },
          })
            // await axios.get(apiEndpoint()+ `/api/blob/folder/sheet/read/${"admin"}/${templateSelect}/${currentSelect}/${sheet}/${rowx}/${colx}/${link}`, { responseType: "blob" },{onDownloadProgress: (progressEvent) => {
            //         setPercentCompleted(Math.floor((progressEvent.loaded * 100) / progressEvent.total));
            // }})
            .then((response) => {
              const downloadUrl = window.URL.createObjectURL(
                new Blob([response.data])
              );
              download(filename, "", downloadUrl);
            });
          setPercentCompleted(100);
          // setWaitIndicatorStart(false);
          //  window.alert("Download Success");
        }
        break;

      case "NDE-3DMM":
        t = z.split("-");
        currentSelect = "flowchart";
        for (let i = 0; i < flowcharts.length; i++) {
          if (y === flowcharts[i].name) {
            id = flowcharts[i].id;
            break;
          }
        }
        if (id !== "") {
          const sheet = "sheet_" + id.toString();
          const respy = await axios.get(
            apiEndpoint() +
              `/api/blob/folder/sheetfile/read/${"admin"}/${templateSelect}/${currentSelect}/${sheet}/${"table.json"}`
          );
          if (respy.status === 200) {
            for (let j = 0; j < respy.data.tableItem.length; j++) {
              rowno = respy.data.tableItem[1].id;
              colno = respy.data.tableItem[1].col[8].id;
              link = respy.data.tableItem[1].col[8].uploadLink;
              link = "None";
              filename = respy.data.tableItem[1].col[8].uploadName;
              window.open(respy.data.tableItem[1].col[8].name, "_blank");
              ready = true;
              break;
            }
          }
        }
        break;

      case "NDE-P":
        currentSelect = "manual";
        for (let i = 0; i < manuals.length; i++) {
          if (y === manuals[i].name) {
            id = manuals[i].id;
            break;
          }
        }
        if (id !== "") {
          const sheet = "sheet_" + id.toString();
          const respy = await axios.get(
            apiEndpoint() +
              `/api/blob/folder/sheetfile/read/${"admin"}/${templateSelect}/${currentSelect}/${sheet}/${"table.json"}`
          );
         const file1 = respy.data.tableItem[1].col[7].uploadName;
          
          if (respy.status === 200) {
            for (let j = 0; j < respy.data.tableItem.length; j++) {
              rowno = respy.data.tableItem[1].id;
              colno = respy.data.tableItem[1].col[7].id;
              link = respy.data.tableItem[1].col[7].uploadLink;
              if(flag===1){
                if(file1.includes("NA")|| link===""){
                  return(true)
                }
                else{
                  return(false)
                }
              }
              ready = true;
              break;
            }
          }
        }
        if (
          ready === true &&
          id !== "" &&
          rowno !== "" &&
          colno !== "" &&
          link !== ""
        ) {
          const sheet = "sheet_" + id;
          const rowx = "row_" + rowno;
          const colx = "col_" + colno;
          await axios
            .get(
              apiEndpoint() +
                `/api/blob/folder/sheet/read/${"admin"}/${templateSelect}/${currentSelect}/${sheet}/${rowx}/${colx}/${link}`,
              {
                responseType: "blob",
                onDownloadProgress: (progressEvent) => {
                  console.log(progressEvent);
                  let percentageCompleted;
                  percentageCompleted = Math.floor(
                    (progressEvent.loaded / progressEvent.total) * 100
                  );

                  setPercentValueForButton(percentageCompleted);
                },
              }
            )
            .then((response) => {
              const downloadUrl = window.URL.createObjectURL(
                new Blob([response.data])
              );
              download(filename, "", downloadUrl);
            });
          window.alert("Download Success");
        }

        break;

      case "NDE-T":
        const notes = [];
        currentSelect = "manual";
        console.log("manuals here at NDE-T",manuals)
        for (let i = 0; i < manuals.length; i++) {
          if (y === manuals[i].name) {
        console.log("id Of Manual",manuals,id)
            id = manuals[i].id;
            break;
          }
        }
        if (id !== "") {
          const sheet = "sheet_" + id.toString();
          const respy = await axios.get(
            apiEndpoint() +
              `/api/blob/folder/sheetfile/read/${"admin"}/${templateSelect}/${currentSelect}/${sheet}/${"table.json"}`
          );
          if (respy.status === 200) {
            console.log("table Fecthes",respy.data)
            for (let j = 0; j < respy.data.tableItem.length; j++) {
              rowno = respy.data.tableItem[1].id;
              for (let k = 0; k < respy.data.tableItem[1].col.length; k++) {
                if (k >= 8) {
                  colno = respy.data.tableItem[1].col[k].id;
                  link = respy.data.tableItem[1].col[k].uploadLink;
                  
                  let fileName=respy.data.tableItem[j].col[k].uploadName
                if(flag===1){
                  if(fileName.includes("NA")|| link===""){
                    return(true)
                  }
                  else{
                    return(false)
                  }
                }
                  
                  notes.push({
                    id: id,
                    filename: fileName,
                    rowno: rowno,
                    colno: colno,
                    link: link,
                  });
                  console.log(notes,"notes ")
                  ready = true;
                }
              }
              break;
            }
          }
        }
        if (
          ready === true &&
          id !== "" &&
          rowno !== "" &&
          colno !== "" &&
          link !== ""
        ) {
          setTechnicalNotes(notes);
        }
        break;

      case "LOC":
        currentSelect = "spreadsheet";
        t = z.split("-");
        let t1 = s.split("[");
        t1 = t1[1].split("]");
        for (let i = 0; i < spreadsheets.length; i++) {
          if (y === spreadsheets[i].name) {
            id = spreadsheets[i].id;
            break;
          }
        }
        if (id !== "") {
          const sheet = "sheet_" + id.toString();
          const respy = await axios.get(
            apiEndpoint() +
              `/api/blob/folder/sheetfile/read/${"admin"}/${templateSelect}/${currentSelect}/${sheet}/${"table.json"}`
          );
          if (respy.status === 200) {
            for (let j = 0; j < respy.data.tableItem.length; j++) {
              if (
                t[0] === respy.data.tableItem[j].col[0].name &&
                t[1] === respy.data.tableItem[j].col[1].name &&
                t[2] === respy.data.tableItem[j].col[2].name &&
                t1[0] === respy.data.tableItem[j].col[4].name
              ) {
                rowno = respy.data.tableItem[j].id;
                colno = respy.data.tableItem[j].col[5].id;
                link = respy.data.tableItem[j].col[5].uploadLink;
                filename = respy.data.tableItem[j].col[5].uploadName;
                ready = true;
                break;
              }
            }
          }
        }
        if (
          ready === true &&
          id !== "" &&
          rowno !== "" &&
          colno !== "" &&
          link !== ""
        ) {
          const sheet = "sheet_" + id;
          const rowx = "row_" + rowno;
          const colx = "col_" + colno;
          await axios
            .get(
              apiEndpoint() +
                `/api/blob/folder/sheet/read/${"admin"}/${templateSelect}/${currentSelect}/${sheet}/${rowx}/${colx}/${link}`,
              {
                responseType: "blob",
                onDownloadProgress: (progressEvent) => {
                  console.log(progressEvent);
                  let percentageCompleted;
                  percentageCompleted = Math.floor(
                    (progressEvent.loaded / progressEvent.total) * 100
                  );

                  setPercentValueForButton(percentageCompleted);
                },
              }
            )
            .then((response) => {
              const downloadUrl = window.URL.createObjectURL(
                new Blob([response.data])
              );
              download(filename, "", downloadUrl);
            });
          window.alert("Download Success");
        }
        break;
      
      default:
        break;
    }
    console.log("link")
    if (link === "") {
      window.alert("Document not uploaded !");
    }
    setDocState(["", "", "", "", ""]);
  }
  async function onClickOpenDoc(info) {
    const currentSelect = "manual";
    const sheet = "sheet_" + info.id;
    const rowx = "row_" + info.rowno;
    const colx = "col_" + info.colno;
    const link = info.link;
    await axios
      .get(
        apiEndpoint() +
          `/api/blob/folder/sheet/read/${"admin"}/${templateSelect}/${currentSelect}/${sheet}/${rowx}/${colx}/${link}`,
        { responseType: "blob" }
      )
      .then((response) => {
        const reader = new FileReader();
        reader.readAsDataURL(response.data);
        reader.onload = function () {
          const imageDataUrl = reader.result;
          setTestPdf(imageDataUrl);
        };
        setViewWait(true);
      });
  }
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
      if (window.innerWidth <= 768 && !widthFlag) {
        setWidthFlag(true);
      }
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    if (widthFlag) {
      collapseSidebar();
      setCollapsedFlag(true);
    }
  }, [widthFlag]);

  return (
    <FlowChartContainer>
      {updateTemplate === true && updateSelectedDatabase()}
      {updateFlowchart === true && flag3 === true && updateSelectedFlowchart()}
      
      {reset === true && (
        <>
          <div style={{
              border: "1px solid #ebebeb",
              "box-shadow": "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              backgroundColor: "white",
        }}>
            {/* <div style={{ display: "flex" }}>
          <div style={{ marginLeft: "1%", marginTop: "5px", marginRight: "2%", marginBottom: "0px", width: "30%", height: "50px", backgroundColor: color_1, border: "1px solid grey" }}>
            <div style={{ marginLeft: "0%", marginTop: "0px", marginRight: "0%", marginBottom: "0px", width: "100%", height: "25px", backgroundColor: color_1, border: "1px solid grey" }}>
              <h6 style={{ fontSize: "small", marginTop: "0px", textAlign: "center", color: "white" }}> Workflow Name</h6>
            </div>

            <select style={{ marginLeft: "0%", marginTop: "0px", marginRight: "0%", marginBottom: "0px", width: "100%", height: "25px", color: color_3, backgroundColor: color_2, border: "1px solid grey" }} id={"Dropdown-1"} onChange={(e) => handleDropdown(2, e)}>>
              {[...storeTemplateInfo.map(d1 => d1 === templateSelect ? <option key={d1} value={d1} selected >{d1}</option> : <option key={d1} value={d1} >{d1}</option>)]}
            </select>

          </div>

          <div style={{ marginLeft: "1%", marginTop: "5px", marginRight: "2%", marginBottom: "0px", width: "30%", height: "50px", backgroundColor: color_1, border: "1px solid grey" }}>
            <div style={{ marginLeft: "0%", marginTop: "0px", marginRight: "0%", marginBottom: "0px", width: "100%", height: "25px", backgroundColor: color_1, border: "1px solid grey" }}>
              <h6 style={{ fontSize: "small", marginTop: "0px", textAlign: "center", color: "white" }}> Damage Mechanisms</h6>
            </div>

            <select style={{ marginLeft: "0%", marginTop: "0px", marginRight: "0%", marginBottom: "0px", width: "100%", height: "25px", color: color_3, backgroundColor: color_2, border: "1px solid grey" }} id={"Dropdown-2"} onChange={(e) => handleDropdown(3, e)}>>
              {[...storeComponentNode.map(d1 => d1.name === componentSelect ? <option key={d1.name} value={d1.name} selected >{d1.name}</option> : <option key={d1.name} value={d1.name} >{d1.name}</option>)]}

            </select>

          </div>

          <div style={{ marginLeft: "1%", marginTop: "5px", marginRight: "2%", marginBottom: "0px", width: "30%", height: "50px", backgroundColor: color_1, border: "1px solid grey" }}>
            <div style={{ marginLeft: "0%", marginTop: "0px", marginRight: "0%", marginBottom: "0px", width: "100%", height: "25px", backgroundColor: color_1, border: "1px solid grey" }}>
              <h6 style={{ fontSize: "small", marginTop: "0px", textAlign: "center", color: "white" }}> Inspect</h6>
            </div>
            <select style={{ marginLeft: "0%", marginTop: "0px", marginRight: "0%", marginBottom: "0px", width: "100%", height: "25px", color: color_3, backgroundColor: color_2, border: "1px solid grey" }} id={"Dropdown-3"} onChange={(e) => handleDropdown(4, e)}>>
              {[...storeSubComponentNode.map(d1 => d1.name === subComponentSelect ? <option key={d1.name} value={d1.name} selected >{d1.name}</option> : <option key={d1.name} value={d1.name} >{d1.name}</option>)]}

            </select>
          </div>


        </div> */}
            <div
              style={{
                display: "flex",
                width: "30%",
                // backgroundColor: "navy",
                height: "100%",
                flexDirection: "horizontal",
              }}
            >
              <Sidebar >
                <div
                  className="dropdownsContainer"
                  style={{
                    padding: "5px",
                    height: "100%",
                    display: "flex",
                    "flex-direction": "column",
                    "justify-content": "space-between",
                  }}
                >
                  <Menu 
                      rootStyles={{
                        [`.${menuClasses.container}`]: {
                          color:'pink',
                          backgroundColor:'black'
                        },
                      }}
                      >
                    {collapsedFlag === false && (
                      <div>
                        <div
                          style={{
                            margin: "10px",
                            borderRadius: "5px",
                            width: "225px",
                          }}
                        >
                          <div style={{ "font-size": "14px" }}>
                            Workflow Name
                          </div>
                          <div >{MyComponent(2)}</div>
                        </div>
                        <div
                          style={{
                            margin: "10px",
                            borderRadius: "5px",
                            width: "225px",
                          }}
                        >
                          <div style={{ "font-size": "14px"}}>
                            Damage Mechanism
                          </div>
                          <div>{MyComponent(3)}</div>
                        </div>
                        <div
                          style={{
                            margin: "10px",
                            borderRadius: "5px",
                            width: "225px",
                          }}
                        >
                          <div style={{ "font-size": "14px"}}>Inspect</div>
                          <div >{MyComponent(4)}</div>
                        </div>
                      </div>
                    )}
                    {collapsedFlag === true && (
                      <MenuContainer>
                        <div
                          style={{
                            margin: "12px",
                            padding: "6px",
                            borderRadius: "5px",
                            height: "50px",
                            width: "50px",
                          }}
                        >
                          {" "}
                          <Icon.PersonWorkspace
                            style={{
                              width: "100%",
                              height: "100%",
                              color: "rgb(225, 78, 147)",
                            }}
                            onClick={() => {
                              collapseSidebar();
                              setCollapsedFlag(false);
                            }}
                          ></Icon.PersonWorkspace>
                        </div>
                        <div
                          style={{
                            margin: "12px",
                            padding: "6px",
                            borderRadius: "5px",
                            height: "50px",
                            width: "50px",
                          }}
                        >
                          <Icon.Cpu
                            style={{
                              width: "100%",
                              height: "100%",
                              color: "rgb(225, 78, 147)",
                            }}
                            onClick={() => {
                              collapseSidebar();
                              setCollapsedFlag(false);
                            }}
                          ></Icon.Cpu>
                        </div>
                        <div
                          style={{
                            margin: "12px",
                            padding: "6px",
                            borderRadius: "5px",
                            height: "50px",
                            width: "50px",
                          }}
                        >
                          <Icon.ChevronBarContract
                            style={{
                              width: "100%",
                              height: "100%",
                              color: "rgb(225, 78, 147)",
                            }}
                            onClick={() => {
                              collapseSidebar();
                              setCollapsedFlag(false);
                            }}
                          ></Icon.ChevronBarContract>
                        </div>
                      </MenuContainer>
                    )}
                  </Menu>
                  <div
                    className="arrowButtonContainer"
                    style={{
                      textAlign: "right",
                      // marginTop: "250px",
                      marginRight: "10px",
                    }}
                  >
                    {collapsedFlag === false && (
                      <ArrowButtonLeft
                        onClick={() => {
                          collapseSidebar();
                          setCollapsedFlag(true);
                        }}
                      >
                        <Icon.ArrowLeft></Icon.ArrowLeft>
                      </ArrowButtonLeft>
                    )}
                    {collapsedFlag === true && (
                      <ArrowButtonLeft
                        onClick={() => {
                          collapseSidebar();
                          setCollapsedFlag(false);
                          setWidthFlag(false)
                        }}
                      >
                        <Icon.ArrowRight></Icon.ArrowRight>
                      </ArrowButtonLeft>
                    )}
                  </div>
                </div>
              </Sidebar>
            </div>
          </div>

          <div style={{ marginTop: "10px" }}></div>
          <div style={{ display: "none" }}>
            <label style={{ marginLeft: "20px" }}>
              <input
                type="radio"
                name="dropdown-group"
                checked={filterPlot[0]}
                onChange={(e) => handleEditor("CHECKBOX", 11, e)}
              />
              <span style={{ fontSize: "small", marginLeft: "10px" }}>
                Mode-1
              </span>
            </label>
            <label style={{ marginLeft: "100px" }}>
              <input
                type="radio"
                name="dropdown-group"
                checked={filterPlot[1]}
                onChange={(e) => handleEditor("CHECKBOX", 12, e)}
              />
              <span style={{ fontSize: "small", marginLeft: "10px" }}>
                Mode-2
              </span>
            </label>

            <label style={{ marginLeft: "100px" }}>
              <input
                type="radio"
                name="dropdown-group"
                checked={filterPlot[2]}
                onChange={(e) => handleEditor("CHECKBOX", 13, e)}
              />
              <span style={{ fontSize: "small", marginLeft: "10px" }}>
                Mode-3
              </span>
            </label>
          </div>
          {/* <div className="inputContainer" style={{ textAlign: "center", top: "100px", width: "50%", margin: "auto", height: "104px", backgroundColor: "lightgray", border: "1px solid black" }}>
            <span className="textLabel" style={{ marginRight: "10px", border: "1px dotted black" }}> ONLY FOR TEST PURPOSES</span>
            <div className='button Container' style={{ border: "1px solid lightgrey", borderRadius: "1px", textAlign: "center" }}>
              <input type='file' onInput={(e) => { fileInputHandler(e) }}></input>
              <button onClick={()=>{onClickGetManualsReferenceExcel()}} >Get Manuals(Excel File) </button>
              <button onClick={()=>{getSpreadsheetJSON()}}>get spreadsheet nde mapping</button>
              <button onClick={()=>{getMethodsRef()}}>Temp Methods ref</button>
            </div>
          </div> */}

          {imageReady === true && (
            <>
              <div style={{ marginTop: "10px" }}></div>
              <div>
                <img
                  style={{ width: "100%", height: "100px" }}
                  src={imageSelect}
                  alt="image"
                />
              </div>
            </>
          )}

          {/* {loading === false && (
            <LoadingContainer>
              <h6
                style={{
                  fontSize: "20px",
                  marginTop: "50px",
                  width:'100%',
                  textAlign: "center",
                  color: "red",
                }}
              >
                Loading .... Wait
              </h6>
              <BouncingBalls color="#009eb3"/>
            </LoadingContainer>
          )} */}
          {/* {loading === true &&
            (storeSubComponentNode.length <= 0 ||
              isItemEmpty !== true ||
              isSubItemEmpty !== true) && (
              <h6
                style={{
                  fontSize: "small",
                  marginTop: "50px",
                  textAlign: "center",
                  color: "red",
                }}
              >
                Database not created for selected Component/Subcomponent
              </h6>
            )} */}
          {loading === false && (
            // loading is the flag before any of the component options load
            // flag3 is the flag before all of the component entered
            <LoadingContainer>
              <h6
                style={{
                  fontSize: "20px",
                  marginTop: "50px",
                  width: "100%",
                  textAlign: "center",
                  color: "red",
                }}
              >
                Loading .... Wait
              </h6>
              <BouncingBalls color="#009eb3" />
              
            </LoadingContainer>
          )}
          {loading === true && flag3 === false && (
            // loading is the flag before any of the component options load
            // flag3 is the flag before all of the component entered
            
<>
            <>
            {
              flag11===true && 
              <LoadingContainer>
              <h6
                style={{
                  fontSize: "20px",
                  marginTop: "50px",
                  width: "100%",
                  textAlign: "center",
                  color: "red",
                }}
              >
                Loading .... Wait
              </h6>
              <BouncingBalls color="#009eb3" />
              
            </LoadingContainer>
            }</>
            {
              flag11===false &&
<SelectMessage>
              <BiSelectMultiple
                style={{ width: 30, height: 30, color: "#2a5ad2" }}
              />
              <h6
                style={{
                  fontSize: "medium",
                  margin: 0,
                }}
              >
                SELECT
                {
                  flag1===true && flag2===false && flag3===false &&
                  
                  <>
                  <span><b> DAMAGE MECHANISM</b></span>
                  </>
                  
                }
                {
                  flag1===false && flag2===true && flag3===false && 
                  <>
                  <span><b> INSPECTION METHOD </b></span>
                  </>

                }
                {
                  flag1===false && flag2===false && flag3===false &&
                  <>
                  <span>
                    <b> WORKFLOW </b>
                  </span>
                  
                  </>
                }
              </h6>
            </SelectMessage>
            }  
            

          </>
          )}
          {/* { 
         loading===false &&
         (
            <h6
              style={{
                fontSize: "small",
                marginTop: "50px",
                textAlign: "center",
                color: "red",
              }}
            >
              Loading .... Wait
            </h6>
          )} */}
          {/* here we need to incorporate flag3 also along with loading because this needs to be shown at 
          end of third click  */}
          {loading === true &&
            flag3 === true &&
            (storeSubComponentNode.length <= 0 ||
              isItemEmpty !== true ||
              isSubItemEmpty !== true) && (
                <NoDatabasePresent>
                  <BsDatabaseFillExclamation
                    style={{ width: 40, height: 40, color: "#949fb2" }}
                  />
                  <h6
                    style={{
                      textAlign: "center",
                      color: "rgb(221 0 0)",
                    }}
                  >
                    Database not created for selected Component/Subcomponent
                  </h6>
                </NoDatabasePresent>
            )}


            {updateReady === true &&
            flag3 === true &&
            filterPlot[0] === true &&
            storeSubComponentNode.length > 0 &&
            isItemEmpty === true &&
            isSubItemEmpty === true &&
              tempUnavailable===true && flagVessel===false && <>
              <NoDatabasePresent>
                  <BsDatabaseFillExclamation
                    style={{ width: 40, height: 40, color: "#949fb2" }}
                  />
                  <h6
                    style={{
                      textAlign: "center",
                      color: "rgb(221 0 0)",
                    }}
                  >
                   Unavailable  (Error Code- Restricted)
                  </h6>
                </NoDatabasePresent>
              </> 
              
            }
          {updateReady === true &&
            flag3 === true &&
            filterPlot[0] === true &&
            storeSubComponentNode.length > 0 &&
            isItemEmpty === true &&
            isSubItemEmpty === true &&
            (tempUnavailable===false || flagVessel===true) &&
            (
              <>
              {
                showWorkFlow()
              }
              
          </>
            )}


          {
            updateReady===false && flag3===true && isItemEmpty===true && isSubItemEmpty===true&&
            <>
             <LoadingContainer>
              <h6
                style={{
                  fontSize: "20px",
                  marginTop: "50px",
                  width: "100%",
                  textAlign: "center",
                  color: "red",
                }}
              >
                Loading .... Wait
              </h6>
              <BouncingBalls color="#009eb3" />
            </LoadingContainer>
            </>
          }
          {updateReady === true && filterPlot[1] === true && (
            <WorkflowMode1 workflow={templateSelect} filterTemplate={""} />
          )}
          {updateReady === true && filterPlot[2] === true && (
            <WorkflowMode2 workflow={templateSelect} filterTemplate={""} />
          )}
          {imageReady === true && (
            <>
              <div style={{ marginTop: "10px" }}></div>
              <div>
                <img
                  style={{ width: "100%", height: "100px" }}
                  src={imageSelect}
                  alt="image"
                />
              </div>
            </>
          )}
        </>
      )}
      {refresh === true && <> </>}
      {refresh === false && <> </>}

      {viewWait === true && (
        <Modal
          show={true}
          backdrop="static"
          centered
          size="xl"
          style={{ width: "1400px", height: "800px" }}
          onHide={() => setViewWait(false)}
        >
          <Modal.Header closeButton> </Modal.Header>
          <iframe
            src={testPdf}
            title="NDE"
            id="my-frame"
            style={{ width: "1125px", height: "650px" }}
          />
        </Modal>
      )}

      {technicalNotes.length > 0 && (
        <Modal
          show={true}
          backdrop="static"
          centered
          size="lg"
          onHide={() => setTechnicalNotes([])}
        >
          <Modal.Header closeButton> </Modal.Header>
          {technicalNotes.map((x) => (
            <Button
              style={{
                marginLeft: "10%",
                marginTop: "10px",
                marginRight: "10%",
                marginBottom: "10px",
              }}
              variant={TabConfig.color}
              size="sm"
              disabled={false}
              onClick={() => onClickOpenDoc(x)}
            >
              {x.filename}
            </Button>
          ))}
        </Modal>
      )}

      {waitIndicatorStart === true && (
        <Modal
          show={true}
          backdrop="static"
          centered
          size="sm"
          onHide={() => setWaitIndicatorStart(false)}
        >
          <Modal.Header closeButton> </Modal.Header>
          <Button
            style={{
              marginLeft: "10%",
              marginTop: "10px",
              marginRight: "10%",
              marginBottom: "10px",
            }}
            variant={TabConfig.color}
            size="sm"
            disabled={false}
          >
            {percentCompleted} % Downloaded
          </Button>
        </Modal>
      )}
    </FlowChartContainer>
  );
}
